.search-layer {
	position: absolute;
	top: rem(100);
	left: 0;
	right: 0;


	//@include theme-background-color('primary-1');
	@include theme-background-color('search-layer');

	display: none;

	transform-origin: center top;
	transition: all 3.3s ease;
	// transform: scaleY(0);

	&.deck { z-index: 10; }

	&.is-active {
		display: block;
		// transform: scaleY(1);
		.search-layer__inner {
			transition: max-height 0.4s ease-out, padding 0.4s ease-out, opacity 0.4s ease-out 0.1s;
			opacity: 1;
			max-height: rem(600);
			padding: rem($space-xsmall) 0;
			@include mq($from: screen-sm){
				max-height: rem(250);
				padding: rem($space-large) 0;
			}
			@include mq($from: screen-md){
				max-height: rem(320);
			}
		}
	}

	&__inner {
		position: relative;
		opacity: 0;
		transition: max-height 0.4s ease-out 0.1s, padding 0.4s ease-out 0.1s, opacity 0.4s ease-out;
		max-height: 0;

		padding: 0;
	}

	// .button--link {
	// 	position: absolute;
	// 	top: 1em;
	// 	right: 1em;
	// 	.svg-icon { fill: $color-white; }
	// 	.text { color: $color-white; }
	// }
	.form {
		padding: rem(30) 0;

		&__inner {
			position: relative;
		}

	}
	input {
		@extend %form-item;
		display: block;
		background: $color-white;
		border: none;
		border-radius: none;
		line-height: 1em;
		font-size: rem(24);
		font-weight: 300;
		border-bottom: rem(4) solid $color-black;
		box-sizing: border-box;
		height: rem(70);
		line-height: rem(40);

		padding: rem($space-small);
		padding-right: rem(60);
		@include mq($from: screen-sm){
			padding: rem(15) rem(30);
		}

		&::-ms-clear, &::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}

		&:focus,
		&:active {
			outline: 0;
			box-shadow: 0 0 0 black;
			@include theme-border-color('primary-3');
		}
	}
	.button--search {
		padding: rem(20);
		transition: background 0.1s ease-out;
		position: relative;

		&:before {
			position: absolute;
			content:'';
			top:100%;
			right: 0;
			left: 0;
			bottom: 0;
			background: $color-black;
			transition: top 0.1s ease-out;
		}

		.icon {
			width: rem(32);
			height: rem(32);
			display: inline-flex;
		}
		.svg-icon {
			width: 100%;
			height: 100%;
			fill: $color-black;
			transition: fill 0.3s ease-out;
		}

		position: absolute;
		top:50%;
		transform:translateY(-50%);
		right: 0;

		&:hover {
			&:before {
				top: 0;
			}
			.svg-icon {
				fill: $color-white;
			}
		}
	}





	&--deck {
		position: relative;
		z-index: 100;
		left: auto;
		top: auto;
		height: auto;

		.form {
			@include mq($from: screen-sm){
				padding: rem(40) 0;
			}
			@include mq($from: screen-md){
				padding: rem(80) 0;
			}
		}
		input[type=text] {
			font-size: rem(18);
			font-weight: 400;
		}

	}

	&__toggles {
		display: none;
		padding-top: rem($space-large);

		transform-origin: center top;
		transition: all 0.1s ease-out;
		transform: scaleY(0);
		opacity: 0;
		&.is-active {
			opacity: 1;
			transform: scaleY(1);
			display: flex;
		}
		.grid-item {
			margin-bottom: rem($space-xxsmall);

			+ .grid-item {
				margin-top: 0;
			}

			&:nth-last-child(1),
			&:nth-last-child(2) {
				margin-bottom: 0;
			}
			@include mq($from: screen-md){
				margin-bottom: 0;
			}
		}

	}

	&--extended {

		.search-layer__inner {
			@include mq($from: screen-sm){
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		.button--search {
			padding: rem(9) rem(15) rem(9);
		}

		h4 {
			color: $color-white;
			font-family: $font-stack-tertiary;
			font-size: rem(18);
			line-height: rem(50);
			font-weight: 600;

			@include mq($from: screen-md){
				display: inline-block;
				width: rem(175);
			}
		}
		input[type=text] {
			border: 0;
			font-size: rem(14);
			font-weight: 400;
			height: rem(50);
			line-height: rem(20);

			@include mq($from: screen-sm){
				border-right: rem(2) solid $color-border;
			}
		}
		.form__field {
			margin: 0;
		}
		.form {
			padding: 0;
			@include mq($from: screen-sm){
				display: inline-block;
				padding: 0;
				width: calc(100% - 175px);
			}
		}
		.grid-item {
			position: relative;
		}

	}
}
.search-layer__inner h1,h2, h3, h4{
	font-family: var(--font-primary) !important;
}