// Attribute Block
//
// DOD: done
//
// Element with mood icon, headline, list of attributes and optional edit icon button
//
// Weight: 1
//
// Markup: ../../templates/prototype/partials/elements/attribute-block.twig
//
// Style guide: kanalen.attributeblock

.attribute-block {
	$module: ".attribute-block";

	flex: 50% 0 0;

	display: flex;

	&--wide {
		flex: 100% 0 0;
		#{$module}__content {
			width: calc(100% - #{rem(30 + $space-small)});
		}

		@include mq($from: screen-xs) {
			ul {
				columns: 2;
				column-gap: rem(60);
			}
		}
	}

	&__icon {
		margin-right: rem($space-small);

		.svg-icon {
			width: rem(30);
			height: rem(30);
			@include theme-fill("primary-1");
			@include mq($from: screen-sm) {
				width: rem(40);
				height: rem(40);
			}
		}
	}

	&__content {
		position: relative;
		font-size: rem(14);
		color: $color-grey-1;

		h4 {
			@extend %headline-5;
			margin-bottom: rem($space-small);
			color: $color-black;
		}
		ul {
			width: 100%;
			li {
				margin-bottom: rem($space-xsmall);
				break-inside: avoid-column;
			}
		}

		.button.button--icon {
			position: relative;
			top: auto;
			right: auto;
			width: rem(24);
			height: rem(24);
			vertical-align: middle;

			display: inline-block;

			.svg-icon {
				fill: $color-text;
				width: rem(24);
				height: rem(24);
			}
		}
	}
}
