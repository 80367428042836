// Card
//
// DOD: done
//
// The card is a central interface element. It can take numerous forms and can be inserted in numerous ways.<br/>
// Without a theme, the card is white. The beige background is the secondary theme color - case in point, a card needs a theme to appear correctly, so all cards below have the theme-uvm class.<br/>
// The card below is not representative, as there's a lot of logic involved. Therefore, make sure to look at the example page.<br/>
//
// .theme-uvm.card								- Default card
// .theme-uvm.card--significant					- Significant card, more spacing around text
// .theme-uvm.card--board						- Centered content, crown, usually used two by two
// .theme-uvm.card--horizontal.has-image		- Usually wide, image on the left, text on the right, needs has-image class
// .theme-uvm.card--entry						- Card with centered icon and title for site entries
// .theme-uvm.card--entry.card--theme			- Entry card in UVM theme colors
// .theme-uvm.card--banner						- Used as a full width banner only in theme color, has an embedded button instead of entire area being clickable (not possible in styleguide)
// .theme-uvm.card--banner.card--theme			- Card in UVM theme, darker than entry cards
//
// Markup: ../../templates/prototype/partials/blocks/card.twig
//
// hidedefault: true
//
// Style guide: blocks.card


.card {
	position: relative;
	display: block;
	// border-right:rem(1) solid $color-white;

	@include theme-background-color('secondary');
	//@include theme-background-color('card-background');		
		.theme-trivsel &		
		{
			background-color: $color-pink-trivsel;
		}
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &
		{
			background-color: $color-tidal-pool;
		}

	.type {
		display: block;
		font-size:rem(14);
		@include theme-color('primary-1');
		text-transform: uppercase;
		margin-bottom: rem($space-small);
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &
		{
			color: $color-white;
		}
	}
	
	h2 {
		position: relative;
		@extend %headline-2;
		//color: $color-black;
		@include theme-color('card-text');

		@include hyphenation;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 100%;
			height: rem(5);
			width: rem(50);
			background: $color-black;
			@include mq($from: screen-sm) {
				bottom: rem(-20);
			}			
			.theme-trivsel &			
			{
				display: none;
			}
			.theme-stuk &,
			.theme-stil &,
			.theme-uvm &,
			.theme-kanalen &
			{
				display: none;
			}
		}
		&.no-ruler:before {
			display: none;
		}
		.theme-trivsel &	
		{
			color: $color-smalt-trivsel;
		}
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &
		{
			color: $color-white;
		}
	}
	h3 {
		@extend %headline-3;
		//color: $color-black;
		@include theme-color('card-text');
		overflow: hidden;
		text-overflow: ellipsis;
		.theme-trivsel &		
			{
				color: $color-smalt-trivsel;
			}
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &
		{
			color: $color-white;
		}
	}
	h4 {
		//color: $color-black;
		@include theme-color('card-text');
		.theme-trivsel &		
			{
				color: $color-smalt-trivsel;
			}
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &
		{
			color: $color-white;
		}
	}
	h5 {
		@extend %headline-5;
		//color: $color-black;
		@include theme-color('card-text');
		.theme-trivsel &		
			{
				color: $color-smalt-trivsel;
			}
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &
		{
			color: $color-white;
		}
	}


	h1,h2,h3,h4,h5{
		.theme-trivsel &,
		.theme-uvm &,
		.theme-stuk &,
		.theme-stil &,
		.theme-kanalen &
		{
			font-family: var(--font-primary)!important;
			font-weight: 700;
		}	
	}
	.styleguide-element & h4 {
		display: none;
	}
	p {
		margin-top: rem($space-xsmall);

		@extend %bodytext-lg;

		color: $color-grey-1;

		.icon {
			transition: transform 0.3s ease-out;
			transform: translateX(8px);
			overflow: hidden;
			display: inline-flex;
		}
		.svg-icon {
			transform: translateX(-8px);
			transition: transform 0.3s ease-out;
			width: rem(20);
			height: rem(20);
			vertical-align: middle;
			fill: $color-black;
		}
		.theme-trivsel &,
		.theme-uvm &,
		.theme-stuk &,
		.theme-stil &,
		.theme-kanalen &
		{
			font-family: var(--font-primary);
			font-weight: normal
		}	

		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &
		{
			color: $color-white;
		}
	}
	h2 ~ p {
		margin-top: rem($space-large);
	}
	.crown {
		display: none;
		.svg-icon {
			width:rem(30);
			height:rem(30);
			fill:$color-black;
		}
	}
	&.button {
		transition:opacity 0.2s ease-out;
		cursor: auto; // only pointer on <a> which is img and text

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: rem(0);
			opacity:0;
			background: $color-black;
			transition:opacity 0.2s ease-out;
		}

		
		&:hover {
			opacity: 0.9;

			&:after:not(.b--bottom) {
				opacity:1;
				height:rem(5);
			}
		}
		
	}

	h3 + .button--subnav,
	p + .button--subnav {
		margin-top: rem($space-large);
	}

	&__image {
		position: relative;

		> img {
			padding-top:rem($space-large);
			position: relative;
			display: block;
			width:30%;
			max-width:rem(200);
			height: auto;
			margin:0 auto;
			opacity:0.1;
		}
		.svg-icon {
			display: none;
		}

		.image {
			width: 100%;
			// height: 100%;
			display: block;
			background-size:cover;
			background-position:center center;
			background-repeat:no-repeat;
			transform: translateZ(0);
			min-height: rem(200);

			&__inner {
				// visibility:hidden;
				@include objectfit;
			}
		}


	}

	&__text {
		max-width: 100%;
		//padding: 0 rem($space-medium) rem($space-medium);
		padding: rem($space-medium) rem($space-medium) rem($space-medium);
		@include mq($from: screen-sm) {
			//padding: 0 rem($space-xxlarge) rem($space-xxlarge);
			padding: rem($space-xlarge) rem($space-xxlarge) rem($space-xxlarge);
		}

		h4 {
			@extend %headline-4;
			font-size: rem(20);
			margin-bottom: 0.5em;
		}

		// JPL: Codehouse Integration
		iframe {
			margin-top: rem($space-large);

			@include mq($until: screen-xs) {
				max-width: 100%;
				width: 100% !important;
			}
			@include mq($from: screen-md) {
				max-width: 100% !important;
				height: auto !important;
			}
		}
		.button--large, .button--medium {
			display: none;
		}
		.text{
			.theme-stuk &,
			.theme-stil &,
			.theme-uvm &
			{
				color: $color-white;
			}
		}
		svg{
			.theme-stuk &,
			.theme-stil &,
			.theme-uvm &
			{
				fill: $color-white!important;
			}
		}
		
	}

	&__image + &__text, .facebook-wrapper + &__text {
		padding-top: rem($space-medium);
		@include mq($from: screen-sm) {
			padding-top: rem($space-xlarge);
		}
	}

	&--significant {

		.type {
			font-size:rem(14);
		}

		.card__text {
			@include mq($from: screen-md) {
				padding:rem($space-xlarge) rem(70) rem($space-xxxlarge);
			}
		}
	}

	&--board {
		padding:rem($space-xxxlarge) 0;
		text-align: center;

		@include mq($until: screen-md) {
			margin-bottom: 2%;
		}

		.crown {
			display: block;
			margin-bottom: rem(10);
		}
		.card__image {
			display: none;
		}
		.styleguide-element & h4 {
			display: block;
		}
		h2:before {
			display: none;
		}

		.styleguide-element & {
			h2, h3 {
				display: none;
			}
		}

		.button--link {
			.text {						
				font-weight: 400;
				&:hover{
					color: $color-black;
				}
			}
		}
	}

	&--entry {
		text-align: center;

		@include mq($from: screen-sm) {
			padding: rem($space-xxxlarge) 0;
		}
		.crown {
			display: none;
		}
		.card__image {
			.image {
				display: none;
			}
		}

		.svg-icon {
			display: inline-block;
			height: rem(50);
			width: rem(50);
			fill: $color-text;
		}
		h2 {
			display: none;
		}

		.styleguide-element & h4 {
			display: block;
		}
		h3, h4 {
			@extend %headline-4;
			font-size: rem(22);
			display: block;
		}
		p {
			@extend %bodytext-md;
		}

		.card__text {
			padding-bottom: 0;
			@include mq($from: screen-sm) {
				padding-bottom: 0;
			}
		}

		@include mq($until: screen-sm) {
			display: flex;
			padding: rem($space-medium);
			margin-bottom: 2%;

			.card__text {
				text-align: left;
				margin-left: rem($space-medium);
				padding: 0;
				h3 {
					font-size: rem(20);
				}
				p {
					font-size: rem(15);
					margin-top: rem($space-xxsmall);
				}
			}
			.card__image {
				flex: 0 0 rem(40);
			}
		}


		.grid & {
			margin-bottom: 2%;
		}
		.grid.grid--short & {
			@include mq($from: screen-xs) {
				margin-bottom: 0;
			}
		}
		&.card--theme-darker{
			.theme-stuk &,
			.theme-stil &,
			.theme-uvm &
			{
				background-color: $color-tidal-pool;
			}
			.card__text h5, .card__text p{
				.theme-stuk &,
				.theme-stil &,
				.theme-uvm &
				{
					color: $color-white;
				}
				.svg-icon{
					.theme-stuk &,
					.theme-stil &,
					.theme-uvm &
					{
						fill: $color-white;
					}
				}
			}
		}

		&:hover p {
			.svg-icon {
				transform: translateX(0px);
			}
			.icon {
				transform: translateX(10px);
			}
		}
	}

	&--horizontal {

		&.has-image {
			@include mq($from: screen-sm) {
				display: flex;
				justify-content: space-between;
			}
			.card__image {
				flex: 1 1 rem(200);

				.image {
					height: 100%; 
				}
			}
			.card__text {
				flex: 1 1 60%;
				margin-left: rem($space-medium);
				box-sizing: border-box;
			}
		}
	}


	&--banner {
		&.button {
			cursor: default;

			&:hover {
				opacity: 1;
			}
			&:after {
				display: none;
			}
		}
		padding: rem($space-medium) rem($space-small);

		@include mq($from: screen-xs) {
			padding: rem($space-large);
		}
		@include mq($from: screen-sm) {
			text-align: center;
			padding: rem($space-xxxlarge);
		}
		@include mq($from: screen-lg) {
			padding: rem(60);
		}
		.card__text {
			padding: 0;

			> h2 {
				@extend %headline-2;
				&:before {
					display: none;
				}
			}
		}
		h2 ~ p {
			margin-top: 1em;
		}
		p {
			@extend %bodytext-lg;

			@include mq($until: screen-sm) {
				margin-bottom: 1em;
				font-size: 1em;
			}
		}

		.button {
			margin-top: rem($space-large);
			display: inline-block;
		}

		.card__text {
			padding: 0;
		}
		.form__field--checkbox {
			margin-bottom: 0;
		}
		.card__image {
			.image {
				display: none;
			}
		}

		&.card--theme {
			//@include theme-background-color('primary-4');
			@include theme-background-color('card--theme-banner-background');
		}
		&.theme-uvm:not(.card--theme) {
			.card__text {
				.button {
					background: $color-grey-2;

				}
			}
		}
	}


	&--theme {
		//@include theme-background-color('primary-5');
		@include theme-background-color('card--theme-background');
		:not(.icon) > .svg-icon {
			@include theme-fill('primary-1');
		}
	}


	&--theme-darker {
		//@include theme-background-color('primary-4');
		@include theme-background-color('card--theme-darker-background');
		:not(.icon) > .svg-icon {
			@include theme-fill('primary-1');
		}
	}



	&.grid-item {
		// width: 100%;
	}

	.grid-item &:not(.card--banner) {
		height: 100%;
	}

	.form {
		max-width: rem(600);
		margin: rem(20) auto 0;

		.form__field--checkbox {
			margin-top: rem($space-medium);
		}
	}

	&--image-link {
		height: auto !important;
		border-bottom: 5px solid $color-border;
		@include themify('border-color', 'primary-3');
		@include theme-background-color('primary-4');
		display: flex;
		align-items: center;
		justify-content: center;
		padding: rem(20) rem(50);
		min-height: 100px;

		img {
			max-width: 100%;
			max-height: 60px;
			opacity: 0.6;
		}

		@include mq($from: screen-md) {
			min-height: 150px;
			padding: rem(50) rem(100);

			img {
				max-height: 80px;
				max-width: 350px;
			}
		}
	}

	&__arrow {
		.button & .icon {
			display: block;
			@include themify('color', 'primary-3');

			.svg-icon {
				width: rem(50);
				height: rem(28);
				fill: currentColor;
			}
		}
	}
}

.theme-trivsel .card--board .card__text a.button .text{
	color: $color-black!important;
}
.theme-trivsel .card--board .card__text a.button .icon .svg-icon{
	fill: $color-black!important;
}
	
.theme-stuk,
.theme-stil,
.theme-uvm
{
	.card--banner.card--theme{
		background: $color-tranquil;
		.card__text{
			h1,h2,h3,h4,h5{
				color: #000!important;
			}
		}
	}
}
