// Entries navigation
//
// DOD: done
//
// This component is used for the frontpages
//
// .theme-uvm						- Shown in uvm theme
// .theme-stuk						- Shown in stuk theme
// .theme-stil						- Shown in stil theme
// .theme-sosu						- Shown in sosu theme
//
// Markup: ../../templates/prototype/partials/blocks/entries-navigation.twig
//
// hidedefault: true
//
// Style guide: blocks.entriesnavigation


.entries-navigation {
	position: relative;
	@include clearfix;

	@include mq($from: screen-sm) {
		margin-bottom: rem($space-xxlarge);
	}

	overflow:hidden;

	.deck {
		padding: 0;
	}

	.deck .w--normal>h3 {
		display: none;
	}

	.entries-navigation__primary {
		@include theme-background-color('entries-nav-bg');	
		.theme-trivsel &
		{
			background-color: $color-tranquil-trivsel;
		}
	}


	&.entries-navigation--mobile {
		@include mq($from: screen-sm) {
			display: none;
		}
	}



	&.entries-navigation--5-col {
		&.entries-navigation--mobile {
			@include mq($from: screen-sm) {
				display: block;
			}

			@include mq($from: screen-sm-plus) {
				display: none;
			}
		}
	}


	&--tablet {
		display: none;

		@include mq($from: screen-sm) {
			display: block;
		}

		@include mq($from: screen-md) {
			display: none;
		}
	}

	&.entries-navigation--5-col {
		&.entries-navigation--tablet {
			@include mq($from: screen-sm) {
				display: none;
			}

			@include mq($from: screen-sm-plus) {
				display: block;
			}

			@include mq($from: screen-xl) {
				display: none;
			}
		}
	}


	&.entries-navigation--desktop {
		display: none;

		@include mq($from: screen-md) {
			display: block;

		}
	}

	&.entries-navigation--5-col {
		&.entries-navigation--desktop {
			@include mq($from: screen-md) {
				display: none;
			}

			@include mq($from: screen-xl) {
				display: block;
			}
		}
	}

	.entries-navigation__primary__content {
		display: flex;

		@include mq($from: screen-xl) {
			justify-content: center;
		}

		.button--entry {
			max-width: 100%;
		}
	}

	.entries-navigation__secondary {
		position: relative;
		transition: height 0.15s ease-out, max-height 0.15s ease-out;
		display: none;	
		.theme-trivsel &
		{
			background-color: $color-tidal-pool-trivsel!important;
		}
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &
		{
			background-color: $color-tidal-pool;
		}
		

		&.is-active {
			height: 0;
			display: block;
		}

		&.is-animating {}

		.grid {
			margin-left: rem($space-large);
			padding: rem($space-medium) 0;
			margin-top: 0;

			@include mq($from: screen-sm) {
				margin-left: 0;
				padding: rem($space-medium) rem(60);
			}

			@include mq($from: screen-sm-plus) {
				padding: rem($space-xlarge) rem(100);
			}
		}

		ul li {
			margin: 0 0 rem($space-small);

			@include mq($from: screen-sm) {
				margin: 0 0 rem($space-large);
			}
		}

		.button--link--heavy {
			display: inline-flex;
		}

		.button--subentry {
			display: inline-flex;
			font-size: rem(18);

			.text {
				font-weight: 400;
				font-size: rem(18);
				line-height: rem(20);
				display: inline;					
				.theme-trivsel &
				{
					color: $color-white;
				}
				.theme-stuk &,
				.theme-stil &,
				.theme-uvm &
				{
					color: $color-white;
				}
				.theme-sosu &,
				.theme-spsu &,
				.custom-icon-font &
				{
					font-size: 17px;
				}

			}

			&:hover {
				background: none;

				.text,
				.svg-icon {
					@include theme-color('primary-2');
					@include theme-fill('primary-2');					
						
					.theme-trivsel &
					{
						fill: $color-white;
						color: $color-white;				
				
					}	
					.theme-uvm &
					{
						fill: $color-white;
						color: $color-white;
					}
					.theme-stuk &
					{
						fill: $color-white;
						color: $color-white;
					}
					.theme-stil &
					{
						fill: $color-white;
						color: $color-white;
					}
				}
			}

			.icon {
				height: rem(20);
				line-height: rem(20);
				display: inline-block;
				transition: transform 0.3s ease-out;
				transform: translateX(0px);
				overflow: hidden;
				vertical-align: top;
			}

			.svg-icon {
				transition: transform 0.3s ease-out;
				width: 1em;
				height: 1em;
				vertical-align: middle;
				fill: $color-grey-1;

				@include mq($from: screen-sm) {
					transform: translateX(-10px);
				}
				
				.theme-trivsel &
				{
					fill: $color-white;
				}
				.theme-stuk &,
				.theme-stil &,
				.theme-uvm &
				{
					fill: $color-white;
				}
			}

			@include mq($from: screen-sm) {
				&:hover {
					.text {
						border-color: $color-black;
					}

					.svg-icon {
						transform: translateX(0px);
					}
				}
			}
		}

		.button--icon {
			background: none;
			z-index: 100;

			.svg-icon {
				width: 2em;
				height: 2em;
				fill: $color-black;				
				&:hover{						
					.theme-trivsel &
					{
						fill: $color-white;
					}
				}				
				.theme-trivsel &
				{
					fill: $color-white;
				}
				&:hover{					
					.theme-stuk &,
					.theme-stil &,
					.theme-uvm &
					{
						fill: $color-white;
					}
				}
				.theme-stuk &,
				.theme-stil &,
				.theme-uvm &
				{
					fill: $color-white;
				}
			}
		}
	}

	.entries-navigation__secondary__content {
		display: none;
		opacity: 0;
		transform: scaleY(0.9);
		transform-origin: top center;
		transition: opacity 0.15s ease-out, transform 0.15s ease-out;
		padding-right: rem($space-xxxxlarge);

		@include mq($from: screen-sm) {
			padding-right: 0;
		}

		&.is-active {
			display: block;
		}

		&.is-animating {
			opacity: 1;
			transform: scaleY(1);
		}

		ul li:last-child {
			margin-bottom: 0;
		}


		.arrow {
			left: 50%
		}

		@include mq($from: screen-sm) {

			&[data-contentid="0"],
			&[data-contentid="2"],
			&[data-contentid="4"] {
				.arrow {
					left: 25%
				}
			}

			&[data-contentid="1"],
			&[data-contentid="3"],
			&[data-contentid="5"] {
				.arrow {
					left: 75%
				}
			}

		}

		@include mq($from: screen-md) {

			&[data-contentid="0"],
			&[data-contentid="3"] {
				.arrow {
					left: 16.66667%
				}
			}

			&[data-contentid="1"],
			&[data-contentid="4"] {
				.arrow {
					left: 50%
				}
			}

			&[data-contentid="2"],
			&[data-contentid="5"] {
				.arrow {
					left: 83.333333%
				}
			}

		}

	}

	.arrow {
		position: absolute;
		top: rem(-10);
		left: rem(50);
		width: rem(20);
		height: rem(20);
		@include theme-background-color('primary-4');
		content: '';
		transform-origin: center center;
		transform: translateX(-50%) rotateZ(45deg);
		transition: left 0.2s ease-out;			
		.theme-trivsel &
		{
			background-color: $color-tidal-pool-trivsel;
		}
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &
		{
			background-color: $color-tidal-pool;
		}
	}



	.button--icon {
		position: absolute;
		top: rem($space-small);
		right: rem($space-small);

		@include mq($from: screen-sm) {
			top: rem($space-large);
			right: rem($space-large);
		}
	}



	&--desktop--4-col {
		.button--entry {
			@include mq($from: screen-md) {
				width: 25%;
				padding: 0 rem($space-medium);

				p {
					overflow: hidden;
				}
			}
		}

		.entries-navigation__secondary__content {

			@include mq($from: screen-md) {
				&[data-contentid="0"] {
					.arrow {
						left: 12.5%
					}
				}

				&[data-contentid="1"] {
					.arrow {
						left: 37.5%
					}
				}

				&[data-contentid="2"] {
					.arrow {
						left: 62.5%
					}
				}

				&[data-contentid="3"] {
					.arrow {
						left: 87.5%
					}
				}


			}
		}
	}

	&--5-col {
		.button--entry {
			@include mq($from: screen-xl) {
				width: 20%;
				padding: 0 rem($space-medium);

				p {
					overflow: hidden;
				}
			}
		}

		.entries-navigation__secondary__content {
			&[data-contentid] {
				.arrow {
					left: 50%;
				}
			}

			@include mq($from: screen-sm) {

				&[data-contentid="0"],
				&[data-contentid="2"],
				&[data-contentid="4"] {
					.arrow {
						left: 25%
					}
				}

				&[data-contentid="1"],
				&[data-contentid="3"],
				&[data-contentid="5"] {
					.arrow {
						left: 75%
					}
				}

			}



			@include mq($from: screen-xl) {
				&[data-contentid="0"] {
					.arrow {
						left: 10%
					}
				}

				&[data-contentid="1"] {
					.arrow {
						left: 30%
					}
				}

				&[data-contentid="2"] {
					.arrow {
						left: 50%
					}
				}

				&[data-contentid="3"] {
					.arrow {
						left: 70%
					}
				}

				&[data-contentid="4"] {
					.arrow {
						left: 90%
					}
				}


			}
		}
	}

}
