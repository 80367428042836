.main-navigation {
	$module: '.main-navigation';

	position: relative;
	padding: rem($space-xxsmall) 0;

	display: flex;
	justify-content: space-between;
	align-items: center;

	&--slim {
		@include mq($from: screen-sm-plus){
			padding: 0;
		}
		.button--logo.button--logo--subsite {
			@include mq($from: screen-sm-plus){
				max-width: rem(180);
			}
		}
	}
	.theme-trivsel &
	{
		a.button--logo{
			width: 100%;
			height: 100%;
		}
	}
	

	&:not(&--slim) {
		@include mq($from: screen-sm){
			padding: 0;
		}
	}


	li {
		margin: 0;
		padding: 0;
	}
	.svg-icon {
		fill: $color-black;
	}

	@mixin mobile-active-state {
		.main-navigation__list {
			max-height: none;
			overflow: scroll;
			visibility: visible;
			transform: scaleY(1);
			position: fixed;
			top: rem(74);
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 8090; //to be on top of sticky
			background: $color-white;
			border-bottom: rem(4) solid $color-black;
		}
		.main-navigation__item {
			opacity: 1;
			transition: opacity 0.2s ease-out 0.2s;
		}
		.button--burger {
			appearance: none;
			background-color: transparent;
			border: none;

			.icon2 {
				transition: all 0.1s ease-out 0.05s;
				opacity: 1;
				transform: translateX(-50%) translateY(-50%) rotateZ(0deg);

			}
			.icon {
				transition: all 0.1s ease-out;
				transform: translateX(-50%) translateY(-50%) scaleX(0);
				opacity: 0;
			}
		}
	}


	&.is-active {
		&#{$module}--slim {
			@include mq($until: screen-sm-plus){
				@include mobile-active-state;
			}
		}
		&:not(#{$module}--slim) {
			@include mq($until: screen-sm){
				@include mobile-active-state;
			}
		}
	}


	&__list {
		padding: 0;
		max-height: 0;
		// overflow: hidden; //JPL: Why? Cuts off focus rect
		visibility: hidden;

		transform: scaleY(0);
		transform-origin: top center;
		backface-visibility: hidden;

		transition: transform 0.2s ease-out;

		position: absolute;
		top: rem(66);

		@mixin list-desktop-state {
			max-height: none;
			visibility: visible;
			transform: scaleY(1);
			text-align: right;

			position: relative;
			top: auto;
			flex: 1;

			&:hover {
				.button--nav.is-active {
					&:after {
						transform: scaleX(0);
					}
					&:hover {
						&:after {
							transform: scaleX(1);
						}
					}
				}
			}
		}

		#{$module}#{$module}--slim & {
			@include mq($from: screen-sm-plus) {
				@include list-desktop-state;
			}
		}

		#{$module}:not(#{$module}--slim) & {
			@include mq($from: screen-sm) {
				@include list-desktop-state;
			}
		}
	}


	&__item {
		position: relative;
		display: block;
		font-size: 0;

		transition: all 0.2s ease-out;
		opacity: 0;

		> * {
			font-size: 1rem;
		}
		@mixin nav-item-desktop-state {
			background: transparent;
			position: relative;
			display: inline-block;
			border-bottom: none;

			opacity: 1;
		}

		#{$module}#{$module}--slim & {
			@include mq($from: screen-sm-plus){
				@include nav-item-desktop-state;
			}
		}
		#{$module}:not(#{$module}--slim) & {
			@include mq($from: screen-sm){
				@include nav-item-desktop-state;
			}
		}

		&--mobile {
			display: block;

			#{$module}#{$module}--slim & {
				@include mq($from: screen-sm-plus){
					display: none;
				}
			}
			#{$module}:not(#{$module}--slim) & {
				@include mq($from: screen-sm){
					display: none;
				}
			}

			+ .main-navigation__item:nth-child(2) .button--nav {
				margin-top: rem($space-xlarge);
				@include mq($from: screen-xs){
					margin-top: 0;
				}
			}
		}

	}

	.button--logo {
		overflow: visible;
		max-width: rem(180);
		@include mq($from: screen-sm){
			max-width: rem(300);
		}
		@include mq($from: screen-sm-plus){
			max-width: rem(360);
		}
		position: relative;

		.print-icon {
			display: none;
		}

		.text {
			display: inline-block;
			//color: $color-black;
			@include theme-color('header-text');			
			font-weight: 700;
			font-size: 13px;		
			line-height: 1.2em;
			letter-spacing: 0.04em;
			padding: 0 0 0 rem(40);
			.theme-sosu &
			.theme-spsu &,
			.custom-icon-font &
			{
				white-space: nowrap;
				font-size: 17px!important;
			}
			@include mq($from: screen-sm){				
				line-height: 1.1em;				
				padding-left: rem(1);
				position: relative;
				top: 0.7rem;
				left: 1rem;
			}			
			
			.secondary-logo &{
				position: static;			
				margin-left: 3rem;
				padding-left: 0.6rem;
				@include mq($from: screen-sm){						
					margin-left: 2.7rem;
					padding-left: 0.5rem;
				}
			}	
			
			
			&--trivsel{
				display: none;
			}
		}
		.subtext {
			display: inline-block;
			//color: $color-black;
			@include theme-color('header-text');			
			font-weight: 700;
			font-size: rem(11);
			line-height: 1.2em;
			padding: 0 0 0 rem(40);
			@include mq($from: screen-sm){
				max-width: rem(140);
				padding: 0.4em 0 0 rem(42);
				line-height: 1.1em;
				font-size: rem(13);
				.theme-sosu &,
				.theme-spsu &,
				.custom-icon-font &
				{
					white-space: nowrap;
					font-size: 17px;
				}
			}
			@include mq($from: screen-sm-plus){
				max-width: none;
				min-width: rem(300);
				padding: 0.3em 0 0 rem(45);
				font-size: rem(13);
			}
		}
		.icon {
			display: inline-block;
			position: absolute;
			left: 0;
			top: -0.3rem;
			@include mq($from: screen-sm){			
				top: -1.9rem;
			}
			.secondary-logo &{
				left: .2rem;
				border-right: 1px solid #000;
    			padding-right: 0.5rem;
				@include mq($from: screen-sm){
					top: -0.4rem;
					left: 0;
				}
				.theme-trivsel &{
					border-right: 0;
				}
			}		
			&--trivsel{
				min-width: 7rem;
				top: -1.8rem;				
				@include mq($from: screen-sm){
					min-width: 7rem;
					top: -2.6rem;
					left: 0;
				}
			}
		}
		.svg-icon {
			height: rem(35);
			width: rem(35);			
			fill: $color-black;			
		}
		.svg-icon--trivsel{
			height: 3.625rem;
			width: 3.625rem;		
			@include mq($from: screen-sm){
				height: 5.1875rem;
				width: 5.1875rem;
			}		
		}
		&:before,
		&:after {
			display: none;
		}

		&:hover {
			opacity: 0.8;
		}

		&--subsite {
			max-width: rem(190);

			@include mq($from: screen-sm){
				max-width: rem(180);
			}
			@include mq($from: screen-sm-plus){
				max-width: rem(250);
			}			
			.text {
				display: inline-block;
				//color: $color-black;
				@include theme-color('header-text');				
				font-weight: 700;
				font-size: 13px;		
				line-height: 1.2em;
				letter-spacing: 0.04em;
				padding: 0 0 0 rem(40);
				.theme-sosu &,
				.theme-spsu &,
				.custom-icon-font &
				{
					font-size: 17px!important;
					white-space: nowrap;
				}
				@include mq($from: screen-sm){				
					line-height: 1.1em;				
					padding-left: rem(1);
					position: relative;
					top: 0.7rem;
					left: 1rem;
				}			
				
				.secondary-logo &{
					position: static;				
					margin-left: 3rem;
					padding-left: 0.6rem;
					@include mq($from: screen-sm){						
						margin-left: 2.7rem;
						padding-left: 0.5rem;
					}
					.theme-trivsel &{
						border-right: 0;
					}
				}	
				
							
				
			}
			.svg-icon {
				height: rem(35);
				width: rem(35);			
				fill: $color-black;			
			}
			.icon{
				display: inline-block;
				position: absolute;
				left: 0;
				top: -0.3rem;						    
    			padding-right: 0.5rem;
				@include mq($from: screen-sm){			
					top: -1.9rem;
				}
				.secondary-logo &{
					border-right: 1px solid #000;
					left: .2rem;
					@include mq($from: screen-sm){
						top: -0.4rem;
						left: 0;
					}
				}	
					
			}

		}

		&--compact {
			max-width: rem(130);

			@include mq($from: screen-sm){
				max-width: rem(130);
			}
			@include mq($from: screen-sm-plus){
				max-width: rem(130);
			}
			@include mq($from: screen-md){
				max-width: rem(250);
			}
			.theme-sosu &,
			.theme-spsu &,
			.custom-icon-font &
			{
				font-size: 17px!important;
				white-space: nowrap;
			}
			
			.text {
				display: inline-block;
				//color: $color-black;
				@include theme-color('header-text');			
				font-weight: 700;
				font-size: 13px;		
				line-height: 1.2em;
				letter-spacing: 0.04em;
				padding: 0 0 0 rem(40);

				@include mq($from: screen-sm){				
					line-height: 1.1em;				
					padding-left: rem(1);
					position: relative;
					top: 0.7rem;
					left: 1rem;
				}			
				
				.secondary-logo &{
					@include mq($from: screen-sm){
						position: static;					
						margin-left: 2.7rem;
						padding-left: 0.5rem;
					}
				}	
				
				
			}
			.secondary-logo &{
				@include mq($from: screen-sm){
					top: -0.2rem
				}
			}		
			.subtext {
				@include mq($from: screen-sm){
					font-size: rem(11);
				}
				@include mq($from: screen-sm-plus){
					max-width: rem(130);
					min-width: 0;
					font-size: rem(12);
				}
				@include mq($from: screen-md){
					min-width: rem(180);
				}
			}
		}

	}

	&__tools {
		display: flex;
		align-items: center;

		@mixin tools-desktop-state {
			align-self: flex-end;
			display: flex;
			justify-content: space-between;
		}

		#{$module}#{$module}--slim {
			@include mq($from: screen-sm-plus){
				@include tools-desktop-state;
			}
		}
		#{$module}:not(#{$module}--slim) {
			@include mq($from: screen-sm){
				@include tools-desktop-state;
			}
		}
		.button--icon {
			position: relative;
			width: rem($space-xlarge);
			height: rem($space-xlarge);
			margin-left: rem($space-xsmall);

			.icon {
				width: rem($space-xlarge);
				height: rem($space-xlarge);
			}
			.svg-icon {
				width: rem(25);
				height: rem(25);
			}
			.text {
				margin: 0;
				position: absolute;
				top: rem(-2);
				right: rem(-2);
				width: rem(15);
				height: rem(15);
				overflow: hidden;
				border-radius: 50% 50%;
				font-size: rem(10);
				font-weight: 700;
				display: flex;
				align-items: center;
				justify-content: center;
				@include theme-background-color('primary-1');
				color: $color-white;
			}

			display: none;
			@include mq($from: screen-sm){
				display: inline-block;
			}

			&.is-persistent {

				display: inline-block;

				@include mq($until:screen-sm){
					&:hover,
					&:active {
						.svg-icon {
							fill: $color-black;
						}
					}
				}
			}


		}

		.text {
			color: $color-white;
			display: block;
			font-size: rem(12);
			font-weight: 500;
			text-align: center;
			margin-top: rem(30);
		}
		.icon,
		.icon2,
		.svg-icon {
			width: rem(24);
			height: rem(16);
		}
		.icon,
		.icon2 {
			position: absolute;
			top: 50%;
			left: 50%;
			display: inline-block;
		}
		.icon {
			transform: translateX(-50%) translateY(-50%) scaleX(1);
			transition: all 0.2s ease-out;
		}
		.icon2 {
			transition: all 0.1s ease-out;
			transform: translateX(-50%) translateY(-50%) rotateZ(-90deg);
			opacity: 0;
		}

		.button--burger {
			padding: 0;
			display: inline-block;
			height: rem(40);
			width: rem(40);

			#{$module}#{$module}--slim & {
				@include mq($from: screen-sm-plus){
					display: none;
				}
			}
			#{$module}:not(#{$module}--slim) & {
				@include mq($from: screen-sm){
					display: none;
				}
			}

			.text {
				color: $color-white;
				display: block;
				font-size: rem(12);
				font-weight: 500;
				text-align: center;
				margin-top: rem(30);
			}
			.icon,
			.icon2,
			.svg-icon {
				width: rem(24);
				height: rem(24);
			}
			.icon,
			.icon2 {
				position: absolute;
				top: 50%;
				left: 50%;
				display: inline-block;
			}
			.icon {
				transform: translateX(-50%) translateY(-50%) scaleX(1);
				transition: all 0.2s ease-out;
			}
			.icon2 {
				transition: all 0.1s ease-out;
				transform: translateX(-50%) translateY(-50%) rotateZ(-90deg);
				opacity: 0;
			}

		}

		.speak-icon {
			position: relative;
			width: 2.5rem;
			height: 2.5rem;
			margin-left: rem(10);
			background-color: $color-grey-5;
			border-radius: 50%;
			padding: 0;
			transition: background-color 0.2s;

			.icon, .icon svg {
				width: 1.5rem;
				height: 1.5rem;
			}

			.icon {
				transition: color 0.2s;
				svg {
					fill: currentColor;
				}
			}

			&:hover {
				background-color: $color-black;
				.icon {
					color: $color-white;
				}
			}
		}
	}

 	.button--nav {
		padding: rem($space-medium) rem($space-small);

		display: block;
		position: relative;
		overflow: visible;


		#{$module}#{$module}--slim & {
			@include mq($from: screen-sm-plus){
				display: inline-block;
			}
		}
		#{$module}:not(#{$module}--slim) & {
			@include mq($from: screen-sm){
				display: inline-block;
			}
		}


		@include mq($from: screen-sm){
			padding: rem($space-xsmall);
		}
		@include mq($from: screen-sm-plus){
			padding: rem($space-xsmall) rem($space-small);
		}
		@include mq($from: screen-md){
			padding: rem($space-xsmall) rem($space-large);
		}

		.text {
			//color: $color-black;
			@include theme-color('header-text');
			font-weight: 300;
			font-size: rem(20);
			line-height: 1.25em;
			@include mq($from: screen-sm){
				font-size: rem(16);
			}
			@include mq($from: screen-sm-plus){
				font-size: rem(18);
			}

			em {
				@include mq($from: screen-xl){
					display: none;
				}
			}
			span {
				@include mq($until: screen-xl){
					display: none;
				}
			}
		}


		&:after {
			.theme-kanalen &{
				background:$color-tidal-pool;
			}
			display: block;
			content:'';
			position: absolute;
			bottom: rem(3);
			left: rem($space-small);
			right: rem($space-small);
			@include mq($from: screen-md){
				left: rem($space-xlarge);
				right: rem($space-xlarge);
			}
			background: $color-grey-4;
			height: rem(2);
			transform: scaleX(0);
			transition: transform 0.2s ease-out;			
		}

		@include mq($from: screen-sm){
			&:hover {
				&:after {
					transition: transform 0.2s ease-out;
					transform: scaleX(1);
				}
			}
		}
		&.is-active {
			.text {
				font-weight: 500;
			}
			@mixin button-nav-active-state-desktop {
				.text {
					font-weight: 300;
				}
				&:after {
					transform: scaleX(1);
				}
			}
			#{$module}#{$module}--slim & {
				@include mq($from: screen-sm-plus){
					@include button-nav-active-state-desktop;
				}
			}
			#{$module}:not(#{$module}--slim) & {
				@include mq($from: screen-sm){
					@include button-nav-active-state-desktop;
				}
			}
			&:after {
				.theme-kanalen &{
					background: $color-tidal-pool;
				}
				display: block;
				content:'';
				position: absolute;
				bottom: rem(3);
				left: rem($space-small);
				right: rem($space-small);
				@include mq($from: screen-md){
					left: rem($space-xlarge);
					right: rem($space-xlarge);
				}				
				height: rem(2);
				transform: scaleX(0);
				transition: transform 0.2s ease-out;			
			}
		}
		.icon {
			padding-left: 0.5rem;
		}
	}

	.form {
		input {
			@extend %form-item;
			display: block;
			background: $color-grey-5;
			border: none;
			border-radius: none;
			line-height: 1em;
			font-size: rem(18);
			font-weight: 300;
			box-sizing: border-box;
			padding: rem(10) rem(20);
			height: rem(60);
			line-height: rem(30);
			padding-right: rem(40);
		}
		.button--search {
			border: none;
			background: none;
			padding: rem(15);
			transition: background 0.1s ease-out;
			position: relative;


			&:before {
				position: absolute;
				content:'';
				top: 100%;
				right: 0;
				left: 0;
				bottom: 0;
				background: $color-black;
				transition: top 0.1s ease-out;
			}
			&:focus {
				outline: 0;
			}
			.icon {
				width: rem(30);
				height: rem(30);
			}
			.svg-icon {
				width: 100%;
				height: 100%;
				fill: $color-black;
				transition: fill 0.3s ease-out;
			}

			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;

			&:hover {
				&:before {
					top: 0;
				}
				.svg-icon {
					fill: $color-white;
				}
			}
		}
	}


	&--slim {
		.main-navigation__list {
			@include mq($from: screen-sm-plus){
				margin-right: rem($space-xsmall);
			}
		}
		.button--nav {
			@include mq($from: screen-sm){
				padding: rem($space-medium) rem($space-xlarge);

			}
			@include mq($from: screen-sm-plus){
				padding-left: rem($space-xsmall);
				padding-right: rem($space-xsmall);
			}

			@include mq($from: screen-md){
				padding-left: rem($space-xsmall);
				padding-right: rem($space-xsmall);
			}

			.text {
				color: $color-grey-1;
				@include mq($from: screen-sm){
					font-size: rem(20);
				}
				@include mq($from: screen-sm-plus){
					font-weight: 400;
					font-size: rem(16);
				}
				@include mq($from: screen-xl){
					font-size: rem(18);
				}
			}
		}

		.button--logo--subsite {
			@include mq($until: screen-sm){
				max-width: rem(120);
				margin-top: rem($space-xxsmall);
			}
		}

		[class^='w--'] {
			@include mq($from: screen-sm) {
				padding: 0;
			}
			@include mq($from: screen-sm-plus) {
				padding: 0 rem($space-large);
			}
		}
	}
}
