.simple-header {
	margin-bottom: rem($space-large);
	@include clearfix;

	img {
		margin-bottom: rem($space-large);
		display: block;
	}
	h1 {
		@extend %headline-3;
		font-family: var(--font-primary)!important;
	}
	h3 {
		@extend %headline-3;
		font-family: var(--font-primary)!important;
	}
	h4 {
		@extend %headline-5;
		font-family: var(--font-primary)!important;
	}

	p {
		@extend %bodytext-md;
		color: $color-grey-1;
		@include mq($from: screen-sm) {
			float: left;
		}
	}

	a {
		display: inline-block;
		color: $color-grey-1;
		text-decoration: underline;
		margin-top: rem($space-xsmall);

		@include mq($from: screen-sm) {
			margin-top: 0;
			float: right;
		}
	}
}
