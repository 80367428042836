// Link Box
//
// DOD: done
//
// Used on Kanalens frontpage
//
// .linkbox--theme-1					- Theme 1
// .linkbox--theme-2					- Theme 2
// .linkbox--theme-3					- Theme 3
// .linkbox--theme-4					- Theme 4
// .linkbox--theme-5					- Theme 5
// .linkbox--theme-6					- Theme 6
// .linkbox--theme-7					- Theme 7
//
// Markup: ../../templates/prototype/partials/blocks/linkbox.twig
//
// hidedefault: true
//
// Style guide: blocks.linkbox

.linkbox {
  a.button {
    display: flex;
    align-items: center;
    
    .text, .icon {
      color: $color-text;
      font-size: rem(10);
    }

    svg {
      fill: $color-pale-grey;
    }
    &:hover {
      svg {
        fill: $color-pale-grey;
      }
    }
  }

  &--theme-1 {
    background-color: $color-promotion-1;
  }
  &--theme-2 {
    background-color: $color-promotion-2;
  }
  &--theme-3 {
    background-color: $color-promotion-3;
  }
  &--theme-4 {
    background-color: $color-promotion-4;
  }
  &--theme-5 {
    background-color: $color-promotion-5;
  }
  &--theme-6 {
    background-color: $color-promotion-6;
  }
  &--theme-7 {
    background-color: $color-promotion-7;
  }
}