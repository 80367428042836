.panel-layout {
	$module: '.panel-layout';


	#{$module}__aside {
		margin-bottom: rem($space-large);
	}

	#{$module}__main {
		font-size: 0;
		> li {
			margin-bottom: rem($space-xsmall);
			vertical-align: top;
		}

		@include mq($from: screen-xs, $until: screen-sm) {
			> li {
				display: inline-block;
				font-size: 1rem;
				width: calc( (100% / 2) - #{rem($space-medium * (1/2))} );

				margin-right: rem($space-medium);

				&:nth-child(even) {
					margin-right: 0;
				}
			}
		}
		@include mq($from: screen-sm) {
			> li {
				display: inline-block;
				font-size: 1rem;
				width: calc( (100% / 3) - #{rem($space-medium * (2/3))} );

				margin-right: rem($space-medium);

				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
		@include mq($from: screen-md) {
			> li {
				margin-bottom: rem($space-large);
				margin-right: rem($space-large);
				width: calc( (100% / 4) - #{rem($space-large * (3/4))} );

				&:nth-child(3n) {
					margin-right: rem($space-large);
				}
				&:nth-child(4n) {
					margin-right: 0;
				}
			}
		}
	}




	&--masonry {
		font-size: 1rem;
		padding-top: rem(40);

		@include mq($from: screen-sm) {
			padding-top: 0;
			margin-right: rem(-30);
		}
	}


	&--divided {
		font-size: 0;

		@include mq($from: screen-md) {
			display: flex;
		}

		#{$module}__main {
			display: block;
			@include mq($from: screen-xs) {
				> li {
					width: calc( (100% / 2) - #{$space-medium * (1/2) * 1px} );
				}
			}
			@include mq($from: screen-sm) {
				> li {
					width: calc( (100% / 3) - #{$space-medium * (2/3) * 1px} );
				}
			}
			// @supports (-ms-ime-align:auto) {
			// 	@include mq($from: screen-sm) {
			// 		> li {
			// 			width: calc( (100% / 3) - 20px );
			// 		}
			// 	}
			// }
			// @media all and (-ms-high-contrast:none) and (min-width: 768px) {
			// 	> li {
			// 		width: calc( (100% / 3) - 20px );
			// 	}
			// }
			@include mq($from: screen-md) {
				width: calc(100% + #{rem($space-large * 0.75)});
				> li {
					width: calc( (100% / 3) - #{$space-large * 1px} );

					&:nth-child(4n) {
						margin-right: rem($space-large);
					}
				}
			}

			.panel {
				margin: 0 0 rem($space-xsmall);
				@include mq($from: screen-sm) {
					margin: 0 0 rem($space-medium);
				}

				@include mq($from: screen-md) {
					margin: 0 rem($space-xlarge) 0 0;
				}
			}
		}

		#{$module}__aside {
			@include mq($from: screen-md) {
				display: inline-block;
				width: calc((100% / 3) - #{$space-large * 1px});
			}

			.panel {
				padding: rem($space-xlarge) rem($space-medium);

				&__header a.button {
					white-space: nowrap;
				}
			}
		}
	}


}

.panel-layout__aside .panel header.panel__header .searchHeader a.button{

	.theme-stuk &
	{
		background-color: $color-maroon-light;
		color: $color-white;
	}				
	.theme-stil &
	{
		background-color: $color-oracle;
		color: $color-white;
	}			
	.theme-uvm &
	{
		background-color: $color-tidal-pool;
		color: $color-white;
	}
	.theme-kanalen &
	{
		background-color: $color-tidal-pool;
		color: $color-white;
	}
}
