// Keyword Card
//
// DOD: done
//
// Element with headline, list of keywords and optional edit icon button
//
// Weight: 1
//
// Markup: ../../templates/prototype/partials/elements/keyword-card.twig
//
// wrapperstyles: background:#EFEFEF;padding:20px;display:block;
//
// Style guide: kanalen.keywordcard


.keyword-card {

	$module: '.keyword-card';

	padding: rem($space-medium) rem($space-xsmall);
	box-sizing: border-box;
	background: $color-white;

	@include mq($from: screen-sm) {
		padding: rem($space-medium);
	}

	@include mq($from: screen-lg) {
		padding: rem($space-medium) rem($space-xlarge) rem($space-xlarge);
	}

	&__inner {
		position: relative;
	}


	h3 {
		@extend %headline-5;
		@include mq($from: screen-sm) {
			margin-bottom: rem($space-xsmall);
			min-height: rem(50);
		}
	}

	ul,
	ul li {
		margin: 0;
		padding: 0;
	}
	ul li {
		font-size: rem(14);
		color: $color-grey-1;
	}
	ul li:not(:last-child) {
		margin-bottom: rem($space-medium);

	}

	.button--icon {
		width: rem(24);
		height: rem(24);
		position: absolute;
		top: 0;
		right: 0;

		.svg-icon {
			fill: $color-text;
			width: rem(24);
			height: rem(24);
		}
	}
}
