// Contact
//
// DOD: done
//
// Contact element, image and text
//
// Markup: ../../templates/prototype/partials/elements/contact.twig
//
// Style guide: elements.contact



.contact {
	margin-bottom: rem($space-xxxlarge);

	// JPL: Codehouse Integration
	&.no-image {
		@include mq($from: screen-xs) {
			padding-left: 0;
		}
	}

	img {
		margin-bottom: rem($space-large);
		width: rem(72);

		// JPL: Codehouse Integration
		max-width: rem(72);
	}
	p {
		@extend %bodytext-lg;
		margin-bottom: 0.2em;
		
		// JPL: Codehouse Integration
		word-break: initial;
	}
	strong {
		color:$color-black;
	}

	@include mq($from: screen-xs) {
		position: relative;
		padding-left: rem(100);
		img {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	@include mq($from: screen-md) {
		padding-left: 0;
		img {
			position: static;
		}
	}

}
