// Banner
//
// DOD: done
//
// The banner is a full width spot container.
//
// .theme-uvm						- Banner in uvm theme
// .theme-stuk						- Banner in stuk theme
// .theme-stil						- Banner in stil theme
//
// Markup: ../../templates/prototype/partials/blocks/banner.twig
//
// hidedefault: true
//
// Style guide: blocks.banner


.banner {
	padding:rem($space-medium);
	@include mq($from: screen-sm) {
		padding:rem(80);
	}

	position: relative;
	//@include theme-background-color('primary-1');
	@include theme-background-color('banner-bg');
	font-weight: 300;

	//color:$color-white;
	@include theme-color('banner-text');
	text-align:left;
	overflow:hidden;
	p {
		color: $color-grey-1;
		margin-top: rem($space-large);
		@extend %bodytext-lg;
	}
	h6 {
		text-transform: uppercase;
		font-size:rem(14);
		margin-bottom: rem($space-large);
		font-weight: 400;
		@include mq($from: screen-sm-plus){
			font-size:rem(16);
		}
	}
	h2 {
		width:80%;
		font-size:rem(32);
		line-height: 1.2em;
		@include theme-color('banner-text');

		@include mq($from: screen-sm) {
			font-size:rem(40);
		}
	}


	.button--medium,
	.button--large {
		margin-top: rem($space-large);
	}
	.button--link {
		margin-top: rem($space-large);
		font-size: 1.25em;

		.text {
			color: $color-white;
			font-weight: 500;
		}
		.svg-icon {
			fill: $color-white;
		}
	}

	p + [class*='.button--'] {
		margin-top: rem($space-xxlarge);
	}
	&:before {
		position: absolute;
		top:50%;
		right:0;
		width:rem(200);
		height:60%;
		transform:translateY(-50%) translateX(40%);
		content:'';
		opacity:0.2;
		background:url(/svg/crown.svg) center center no-repeat;
		background-size:100% 100%;
	}

	&--slim {
		padding:rem($space-xsmall) 0;
		@include mq($from: screen-sm) {
			padding:rem($space-large) 0;
		}
		&:before {
			display: none;
		}
		h2 {
			font-size:rem(20);
			@include mq($from: screen-sm) {
				font-size:rem(32);
			}
		}
	}
	&--centered {
		color: $color-text;
		@include theme-background-color('primary-4');

		text-align: center;
		&:before {
			display: none;
		}
		h2 {
			@extend %headline-3;
			width: auto;
		}

	}
}
