$form-border-radius: 6;

$form-label-font-size: 18;
$form-label-font-size-fat: 20;
$form-input-font-size: 16;
$form-input-font-size-slim: 12;
$form-input-font-size-fat: 20;
$form-radiocheck-width: 20;
$form-select-border-right: 43;
$form-label-line-height: $form-radiocheck-width;

$form-height: 50;
$form-height-slim: 40;
$form-height-fat: 60;
$form-top-padding: 15;
$form-top-padding-slim: 12;
$form-top-padding-fat: 35;
$form-side-padding: 15;
$form-side-padding-slim: 20;
$form-side-padding-fat: 35;
$form-bottom-padding: 15;
$form-bottom-padding-slim: 11;
$form-bottom-padding-fat: 35;
$form-side-padding-mobile: 15;
$form-side-padding-mobile-fat: 15;

$form-label-top-padding: 7;
$form-label-top-padding-fat: 15;


.form {
	//Globals resets
	::-webkit-input-placeholder {
		transition: opacity 0.2s;

		color: $color-form-placeholder;
	}
	:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px white inset;
	}
	:-moz-ui-invalid {
		-webkit-box-shadow: 0 0 0 white !important;
	}
	:-moz-placeholder {
		transition: opacity 0.2s ease-in-out;

		color: $color-form-placeholder;
	}

	::-moz-placeholder {
		transition: opacity 0.2s;

		color: $color-form-placeholder;
	}

	:-ms-input-placeholder {
		transition: opacity 0.2s ease-in-out;

		color: $color-form-placeholder;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type="password"] {
		font: small-caption;
		font-size: rem($form-input-font-size);
	}
}
/* ================================================================================= */

//Extends
%form-item {
	transition: all 0.2s ease-in-out;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	background-color: $color-form-bg;
	width: 100%;
	box-sizing: border-box;
	font-family: $font-stack-primary;

	font-size: rem($form-input-font-size);
	font-weight: 400;

	margin: 0;
	border: rem(1) solid $color-form-border;
	border-radius: 0;
	color: $color-black;
	position: relative;
}

/* ================================================================================= */

.form__field {
	position: relative;
	display: block;
	vertical-align: top;
	margin: 0 0 rem($space-small);

	@include clearfix;

	@include mq($from: screen-sm) {
		width: 100%;
		@include clearfix;
	}

	&.is-disabled {
		cursor: default;
		.form__label {
			cursor: default;
		}
		.form__item {
			color: $color-form-disabled;
			background: $color-grey-6;
		}
	}

	&.is-required {
		.form__label__text {
			strong {
				display: inline-block;
			}
		}
	}

	&.is-valid {
		.form__valid {
			display: block;
		}
		.form__valid + .form__item {
			padding-right: rem(40);
		}
	}

	&.is-singlechar {
		margin-right: rem($space-xxsmall);
		&:last-of-type {
			margin-right: 0;
		}
	}

	&.is-filled {
		.form__item {
			border-color: $color-form-border;

			&:focus,
			&:active {
				&:not(:disabled) {
					outline: 0;
					box-shadow: 0 0 0 black;
					border-color: $color-form-border-active;
				}
			}
		}
	}

	&.is-invalid {
		.form__item {
			border-color: $color-form-invalid;
		}
	}

	&.is-invalid.is-filled {
		.form__item {
			border-color: $color-form-invalid;
		}
	}

	&--short {
		width: 150px;
	}

	&--outline,
	&--outline.is-filled {
		.form__item {
			background-color: transparent;
			border-color: $color-form-border-active;

			&:focus,
			&:active {
				&:not(:disabled) {
					@include theme-border-color("primary-1");
				}
			}
		}
	}
	&--slim {
		.form__item {
			font-size: rem($form-input-font-size-slim);
		}
	}
}

/* ================================================================================= */

.form__label {
	font-size: rem($form-label-font-size);
	line-height: rem($form-label-line-height);
	margin-bottom: 0.5em;

	display: block;
	color: $color-form-label;
	text-align: left;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@include mq($until: screen-xs) {
		font-size: rem(16);
	}

	+ .form__valid {
		top: 2.1em;
	}

	&__text {
		position: relative;
		// border-radius: rem(6);
		left: rem(1);
		display: inline-block;
		transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;

		strong {
			line-height: em;
			padding-left: 0.5em;
			position: relative;
			width: 1em;
			height: 0.5em;
			content: "";
			display: none;
			background-image: url(/svg/mandatory.svg);
			background-repeat: no-repeat;
			background-position: center right;
			background-size: 100% 100%;
		}

		small {
			margin-left: rem($space-xsmall);
			font-size: 80%;
			color: $color-grey-1;
		}

		.is-disabled & strong {
			opacity: 0.4;
		}

		h5 {
			@extend %bodytext-lg;
			text-transform: none;

			line-height: 1em;
			margin-bottom: 0.5em;
		}
		p {
			@extend %bodytext-md;
			text-transform: none;
			@include hyphenation;

			> a {
				@include theme-color("primary-1");
				text-decoration: underline;
				@include hyphenation;
			}
		}
		h6 {
			font-size: rem(12);
			font-weight: 600;
			color: $color-grey-1;
			text-transform: uppercase;
		}
	}
}

/* ================================================================================= */

.form__valid {
	display: none;
	position: absolute;
	top: 0.3em;
	right: rem($space-small);
	content: "";
	width: 1em;
	height: 2.5em;
	z-index: 40;
	background-image: url(/svg/checkmark.svg);
	background-repeat: no-repeat;
	background-position: center right;
	background-size: 100% 100%;
	pointer-events: none;
}

/* ================================================================================= */

.form__notice {
	display: block;
	clear: both;
	font-size: rem(14);
	font-weight: 300;
	color: $color-text-discreet;
	margin: rem($space-xxsmall) 0 rem($space-small);

	.is-invalid & {
		color: $color-error-text;
	}

	@include mq($from: screen-sm) {
		font-size: rem(16);
	}
}

/* ================================================================================= */

// Input Text
//
// Text inputs can both be used on their own or as a series of single-character fields.
//
// DOD: done
//
// Markup: ../../templates/prototype/partials/elements/form-text.twig
//
// Weight: 1
//
// Wrappercss: form
//
// Style guide: elements.form.text

.form__field--text {
	> .form__item {
		transition: opacity 0.4s ease-in-out;
		line-height: rem($form-input-font-size);
		padding: rem($form-top-padding) rem($form-side-padding)
			rem($form-bottom-padding);

		@extend %form-item;
		&::-ms-clear,
		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}

		&:focus {
			outline: 0;
			box-shadow: 0 0 0 black;
		}
	}
	&.is-invalid {
		> .form__item {
			border-color: $color-form-invalid;
		}
	}
	&.is-singlechar {
		width: 3em;
		display: inline-block;

		.form__item {
			line-height: rem($form-height);
			text-align: center;
			padding: 0;
			&[type="password"] {
				font-size: rem(24) !important;
				font-family: Verdana;
			}
		}
	}
	&.form__field--slim .form__item {
		padding: rem($form-top-padding-slim) rem($form-side-padding-slim)
			rem($form-bottom-padding-slim);
	}
}

// Textarea
//
// Textarea description
//
// DOD: done
//
// Markup: ../../templates/prototype/partials/elements/form-textarea.twig
//
// Weight: 2
//
// Wrappercss: form
//
// Style guide: elements.form.textarea

.form__field--textarea {
	.form__item {
		box-sizing: border-box;
		overflow: hidden;
		min-height: rem(100);
		@extend %form-item;
		display: block;
		width: 100%;
		resize: none;
		padding: rem($form-top-padding) rem($form-side-padding)
			rem($form-bottom-padding);

		&:focus,
		&:active {
			outline: 0;
			box-shadow: 0 0 0 black;
		}
	}

	&.is-invalid {
		> .form__item {
			border-color: $color-form-invalid;
		}
	}

	&.form__field--slim .form__item {
		min-height: rem(40);
		padding: rem($form-top-padding-slim) rem($form-side-padding-slim)
			rem($form-bottom-padding-slim);
	}
}

// Select
//
// Select description
//
// DOD: done
//
// Markup: ../../templates/prototype/partials/elements/form-select.twig
//
// Weight: 3
//
// Wrappercss: form
//
// Style guide: elements.form.select
.form__field--date,
.form__field--select {
	.form__container {
		position: relative;
		overflow: hidden; //to make arrow sit in the middle
	}
	&.has-focus {
		.form__container {
			&:after {
				border-left-color: $color-border;
			}
		}
	}
	.form__item {
		@extend %form-item;
		padding-left: rem($form-side-padding);
		padding-right: rem($form-side-padding);
		text-indent: 0.01px;
		transition: opacity 0.4s ease-in-out;
		float: right;
		height: rem($form-height); //maybe risky - should only be in form.scss
		line-height: 1; //maybe risky - should only be in form.scss

		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 $color-black;
		}
		&::-ms-expand {
			display: none;
		}
		&:focus {
			outline: 0 !important;
			box-shadow: 0 0 0 black;
			//REQUIRES THEMING
			//border-color: $color-form-border-active;
		}

		background-image: url(/svg/down-chevron.svg);
		background-repeat: no-repeat;
		background-position: center right 10px;
	}

	.form__valid {
		right: 2.5em;
	}
	&.form__field.is-valid {
		.form__valid + .form__item {
			padding-right: rem(
				$form-select-border-right * 1.8,
				$form-label-font-size
			);
		}
	}

	&.is-invalid {
		.form__item {
			border-color: $color-form-invalid;
		}
		.form__container {
			&:after {
				border-color: $color-form-invalid;
			}
		}
	}

	&.form__field--slim .form__item {
		padding-left: rem($form-side-padding-slim);
		padding-right: rem($form-side-padding-slim);
		height: rem(
			$form-height-slim
		); //maybe risky - should only be in form.scss
	}

	&--internal-label {
		position: relative;

		.form__item {
			font-size: rem(14);
			font-weight: 600;
			border-color: transparent;
		}

		.form__label {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: rem(20);
			font-size: rem(14);
			line-height: rem(20);
			z-index: 2;
		}
	}

	&--slim {
		.form__label {
			font-size: rem(14);
		}
		.form__item {
			padding: rem($space-xxsmall) rem($space-xxlarge) rem($space-xxsmall)
				rem($space-xsmall);
			height: rem($space-xxlarge) !important;
			line-height: initial;
		}
	}
}

// Radio
//
// Radio-buttons
//
// DOD: done
//
// Markup: ../../templates/prototype/partials/elements/form-radio.twig
//
// Weight: 4
//
// Wrappercss: form
//
// Style guide: elements.form.radio

.form__field--radio {
	margin-bottom: rem($space-medium);

	.form__item {
		vertical-align: top;
		margin-top: rem(9);
		position: absolute;
		opacity: 0;
		margin-left: rem(-$space-medium);

		@include mq($until: screen-md) {
			float: left;
			margin-top: rem(5);
		}
	}

	.form__label {
		position: relative;
		cursor: pointer;
	}
	.form__notice {
		padding-left: rem($form-input-font-size + 20);
		@include mq($until: screen-xs) {
			padding-left: rem($form-input-font-size + 15);
		}
	}

	.form__label .form__label__text {
		font-weight: 300;
		color: $color-text;
		padding-left: rem($form-input-font-size + 20);

		@include mq($until: screen-xs) {
			padding-left: rem($form-input-font-size + 15);
			padding-right: 0;
		}

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			content: "";
			width: rem($form-radiocheck-width);
			height: rem($form-radiocheck-width);
			border: rem(1) solid $color-grey-1;
			border-radius: 50% 50%;
			background: $color-white;
			box-sizing: border-box;
			transition: background 0.3s ease-out;
		}

		&:after {
			position: absolute;
			top: 0;
			left: rem(1);
			display: inline-block;
			content: "";
			opacity: 0;
			width: rem($form-radiocheck-width - 2);
			height: rem($form-radiocheck-width - 2);
			border-radius: 50% 50%;
			background-image: url(/svg/radiobutton.svg);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 70% 70%;
			transition: opacity 0.3s ease-out;
		}
	}
	.form__item:checked + .form__label {
		&__text {
			&:before {
				background: $color-grey-1;
			}
			&:after {
				opacity: 1;
			}
		}
	}
	.form__item.focus-visible + .form__label__text {
		@extend %keyboard-focus;
	}

	&.is-disabled {
		.form__label .form__label__text {
			color: $color-grey-7;

			&:before {
				border: rem(1) solid $color-grey-4;
				background: $color-grey-4;
			}
		}
	}
}

// Checkbox
//
// Checkboxes
//
// DOD: done
//
// Markup: ../../templates/prototype/partials/elements/form-checkbox.twig
//
// Weight: 5
//
// Wrappercss: form
//
// Style guide: elements.form.checkbox

.form__field--checkbox {
	margin-bottom: rem($space-medium);

	.form__item {
		position: absolute;
		opacity: 0;
		vertical-align: top;
		margin-top: rem(10);
		margin-left: rem(-$space-medium);

		@include mq($until: screen-md) {
			float: right;
			margin-top: rem(5);
		}
	}
	.form__label {
		position: relative;
		cursor: pointer;
	}
	.form__notice {
		padding-left: rem($form-input-font-size + 20);
		@include mq($until: screen-xs) {
			padding-left: rem($form-input-font-size + 15);
		}
	}

	.form__label .form__label__text {
		color: $color-text;
		padding-left: rem($form-input-font-size + 20);

		@include mq($until: screen-xs) {
			padding-left: rem($form-input-font-size + 15);
			padding-right: 0;
		}

		p {
			margin-top: rem(-5); //to align with checkbox...
		}

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			content: "";
			width: rem($form-radiocheck-width);
			height: rem($form-radiocheck-width);
			border: rem(1) solid $color-grey-1;
			background: $color-white;
			box-sizing: border-box;
			transition: background 0.2s ease-out;
		}

		&:after {
			position: absolute;
			top: 2px;
			left: rem(1.2);
			display: inline-block;
			content: "";
			opacity: 0;
			width: rem($form-radiocheck-width - 2);
			height: rem($form-radiocheck-width - 2);
			background-image: url(/svg/checkbox.svg);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 80% 80%;
			transition: opacity 0.2s ease-out;
		}
	}

	.form__item:checked + .form__label__text {
		&:before {
			background: $color-grey-1;
		}
		&:after {
			opacity: 1;
		}
	}
	.form__item.focus-visible + .form__label__text {
		@extend %keyboard-focus;
	}

	&.is-disabled {
		.form__label .form__label__text {
			color: $color-grey-3;

			&:before {
				border: rem(1) solid $color-grey-4;
				background: $color-grey-4;
			}
		}
	}

	&--fat {
		.form__label .form__label__text:before {
			border: rem(2) solid $color-grey-4;
			width: rem($form-radiocheck-width + 2);
			height: rem($form-radiocheck-width + 2);
		}
		.form__label .form__label__text:after {
			background-image: url(/svg/checkmark-white.svg);
			top: rem(2);
			left: rem(2);
		}

		.form__item:checked + .form__label {
			&__text {
				&:before {
					border-color: $color-grey-1;
				}
			}
		}
	}
}

.form__field--file {
	.form__item {
		width: rem(0.1);
		height: rem(0.1);
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
	.form__label {
		display: inline-block;
		border-radius: 0;
		font-size: rem(13);
		transition: all 0.2s ease-out;
		text-align: center;
		cursor: pointer;

		&__text {
			padding: 0;
			@include theme-color("primary-1");
		}
	}

	.file-names {
		font-size: rem(11);
		@include theme-color("primary-1");
	}
}


/* ================================================================================= */

// Date Picker (flatpickr)
//
// All documentation can be found at <a target="_blank" href="https://flatpickr.js.org/options/">faltfickr docs</a>. <br>
// Datepickr options can be added with data-attributes on the root element. ex. ```data-min-date="Y-m-d"```
//
// Markup: ../../templates/prototype/partials/elements/form-date-picker.twig
//
// Weight: 1
//
// Wrappercss: form
//
// DOD: done
//
// Style guide: elements.form.flatpickr


.form__field--date {
	.form__item {
		@extend %form-item;
		padding-left: rem($form-side-padding);
		padding-right: rem($form-side-padding);
		// min-height: rem(46);
	}
	.form__container {
		position: relative;
		overflow: hidden;
	}
	&.form__field.is-valid {
		.form__valid + .form__item {
			padding-right: rem(
				$form-select-border-right * 1.8,
				$form-label-font-size
			);
		}
	}

	&.is-invalid {
		.form__item {
			border-color: $color-form-invalid;
		}
	}
	.form__valid {
		right: 2.5em;
	}
}

/* ================================================================================= */

// Multiple Select
//
// Multiple Select can be used for selecting more options at once.
//
// DOD: done
//
// Markup: ../../templates/prototype/partials/elements/form-select-multiple.twig
//
// Weight: 1
//
// Wrappercss: form
//
// Style guide: elements.form.multipleselect



.form__field--select-multiple {
	align-self: flex-end;
	width: 100%;

	.form__container {
		overflow: visible;
	}
	.form__item {
		float: inherit;
		text-align: left;
		text-overflow: ellipsis;
		//overflow: hidden;
		white-space: nowrap;
		min-width: rem(200);
	}

	.dropdown {
		list-style: none;
		position: relative;
		//border: rem(1) solid $color-form-border;

		position: relative;
		// overflow: hidden;

		cursor: pointer;

		&__bundle-select-btns {
			display: flex;
			align-items: center;

			span {
				color: "grey";
			}
		}
		&__bundle-btn {
			font-size: rem(14);
			padding: rem(10) rem(15);
			color: $color-blue-lagoon;
			text-decoration: underline;
			color: #00647d;
			font-family: var(--font-primary);

			&[disabled] {
				color: #a5a19e;
				pointer-events: none;
			}
		}

		&__label {
			font-size: rem(14);
			line-height: rem($form-label-line-height);
			margin-bottom: 0.5em;

			display: block;
			color: $color-form-label;
			text-align: left;

			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}

		&__arrow {
			pointer-events: none;
			position: absolute;
			fill: $color-form-text;
			right: rem(10);
			transition: transform 0.2s linear;
			align-self: center;
			&.expanded {
				transform: rotate(-180deg);
			}
		}

		&__list-container {
			display: flex;
			flex-direction: column;

			transition: opacity 0.1s cubic-bezier(0, 0, 0.38, 0.9),
				max-height 0.3s cubic-bezier(0, 0, 0.38, 0.9);

			position: absolute;
			background-color: $color-white;
			width: auto;
			z-index: 1;
			padding: rem(5) 0 rem(10) 0;
			left: 0;
			top: 0.5px;
			box-shadow: 0 rem(3) rem(5) 0 #a2a4b2;

			&[aria-expanded="false"] {
				height: 0;
				opacity: 0;
			}
			&[aria-expanded="true"] {
				height: auto;
				opacity: 1;
			}
			&.is-hidden {
				display: none;
			}
		}

		ul {
			list-style: none;
			margin: 0;
		}
		&__list {
			display: block !important;
		}

		&__list-item {
			> label {
				font-size: 1rem;
				color: $color-grey-1;
				padding: rem(10) rem(15);
				margin-right: 0 !important;
				transition: background-color 0.1s linear, color 0.1s linear;
				color: $color-form-text;
				list-style-position: inside;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				outline: none;
				display: block;
				flex: auto !important;

				&:hover,
				&:focus {
					background-color: $color-mabel;
					outline: 0;
					border: none;
				}
				&[tabindex]:focus {
					border: none;
					outline: 0;
				}

				.checkbox {
					pointer-events: none;
					padding: 0.5rem;
					border-right: 1;
					-webkit-appearance: auto;
					margin-right: 0.5rem;
				}
			}
		}
	}

	#dropdown__selected {
		color: $color-form-text;
		display: flex;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
		outline: 0;
		height: 1.75rem;
		padding: rem($space-xxsmall) rem($space-xxlarge) rem($space-xxsmall)
			rem($space-xsmall);
		line-height: 1.5;

		.new-value {
			display: flex;
		}
	}

	.dropdown__list-item--checkbox {
		.form__item {
			position: absolute;
			opacity: 0;
			vertical-align: top;
			margin-top: rem(10);
			margin-left: rem(-$space-medium);

			@include mq($until: screen-md) {
				float: right;
				margin-top: rem(5);
			}
		}

		.form__label__text {
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				display: inline-block;
				content: "";
				width: rem($form-radiocheck-width);
				height: rem($form-radiocheck-width);
				border: rem(1) solid $color-grey-1;
				background: $color-white;
				box-sizing: border-box;
				transition: background 0.2s ease-out;
			}

			&:after {
				position: absolute;
				top: 2px;
				left: rem(1.4);
				display: inline-block;
				content: "";
				opacity: 0;
				width: rem($form-radiocheck-width - 2);
				height: rem($form-radiocheck-width - 2);
				background-image: url(/svg/checkbox.svg);
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 80% 80%;
				transition: opacity 0.2s ease-out;
			}

			span {
				margin-left: rem(30);
			}
		}

		.form__item:checked + .form__label__text {
			&:before {
				background: $color-grey-1;
			}
			&:after {
				opacity: 1;
			}
		}
	}
}

