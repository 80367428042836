.keyword-suggest {
	position: absolute;
	top: 90%;

	right: rem($space-xsmall);
	left: rem($space-xsmall);

	@include mq($from: screen-sm) {
		right: auto;
		top: 90%;
		left: 0;
		min-width: rem(100);
		max-width: rem(280);
	}

	background: $color-white;
	box-shadow: 0 rem(5) rem(20) rem(5) rgba(0, 0, 0, 0.1);
	font-size: rem(10);

	transition: all 0.1s ease-out;
	transform-origin: top center;
	transform: scaleY(0) translateY(#{rem(5)});
	opacity: 0;

	display: none;

	z-index: 2;

	&:before {
		content: '';
		width: 0;
		height: 0;
		border-bottom: rem(10) solid $color-white;
		border-left: rem(10) solid transparent;
		border-right: rem(10) solid transparent;
		transform: translateX(-50%);
		position: absolute;
		top: rem(-10);
		left: rem(20);
	}

	&.is-active {
		display: block;
		transform: scaleY(1) translateY(#{rem(5)});
		opacity: 1;
	}


	> * {
		padding: rem($space-xsmall) rem($space-medium);
	}

	&__row {
		font-size: rem(11);
		font-weight: 700;
		display: block;
		text-align: left;
		transition: background 0.3s;
		color: $color-grey-1;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&.button:hover {
			@include theme-background-color('primary-5');
		}
	}
	.keyword-suggest__row:not(.keyword-suggest__row--header) + .keyword-suggest__row:not(.keyword-suggest__row--header) {
		border-top: rem(1) solid $color-border-discreet;
	}
}
