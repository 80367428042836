.split-list {
	position: relative;
	text-align:center;
	&--border {
		&:before {
			content: '';
			position: absolute;
			top: rem($space-xlarge);
			left: 50%;
			bottom: 0;
			width: rem(2);
			display: none;
			background: $color-grey-5;
			transform:translateX(-50%);
		}
		@include mq($from: screen-sm) {
			&:before {
				display: block;
			}
		}
		@include mq($from: screen-md) {
			&:before {
				left:49%;
			}
		}
	}
	.grid-item {
		@include mq($from: screen-sm) {
			&--padding-left {
				padding-left:rem($space-large);
			}
			&--padding-right {
				padding-right:rem($space-large);
			}
			&--padding-left-fat {
				padding-left:rem($space-xlarge);
			}
			&--padding-right-fat {
				padding-right:rem($space-xlarge);
			}
			&--padding-top {
				padding-top:rem($space-large);
			}
			&--margin-left {
				margin-left:rem($space-large);
			}
			&--margin-right {
				margin-right:rem($space-large);
			}
		}
	}
}
