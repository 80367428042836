// Floats & alignment
//
// DOD: done
//
// Helper classes that gives different utility functions. All clases are prefix with the first letter or state of the function. ex. a--left stands for align.
// > `a` Align
// >
// > `f` Float
//
// Markup: <div class="c--uvm-primary-5 {{modifier_class}}" style="padding:5px;max-width:300px;">Lorem ipsum</div>
//
// .a--left    		- align text left
// .a--center    	- align text left
// .a--right    	- align text right
// .f--right		- float right
// .f--left			- float left
// .clearfix		- fixing clear problems on floats
//
// Style guide: helpers.alignment

// Helper classes
//
// DOD: done
//
// Helper classes that gives different utility functions. All clases are prefix with the first letter or state of the function. ex. `.a--left` stands for align left.
// > `v` Visibility
// >
// > `t` Text styles
// >
// > `b` Border
// >
// > `m` Margin
//
// Markup: <div class="c--uvm-primary-5 {{modifier_class}}" style="padding:5px;max-width:300px;">Lorem ipsum</div>
//
// .t--italic			- italic text
// .t--bold				- bold text
// .t--small			- small text
// .v--mobile-only		- only visible on mobile
// .v--desktop-only		- only visible on desktop
// .v--sr-only			- only visible for screenreaders
// .b--bottom 			- border bottom
// .b--top 				- border top
// .m--auto				- auto margins left and right
//
// Style guide: helpers.misc

.clearfix {
	@include clearfix();
}
// width limits for pages
.w--normal {
	position: relative;
	margin: 0 em($space-xsmall);
	@include mq($from: screen-sm) {
		padding: 0 rem($space-large);
		max-width: em($max-page-width);
		margin: 0 auto;
	}
	@include mq($from: screen-xl) {
		padding: 0;
	}
}

.w--narrow {
	position: relative;
	margin: 0 em($space-xsmall);
	@include mq($from: screen-sm) {
		padding: 0 rem($space-large);
		max-width: em($max-page-width-narrow);
		margin: 0 auto;
	}
	@include mq($from: screen-md) {
		padding: 0;
	}
}
.w--wide {
	position: relative;
	margin: 0 em($space-xsmall);
	@include mq($from: screen-sm) {
		padding: 0 rem($space-large);
		max-width: em($max-page-width);
		margin: 0 auto;
	}
	@include mq($from: screen-xl) {
		padding: 0;
		max-width: em($max-page-width-wide);
	}
}
.w--widest {
	position: relative;
	margin: 0 em($space-xsmall);
	@include mq($from: screen-sm) {
		padding: 0 rem($space-large);
		max-width: em($max-page-width);
		margin: 0 auto;
	}
	@include mq($from: screen-lg) {
		max-width: em($max-page-width-wide);
	}
	@include mq($from: screen-xxxl) {
		max-width: em($max-page-width-widest);
	}
}
.w--form {
	width: em($max-form-width);
}

.m--auto {
	margin: 0 auto;
}
//Visibility
.v--mobile-only {
	@include mq($from: screen-sm) {
		display: none !important;
	}
}
.v--desktop-only {
	@include mq($until: screen-sm) {
		display: none !important;
	}
}
@mixin screen-reader-only(){
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}
.v--sr-only { //used to hide labels that should still be available for screenreaders
	@include screen-reader-only();
}

.is-hidden {
	display: none;
}

.is-calculation-height {
	visibility: hidden;
	position: absolute;
	width: 0;
}

.gutter--small {
	margin-bottom: em($space-small);
}

.gutter--medium {
	margin-bottom: em($space-medium);
}

.gutter--large {
	margin-bottom: em($space-large);
}

// text colors
.tc--light {
	color: $color-white;
}


// text-alignment
.a--center {
	text-align: center !important;
}

.a--left {
	text-align: left !important;
}

.a--right {
	text-align: right !important;
}
.va--middle {
	@include vertical-align();
}
.f--right {
	float: right;
}
.f--left {
	float: left;
}
// borders
.b--top {
	border-top: rem(1) solid $color-border;
}
.b--bottom {
	border-bottom: rem(1) solid $color-border;

	&--large {
		border-width: 5px;
	}

	&--theme {
		@include themify('border-color', 'primary-3')
	}
}


%t--small {
	font-size: rem($article-font-size-small);
}
.t--small {
	@extend %t--small;
}
.t--strike {
	text-decoration: line-through;
}

.t--bold {
	font-weight: bold;
}

.t--italic {
	font-style: italic;
}

.t--faded {
	opacity: 0.5;
}

.object-fit {
	@include objectfit;
	width: 100%;
	height: 100%;
}
