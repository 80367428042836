.criterion-list {
	padding-bottom: rem($space-small);

	@include clearfix;

	ul li a {
		display: flex;
		justify-content: space-between;
		padding: rem($space-xsmall) 0;

		.text {
			font-size: rem(13);
		}
		.svg-icon {
			opacity: 0;
			width: rem(15);
			height: rem(15);
			@include theme-fill('primary-1');
			transition: opacity 0.2s ease-out;
		}

		&.is-active {
			.svg-icon {
				opacity: 1;
			}
		}
	}

	.button {
		padding: rem($space-xsmall) 0;
		float: right;
		@include theme-color('primary-1');
		font-size: rem(13);
		font-weight: 400;
		line-height: 1em;
	}
}
