.food-menu {

	ul {

		@include mq($from: screen-sm) {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
	}

	&__item {
		position: relative;

		@include mq($from: screen-sm) {
			flex: (1 / 2) * 100% 0 0;
		}
		@include mq($from: screen-md) {
			flex: (1 / 3) * 100% 0 0;
		}

		margin-bottom: rem($space-medium);
		color: $color-grey-1;

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 100%;
			left: 0;
			height: rem(1);
			width: rem(50);
			background: $color-grey-5;
		}

		&__inner {
			padding: 0 rem($space-medium) rem($space-medium) 0;

		}


		ul {
			margin-top: rem($space-large);
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			@include mq($from: screen-md) {
				flex-wrap: nowrap;
			}

			li {

				margin: 0 rem($space-xsmall) rem($space-xsmall) 0;
				flex: 1 1 calc(100% / 3 - #{rem(($space-xsmall * 2) / 3)});
				max-width: calc(100% / 3 - #{rem(($space-xsmall * 2) / 3)});

				@include mq($from: screen-md) {
					margin: 0 rem($space-medium) rem($space-medium) 0;
					flex: 1 1 calc(100% / 6 - #{rem(($space-medium * 5) / 6)});
					max-width: calc(100% / 6 - #{rem(($space-medium * 5) / 6)});
				}

				img {
					width: 100%;
					max-width: 100%;
					height: auto;
				}
				> span {
					display: block;
					width: 100%;
					height: 100%;
				}


				&:nth-child(3n) {
					margin-right: 0;
				}
				@include mq($from: screen-md) {
					&:nth-child(3n) {
						margin-right: rem($space-medium);
					}
					&:nth-child(6n) {
						margin-right: 0;
					}
				}
			}
		}

		h3 {
			@extend %headline-5;
			margin-bottom: rem($space-xxsmall);
			color: $color-black;
		}

		h5 {
			@extend %trumpet;
			letter-spacing: 0;
			font-size: rem(12);
			margin-bottom: rem($space-xxsmall);
			font-weight: 600;

			& + h5 {
				margin-top: rem($space-medium);
			}
		}

		p {
			font-size: rem(14);
			font-weight: 500;

			& + h5 {
				margin-top: rem($space-medium);
			}
		}
		p:last-child {
			margin-bottom: 0;
		}
	}
}
