// Sitecore inputs
//
// The sitecore form output is decidet by Sitecore and the following is therefore only styling-examples.
//
// Markup: ../templates/prototype/pages_examples/sitecore-forms.twig
//
// Style guide: elements.sitecoreforms

form[data-sc-fxb] {
    //Globals resets
	::-webkit-input-placeholder {
		transition: opacity .2s;

		color: $color-form-placeholder;
	}
	:-webkit-autofill {
	    -webkit-box-shadow: 0 0 0px 1000px white inset;
	}
	:-moz-ui-invalid {
	    -webkit-box-shadow: 0 0 0 white !important;
	}
	:-moz-placeholder {
		transition: opacity .2s ease-in-out;

		color: $color-form-placeholder;
	}

	::-moz-placeholder {
		transition: opacity .2s;

		color: $color-form-placeholder;
	}

	:-ms-input-placeholder {
		transition: opacity .2s ease-in-out;

		color: $color-form-placeholder;
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	////////////////////

	input, textarea, select {
		background-color: $color-form-bg;
		box-sizing: border-box;
		font-family: $font-stack-primary;
		font-size:rem($form-input-font-size);
		font-weight:400;
		margin: 0;
		border: rem(1) solid $color-form-border;
		border-radius: 0;
		color: $color-black;
	}

	textarea,
	select,
	input[type=email],
	input[type=number],
	input[type=date],
	input[type=tel],
	input[type=password],
	input[type=text] {
		padding: rem(15);
		width: 100%;
	}

	input[type=radio],
	input[type=checkbox] {
		width: 18px;
		height: 18px;
		margin-right: rem(15);
	}

	input[type=submit] {
		background-color: $color-blue-lagoon;
		cursor: pointer;
		padding: rem(20) rem(50);
		min-width: 220px;
		text-align: center;
		margin: rem(15) 0;
		appearance: none;
		border: none;

    	color: #fff;

		line-height: 1.75em;
		vertical-align: middle;
		transition: background-color 0.2s ease-out;

		font-size: .75rem;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		font-weight: 700;

		&:hover {
			background-color: $color-pelorous;
		}
	}

	textarea {
		min-height: 100px;
		resize: none;
	}

	select:not([size]) {
		appearance: none;
		height: 48px;
		padding: 0 rem(15);
		background-image: url('/svg/down-chevron.svg');
		background-repeat: no-repeat;
		background-position: center right 10px;
	}

	label {
		font-size: rem($form-label-font-size);
		line-height: rem($form-label-line-height);
		margin: rem(30) 0 0.5em 0;

		display: block;
		color: $color-form-label;
		text-align: left;

		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;


		@include mq($until:screen-xs){
			font-size: rem(16);
		}
	}

	.field-validation-error {
		display: block;
		clear: both;
		font-size: rem(14);
		font-weight: 300;
		color: $color-text-discreet;
		margin: rem($space-xxsmall) 0 rem($space-small);
		color: $color-error-text;

		@include mq($from: screen-sm) {
			font-size: rem(16);
		}
	}
}
