// --- google translate widget fix - start
// The google translate widget aggresively sets styles on html and body, which breaks page layout
// html {
//   height: auto !important;
// }
// body {
//   position: initial !important;
//   min-height: initial !important;
//   top: auto !important;
// }
// --- google translate widget fix - end

html {
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

:target:before {
  content: "";
  display: block;
  height: 50px;
  margin: -50px 0 0;
}


body {
	font: normal 1em/1.3 $font-stack-primary;
	margin: 0;
	@include theme-background-color('site-bg');
	-webkit-overflow-scrolling: auto;
	overflow: hidden;
	overflow-y: visible;

	&.is-fixed  {
		max-height: 100%;
		width: 100%;
		height: 100%;
		position: fixed;
	}
	&.is-blurred {
		-webkit-filter: blur(3px);
		-moz-filter: blur(3px);
		filter: blur(3px);
  	}
}

.dev {
	display:block;
	color:black;
	font-weight:700;
	background: darken(white,5%);
	border:rem(2) solid black;
	position: relative;
	font-size:rem(12);
	padding:rem($space-xxsmall) rem($space-xxsmall) rem($space-xxsmall) rem(95);
	margin:rem($space-large) 0 rem($space-xxlarge);

	&:before {
		content:'DEV MESSAGE: ';
		position: absolute;
		top:rem($space-xxsmall);
		left:rem($space-xxsmall);
	}
}

a:not(.button) {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	color:$color-grey-1;
	font-weight:400;
	text-decoration: none;
	position: relative;
	transition:color 0.2s ease-out;
	word-break: break-word;

	> strong {
		font-weight: bold;
	}
	.svg-icon {
		width: 0.8em;
		height: 0.8em;
		color: $color-grey-1;
	}
	&:hover {
		@include theme-color('primary-2');
		outline: none;

		.svg-icon {
			@include theme-fill('primary-2');
		}
	}
	&:active {
		outline: 0;
	}
}

a[href],
input[type='submit'],
input[type='image'],
label[for],
select,
button,
.pointer {
	cursor: pointer;
}

input, textarea, select, button {
	font-size: inherit;
	box-sizing: content-box;
	border: 0;
	background: transparent;
}

img {
    max-width: 100%;
    font-style: italic;
    vertical-align: middle;
}

svg {
	max-width: 100%;
	overflow:hidden; //IE11 fix, if overflow visible, MAJOR whitespace at bottom of page
}

.t--notice {
	color: $color-form-invalid;
	font-weight: bold;
	font-size: 110%;
}
.t--highlight {
	@include theme-color('primary-2');
	font-weight: bold;
	font-size: 120%;
}

.theme-highlight {
	//@include theme-background-color('primary-4');
	@include theme-background-color('search-highlight');
}
mark {
	color: inherit;
}
hr {
	border-width: 0;
	border-top: rem(1) solid $color-border;
	margin: rem($space-xxlarge) 0;
}

input {
	&:focus {
		outline: none;
	}
}
template { // needs to be hidden in IE
	display: none;
}
#__bs_notify__ { // TODO
	display: none !important;
}
xmp {
	background-color: #fff;
	padding: 5px;
	white-space: pre-wrap;
}
code {
	// @include theme-background-color('site-bg');
	padding: 12px;
	font-size: em(14);
	color: $color-black;
	font-style: italic;
	display: block
}



blockquote {
	display: inline-block;

	position: relative;

	padding-left: rem($space-large);
	@include mq($from: screen-sm){
		padding-left: rem($space-xlarge);
	}

	font-family: $font-stack-tertiary;

	.swiper & {
		display: flex;
		justify-content: center;
		align-items: center;

		min-height: rem(300);
		@include mq($from: screen-sm) {
			min-height: rem(400);
		}
		&:before {
			display: none;
		}
	}


	&:before {
		content: '';
		display: block;
		position: absolute;
		top: rem(5);
		left: rem(5);
		width: rem(36);
		height: rem(36);
		background: url(/svg/quote-mark.svg);
		background-repeat: no-repeat;
	}

	> p,
	div > p {
		font-family: inherit !important;
		color: $color-black;
		font-weight: 300;
		margin: 0 !important;
		font-style: italic;
		font-size: rem(22);
		line-height: rem(30);

	}

	span {
		display: block;
		margin-top: rem($space-medium);
		font-family: $font-stack-primary;
		font-size: rem(16);
	}
}




%responsive-label {
	&:before {
		position: absolute;
		top: 0;
		left: 0.5em;
		display: inline-block;
		content: attr(data-label);
		font-size: rem(16);
		text-transform: uppercase;
		font-weight: 500;
		color: $color-text;

		@include mq($from: screen-xs) {
		}
		@include mq($from: screen-sm) {
			display: none;
		}
	}

	padding: 0 rem(5);

	@include mq($from: screen-sm) {
		padding: rem(20) rem(10);
	}
}
%general-link {
	border-bottom-width: rem(2);
	border-style: solid;
	transition: all 0.1s ease-out;

	//@include theme-color('primary-1');
	//@include theme-border-color('primary-1');
	@include theme-color('general-link');
	@include theme-border-color('general-link');

	&.external {
		&:after {
			display: inline-block;
			vertical-align: baseline;
			padding-left: 0.5em;
			height: 0.8em;
			width: 0.8em;
			content: '';
			background-image: url(/svg/external-link-blue.svg);
			background-repeat: no-repeat;
			background-position: center right;
			background-size: 100% 100%;
		}
	}

	.svg-icon {
		@include theme-fill('primary-1');
	}

	&:hover {
		//@include theme-color('primary-3');
		//@include theme-border-color('primary-3');
		@include theme-color('general-link-hover');
		@include theme-border-color('general-link-hover');

		.text {
			//@include theme-color('primary-3');
			@include theme-color('general-link-hover');
		}
		.svg-icon {
			//@include theme-fill('primary-3');
			@include theme-fill('general-link-hover');
		}
		&:after {
			opacity: 0.6;
		}
	}
}
.link {
	@extend %general-link;
}


%keyboard-focus {
	outline: 0;
	box-shadow: inset 0 0 rem(2) rem(2) blue;
}





// Hide outlines unless focus was gained via keyboard https://github.com/WICG/focus-visible
.js-focus-visible :focus:not(.focus-visible) {
	outline: 0;
	box-shadow: 0 0 0 0 black;
}
.js-focus-visible .focus-visible {
	outline: 0;
	@extend %keyboard-focus;
}
