// Links List
//
// DOD: done
//
// List of full width `button--link` elements with borders
//
// .links-list--slim			- Less padded buttons, no borders
// .links-list--arrows			- With arrows
//
// Markup: ../../templates/prototype/partials/blocks/links-list.twig
//
// Style guide: blocks.linkslist



.links-list {

	font-size: rem(18);

	text-align: left;
	@include mq($from: screen-sm) {
		margin-bottom: rem($space-medium);
	}
	ul {
		margin: 0;
		padding: 0;
		li:first-child {
			border-top: none;
			@include mq($from: screen-sm) {
				//border-top: rem(2) solid $color-grey-5;

			}
		}
		li:last-child {
			@include mq($from: screen-sm) {
				border-bottom: none;

				.button {
					padding-bottom: 0;
				}
			}
		}
		li .button.button--link{
			display: inline-flex;
			justify-content: space-between;
			width: 100%;
			align-items: self-start;
			&:before,
			&:after{
				display: none;
				content: none;
			}
		}
	}
	li {
		margin: 0 !important;
		display: block;
		border-bottom: rem(2) solid $color-grey-6;
	}

	.button--link {
		font-size: inherit;
		padding: rem($space-medium) 0;
		line-height: 1.3em;
		border-bottom: none;

		.text {
			font-weight: 400;
			line-height: 1.2em;
			//@include theme-color('primary-1');
			@include theme-color('links-list-text');

		}
		.icon {
			min-width: 23px;
			line-height: 1.7em;
			height: 1em;
			overflow: visible;
			vertical-align: sub;
			transform: translate(0,0);
		}
		.svg-icon {
			vertical-align: initial;
			// height: 1em;
			transform: scale(1.4);
			transform: translate(0,0);
			//@include theme-fill('primary-1');
			@include theme-color('links-list-text');
		}
		&:hover {
			.text {
				//@include theme-color('primary-2');
				@include theme-color('links-list-text-hover');
			}
			.svg-icon {
				//@include theme-fill('primary-2');
				@include theme-color('links-list-text-hover');
			}
		}
	}


	&--slim {
		li {
			margin: 0 !important;
			display: block;
			border-bottom: 0;
		}

		.button--link {
			padding: 0;
			border-style: solid;
			border-bottom-width: rem(2);
			@extend %general-link;
			.icon {
				width: 1em;
			}
		}
	}

	&--arrows {
		ul {
			display: flex;
			flex-wrap: wrap;
		}

		li {
			border: none;
			flex: 1 1 100%;
			@include mq($from: screen-sm) {
				flex-basis: 50%;
				max-width: 50%;
			}

			&:before {
				display: none !important;
			}

			.button--link {
				padding-right: rem(20);
				display: inline-flex;
				justify-content: flex-start!important;
			}
		}

		.icon.icon-arrow {
			margin-right: rem(10);

			svg {
				@include theme-fill('primary-3');
				width: rem(24);
				height: rem(18);
			}
		}
	}
}
