
// Grey scale colors

$color-black: #000000;
$color-white: #fff;
$color-light-grey: #c1c1c1;
$color-stonewall-grey: #f0f0f0;
$color-pale-grey: #4A4A4A;
$color-rolling-stone: #646D71;
$color-submarine: #828a8f;
$color-casper: #a1a9ac;
$color-zumthor: #c2c5c8;
$color-zircon: #e1e3e5;
$color-white-smoke: #ebebeb;
$color-white: #ffffff;
$color-gallery: #d7f5e1;

$color-grey-1: $color-pale-grey;
$color-grey-2: $color-submarine;
$color-grey-3: $color-casper;
$color-grey-4: $color-zumthor;
$color-grey-5: $color-zircon;
$color-grey-6: $color-white-smoke;
$color-grey-7: $color-rolling-stone;


// Greyscale colors
//
// DOD: done
//
// The greys
//
// Markup: <div class="styleguide-color {{modifier_class}}"></div>
//
//	.c--black   			- black
//	.c--grey-1 				- pale-grey
//	.c--grey-2 				- submarine
//	.c--grey-3  			- casper
//	.c--grey-4  			- zumthor
//	.c--grey-5  			- zircon
//	.c--grey-6				- white-smoke
//	.c--white   			- white
//
// hidedefault: true
//
// Style guide: identity.colors.greys

// Shared custom colors

$color-moon-glow:			#F7EFD3; //box backgrounds
$color-sunset-orange:		#ff4949; //alerts, notifications, errors
$color-roof-terracotta: 	#a94442; //from codehouse, error
$color-valencia: #d73d3d;

$color-beige: $color-moon-glow;
// AA
// $color-red: $color-sunset-orange;
$color-red: #EE0000;
$color-error-text: $color-valencia;

$color-bizarre: #F0DCE1;
$color-error-message: $color-bizarre;

$color-del-rio: #B39696;
$color-thatch: #C0A7A7;
$color-norway: #B0C0A7;
$color-cadet-blue: #A7B4C0;
$color-coral-reef: #C7BD9C;
$color-alto: #D8D8D8;


// $color-blue-lagoon: 	#00667f;
$color-blue-lagoon: 	#00647D;
$color-pelorous:		#000;
$color-seagull:			#000;
$color-mabel:			#badae3;
$color-oyster-bay:		#d3e7ec;

$color-ironside-grey:	#736e6a;
$color-concord:			#000;
$color-mountain-mist:	#000;
$color-mercury:			#d6d4d3;
$color-white-smoke2:	#f1eff0;

$color-genoa:			#33826d;
$color-tidal-pool:		#005a5a;
$color-green-rbl: 		#00a100;
$color-oracle: 			#37509B;
$color-gossamer:		#000;
$color-shadow-blue:		#000;
$color-shadow-green:	#98c0b7;
$color-jet-stream:		#b8d3cd;
$color-tranquil:		#d7f5e1;
$color-tranquil-trivsel:#e9f2ef;
$color-tidal-pool-trivsel:		#005a5a;

$color-seance:			#633677;
$color-affair:			#734885;
$color-prelude:			#c1aec9;
$color-snuff:			#e0d6e4;
$color-selago:			#efeaf1;

$color-contessa:		#BC5759; //be5e5e
$color-apple-blossom:	#AE4547;
$color-maroon-light:	#be325a;
$color-charm:			#c87676;
$color-rose:			#d79a9a;
$color-dust-storm:		#e5bfbf;
$color-amour:			#f4e3e3;
$color-pink-trivsel: 	#fae6eb;
$color-maroon-light:	#be325a;
		

$color-smalt:			#002395;
$color-smalt-trivsel:	#4632b4;
$color-free-speech-blue:#5170CC;
$color-free-speech-blue-trivsel:#000;
$color-polo-blue:		#99a7d5;
$color-hawkes-blue:		#ccd3ea;
$color-hawkes-black-trivsel:		#000;
$color-alice-blue:		#eaefff;

$color-ash:				#beb9a6;
$color-snow-drift:		#e5e3db;
$color-wild-sand:		#f2f1ed;

$color-azalea:			#f9b7c8;

// Ordblinhed
$color-jagged-ice: #D6E8EF;
$color-granny-apple: #CAF2E5;
$color-shamrock: #44D2A6;
$color-white-ice: #DFF9F2;
$color-mystic: #DCE7ED;
$color-prussian-blue: #013A51;
$color-black-squeeze: #EAF3F7;

// UNESCO
$color-bridal-heath: #FFFCF7;
$color-dawn-pink: #F6F1EB;
$color-bianca: #FAF7EB;
$color-satin-linen: #E4DED6;
$color-tundora: #444444;
$color-shuttle-gray: #535E63;
$color-brown-derby: #4B2A13;
$color-taupe: #3A302A;
$color-opal: #A5C4BC;
$color-lily: #CBAFCB;
$color-sandy-brown: #F09F6A;
$color-mineral-green: #476262;
$color-roman-coffee: #7B604D;

$color-unesco-grey: $color-shuttle-gray;
$color-unesco-headline: $color-taupe;

$color-brand-UNESCO-1: $color-black;
$color-brand-UNESCO-2: $color-tundora;
$color-brand-UNESCO-3: $color-shuttle-gray;
$color-brand-UNESCO-4: $color-bianca;
$color-brand-UNESCO-5: $color-bridal-heath;
$color-brand-UNESCO-6: transparent;

$color-spot-1: $color-opal;
$color-spot-2: $color-lily;
$color-spot-3: $color-sandy-brown;
$color-spot-4: $color-mineral-green;
$color-spot-5: $color-roman-coffee;



$color-brand-UVM-1: $color-tidal-pool;
//$color-brand-UVM-1: red;
$color-brand-UVM-2: $color-pelorous;
$color-brand-UVM-3: $color-seagull;
$color-brand-UVM-4: $color-mabel;
$color-brand-UVM-5: $color-tranquil;

// AA
// $color-brand-STUK-1: $color-ironside-grey;
$color-brand-STUK-1: $color-maroon-light;
$color-brand-STUK-2: $color-concord;
$color-brand-STUK-3: $color-mountain-mist;
$color-brand-STUK-4: $color-mercury;
$color-brand-STUK-5: $color-tranquil;

$color-brand-STIL-1: $color-oracle;
$color-brand-STIL-2: $color-gossamer;
$color-brand-STIL-3: $color-shadow-blue;
$color-brand-STIL-4: $color-jet-stream;
$color-brand-STIL-5: $color-tranquil;

$color-brand-STILSTAT-1: $color-seance;
$color-brand-STILSTAT-2: $color-affair;
$color-brand-STILSTAT-3: $color-prelude;
$color-brand-STILSTAT-4: $color-snuff;
$color-brand-STILSTAT-5: $color-selago;

$color-brand-SPSU-1: $color-apple-blossom;
$color-brand-SPSU-2: $color-charm;
$color-brand-SPSU-3: $color-rose;
$color-brand-SPSU-4: $color-dust-storm;
$color-brand-SPSU-5: $color-amour;

$color-brand-RBL-1: $color-smalt;
$color-brand-RBL-2: $color-free-speech-blue;
$color-brand-RBL-3: $color-hawkes-blue;
$color-brand-RBL-4: $color-hawkes-blue;
$color-brand-RBL-5: $color-alice-blue;

$color-brand-trivsel-1: $color-smalt-trivsel;
$color-brand-trivsel-2: black!important;
$color-brand-trivsel-3: $color-hawkes-black-trivsel;
$color-brand-trivsel-4: $color-hawkes-black-trivsel;
$color-brand-trivsel-5: $color-alice-blue;


$color-brand-ORD-1: $color-prussian-blue;
$color-brand-ORD-2: $color-shamrock;
$color-brand-ORD-3: $color-shamrock;
$color-brand-ORD-4: $color-jagged-ice;
$color-brand-ORD-5: $color-black-squeeze;
$color-brand-ORD-5-dark: darken( $color-black-squeeze, 5% );
// AA
// $color-chocolate: #C45516;
$color-chocolate: #BF5315;

$color-brand-SOSU-1: $color-chocolate;
$color-brand-SOSU-2: mix($color-white,#EA8145,20%);
$color-brand-SOSU-3: mix($color-white,#EA8145,40%);
$color-brand-SOSU-4: mix($color-white,#EA8145,60%);
$color-brand-SOSU-5: mix($color-white,#EA8145,80%);
$color-brand-SOSU-6: mix($color-white,#EA8145,95%);

// Digitaliseringsdage
$color-digi-yellow: #fff000;
$color-digi-blue: #005aaa;
$color-digi-pink: #f59bbe;
$color-digi-beige: #fffae6;

$color-brand-DIGIDAGE-1: $color-digi-blue;
$color-brand-DIGIDAGE-2: $color-digi-yellow;
$color-brand-DIGIDAGE-3: $color-digi-pink;
$color-brand-DIGIDAGE-4: $color-digi-beige;
$color-brand-DIGIDAGE-5: $color-white;

// Aldrig mere
$color-brand-aldrigmere-1: #222222;
$color-brand-aldrigmere-2: #eeeeee;
$color-brand-aldrigmere-3: #D3D3D3;
$color-brand-aldrigmere-4: #705E36;
$color-brand-aldrigmere-5: darken(#9e8651, 10%);
$color-brand-aldrigmere-6: #c5b99f;

// Semantic shorthands
//
// DOD: done
//
// Defined semantic color shorthands for common element types.
//
// Markup: <div class="styleguide-color {{modifier_class}}"></div>
//
//	.c--border			- grey-2
//	.c--text			- black
//	.c--text-discreet	- grey-1
//	.c--text-remarkable	- grey-1
//
// hidedefault: true
//
// Style guide: identity.colors.semantic



//Semantic shorthands

$color-border: $color-grey-2;
$color-border-discreet: $color-grey-5;

$color-text: $color-black;
$color-text-remarkable: $color-grey-1;
$color-text-discreet: $color-grey-1;

$color-form-bg: $color-white;
$color-form-border: $color-grey-5;
$color-form-border-active: $color-grey-3;
$color-form-label: $color-black;
$color-form-text: $color-black;
$color-form-placeholder: $color-grey-2;
$color-form-disabled: $color-grey-1;
$color-form-valid: transparent;
$color-form-invalid: $color-red;

$color-error: $color-roof-terracotta;
$color-link-box: $color-del-rio;



$color-promotion-1: $color-brand-UVM-5;  //UVM
$color-promotion-2: $color-brand-STIL-5; //STIL
$color-promotion-3: $color-brand-STUK-4; //STUK
$color-promotion-4: $color-beige; //SAND
$color-promotion-5: $color-tidal-pool; //Corporate Green
$color-promotion-6: $color-brand-STILSTAT-4; //Tertiær 2
$color-promotion-7: #FBE6DA; //Tertiær 3


$themes: (
	'uvm': (
		'site-bg': $color-white,
		'header-bg': $color-white,
		'header-border': $color-brand-UVM-1,
		'header-text': $color-black,
		'notification-bg': $color-brand-UVM-1,
		'notification-text': $color-white,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-UVM-2,
		'entries-nav-bg': $color-brand-UVM-5,
		'entries-nav-headline': $color-black,
		'entries-nav-text': $color-grey-1,
		'entries-nav-icon': $color-brand-UVM-1,
		'hero-box-bg': $color-brand-UVM-4,
		'hero-text': $color-text,
		'banner-bg': $color-brand-UVM-1,
		'banner-text': $color-white,
		'footer-headline': $color-black,
		'footer-text': $color-text-discreet,
		'footer-hover': $color-brand-UVM-2,
		'deck-theme': $color-brand-UVM-5,
		'deck-theme-dark': $color-brand-UVM-4,
		'deck-theme-light': $color-white,
		'pageaside-gray': $color-moon-glow,
		'card-theme-primary': $color-gallery,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-UVM-1,
		'general-link-hover': $color-brand-UVM-3,
		'accordion-text': $color-brand-UVM-1,
		'content-box-note': $color-brand-UVM-5,
		'search-layer': $color-brand-UVM-1,
		'search-button': $color-brand-UVM-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-UVM-4,
		'card-text': $color-black,
		'card-background': $color-beige,
		'card--theme-background': $color-brand-UVM-5,
		'card--theme-darker-background': $color-brand-UVM-4,
		'card--theme-banner-background': $color-brand-UVM-4,
		'card--featured-background': $color-brand-UVM-5,
		'links-list-text': $color-brand-UVM-1,
		'links-list-text-hover': $color-brand-UVM-2,
		'primary-1': $color-brand-UVM-1,
		'primary-2': $color-brand-UVM-2,
		'primary-3': $color-brand-UVM-3,
		'primary-4': $color-brand-UVM-4,
		'primary-5': $color-brand-UVM-5,
		'secondary': $color-beige
	),
	'stuk': (
		'header-bg': $color-white,
		'header-border': $color-brand-STUK-1,
		'header-text': $color-black,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-STUK-2,
		'notification-bg': $color-brand-STUK-1,
		'notification-text': $color-white,
		'hero-box-bg': $color-brand-STUK-4,
		'hero-text': $color-text,
		'banner-bg': $color-brand-STUK-1,
		'banner-text': $color-white,
		'entries-nav-bg': $color-brand-STUK-5,
		'entries-nav-headline': $color-black,
		'entries-nav-text': $color-grey-1,
		'entries-nav-icon': $color-brand-STUK-1,
		'footer-headline': $color-black,
		'footer-text': $color-text-discreet,
		'footer-hover': $color-brand-STUK-2,
		'deck-theme': $color-brand-STUK-5,
		'deck-theme-dark': $color-brand-STUK-4,
		'deck-theme-light': $color-white,
		'pageaside-gray': $color-moon-glow,
		'card-theme-primary': $color-gallery,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-STUK-1,
		'general-link-hover': $color-brand-STUK-3,
		'content-box-note': $color-brand-STUK-5,
		'accordion-text': $color-brand-STUK-1,
		'search-layer': $color-brand-STUK-1,
		'search-button': $color-brand-STUK-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-STUK-4,
		'card-text': $color-black,
		'card-background': $color-beige,
		'card--theme-background': $color-brand-STUK-5,
		'card--theme-darker-background': $color-brand-STUK-4,
		'card--theme-banner-background': $color-brand-STUK-4,
		'card--featured-background': $color-brand-STUK-5,
		'links-list-text': $color-brand-STUK-1,
		'links-list-text-hover': $color-brand-STUK-2,
		'primary-1': $color-brand-STUK-1,
		'primary-2': $color-brand-STUK-2,
		'primary-3': $color-brand-STUK-3,
		'primary-4': $color-brand-STUK-4,
		'primary-5': $color-brand-STUK-5,
		'secondary': $color-beige,
		'site-bg': $color-white
	),
	'stil': (
		'site-bg': $color-white,
		'header-bg': $color-white,
		'header-text': $color-black,
		'notification-bg': $color-brand-STIL-1,
		'notification-text': $color-white,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-STIL-2,
		'footer-headline': $color-black,
		'footer-text': $color-text-discreet,
		'footer-hover': $color-brand-STIL-2,
		'header-border': $color-brand-STIL-1,
		'entries-nav-bg': $color-brand-STIL-5,
		'entries-nav-headline': $color-black,
		'entries-nav-text': $color-grey-1,
		'entries-nav-icon': $color-brand-STIL-1,
		'hero-box-bg': $color-brand-STIL-4,
		'hero-text': $color-text,
		'banner-bg': $color-brand-STIL-1,
		'banner-text': $color-white,
		'deck-theme': $color-brand-STIL-5,
		'deck-theme-dark': $color-brand-STIL-4,
		'deck-theme-light': $color-white,
		'pageaside-gray': $color-moon-glow,
		'card-theme-primary': $color-gallery,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-STIL-1,
		'general-link-hover': $color-brand-STIL-3,
		'content-box-note': $color-brand-STIL-5,
		'accordion-text': $color-brand-STIL-1,
		'search-layer': $color-brand-STIL-1,
		'search-button': $color-brand-STIL-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-STIL-4,
		'card-text': $color-black,
		'card-background': $color-beige,
		'card--theme-background': $color-brand-STIL-5,
		'card--theme-darker-background': $color-brand-STIL-4,
		'card--theme-banner-background': $color-brand-STIL-4,
		'card--featured-background': $color-brand-STIL-5,
		'links-list-text': $color-brand-STIL-1,
		'links-list-text-hover': $color-brand-STIL-2,
		'primary-1': $color-brand-STIL-1,
		'primary-2': $color-brand-STIL-2,
		'primary-3': $color-brand-STIL-3,
		'primary-4': $color-brand-STIL-4,
		'primary-5': $color-brand-STIL-5,
		'secondary': $color-beige
	),
	'stil-stat': (
		'site-bg': $color-white,
		'header-bg': $color-white,
		'header-border': $color-brand-STILSTAT-1,
		'header-text': $color-black,
		'notification-bg': $color-brand-STILSTAT-1,
		'notification-text': $color-white,
		'entries-nav-bg': $color-brand-STILSTAT-5,
		'entries-nav-headline': $color-black,
		'entries-nav-text': $color-grey-1,
		'entries-nav-icon': $color-brand-STILSTAT-1,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-STILSTAT-2,
		'hero-box-bg': $color-brand-STILSTAT-4,
		'hero-text': $color-text,
		'banner-bg': $color-brand-STILSTAT-1,
		'banner-text': $color-white,
		'footer-headline': $color-black,
		'footer-text': $color-text-discreet,
		'footer-hover': $color-brand-STILSTAT-2,
		'deck-theme': $color-brand-STILSTAT-5,
		'deck-theme-dark': $color-brand-STILSTAT-4,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-STILSTAT-1,
		'general-link-hover': $color-brand-STILSTAT-3,
		'content-box-note': $color-brand-STILSTAT-5,
		'accordion-text': $color-brand-STILSTAT-1,
		'search-layer': $color-brand-STILSTAT-1,
		'search-button': $color-brand-STILSTAT-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-STILSTAT-4,
		'card-background': $color-beige,
		'card-text': $color-black,
		'card--theme-background': $color-brand-STILSTAT-5,
		'card--theme-darker-background': $color-brand-STILSTAT-4,
		'card--theme-banner-background': $color-brand-STILSTAT-4,
		'card--featured-background': $color-brand-STILSTAT-5,
		'links-list-text': $color-brand-STILSTAT-1,
		'links-list-text-hover': $color-brand-STILSTAT-2,
		'primary-1': $color-brand-STILSTAT-1,
		'primary-2': $color-brand-STILSTAT-2,
		'primary-3': $color-brand-STILSTAT-3,
		'primary-4': $color-brand-STILSTAT-4,
		'primary-5': $color-brand-STILSTAT-5,
		'secondary': $color-beige
		
	),
	'kanalen': (
		'site-bg': darken($color-grey-6, 0%),
		'header-bg': $color-white,
		'header-border': $color-brand-UVM-1,
		'header-text': $color-black,
		'notification-bg': $color-brand-UVM-1,
		'notification-text': $color-white,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-UVM-2,
		'hero-box-bg': $color-brand-UVM-4,
		'hero-text': $color-text,
		'banner-bg': $color-brand-UVM-1,
		'banner-text': $color-white,
		'footer-headline': $color-black,
		'footer-text': $color-text-discreet,
		'footer-hover': $color-brand-UVM-2,
		'deck-theme': $color-brand-UVM-5,
		'deck-theme-dark': $color-brand-UVM-4,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-UVM-1,
		'general-link-hover': $color-brand-UVM-3,
		'content-box-note': $color-brand-UVM-5,
		'accordion-text': $color-brand-UVM-1,
		'search-layer': $color-brand-UVM-1,
		'search-button': $color-brand-UVM-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-UVM-4,
		'card-text': $color-black,
		'card-background': $color-beige,
		'card--theme-background': $color-brand-UVM-5,
		'card--theme-darker-background': $color-brand-UVM-4,
		'card--theme-banner-background': $color-brand-UVM-4,
		'card--featured-background': $color-brand-UVM-5,
		'links-list-text': $color-brand-UVM-1,
		'links-list-text-hover': $color-brand-UVM-2,
		'primary-1': $color-brand-UVM-1,
		'primary-2': $color-brand-UVM-2,
		'primary-3': $color-brand-UVM-3,
		'primary-4': $color-brand-UVM-4,
		'primary-5': $color-brand-UVM-5,
		'secondary': $color-beige
		
	),
	'spsu': (
		'site-bg': $color-white,
		'header-bg': $color-white,
		'header-border': $color-brand-SPSU-1,
		'header-text': $color-black,
		'notification-bg': $color-brand-SPSU-1,
		'notification-text': $color-white,
		'entries-nav-bg': $color-brand-SPSU-5,
		'entries-nav-headline': $color-black,
		'entries-nav-text': $color-grey-1,
		'entries-nav-icon': $color-brand-SPSU-1,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-SPSU-2,
		'hero-box-bg': $color-brand-SPSU-4,
		'hero-text': $color-text,
		'banner-bg': $color-brand-SPSU-1,
		'banner-text': $color-white,
		'footer-headline': $color-black,
		'footer-text': $color-text-discreet,
		'footer-hover': $color-brand-SPSU-2,
		'deck-theme': $color-brand-SPSU-5,
		'deck-theme-dark': $color-brand-SPSU-4,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-SPSU-1,
		'general-link-hover': $color-brand-SPSU-3,
		'content-box-note': $color-brand-SPSU-5,
		'accordion-text': $color-brand-SPSU-1,
		'search-layer': $color-brand-SPSU-1,
		'search-button': $color-brand-SPSU-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-SPSU-4,
		'card-text': $color-black,
		'card-background': $color-beige,
		'card--theme-background': $color-brand-SPSU-5,
		'card--theme-darker-background': $color-brand-SPSU-4,
		'card--theme-banner-background': $color-brand-SPSU-4,
		'card--featured-background': $color-brand-SPSU-5,
		'links-list-text': $color-brand-SPSU-1,
		'links-list-text-hover': $color-brand-SPSU-2,
		'primary-1': $color-brand-SPSU-1,
		'primary-2': $color-brand-SPSU-2,
		'primary-3': $color-brand-SPSU-3,
		'primary-4': $color-brand-SPSU-4,
		'primary-5': $color-brand-SPSU-5,
		'secondary': $color-beige
		
	),
	'rbl': (
		'site-bg': $color-white,
		'header-bg': $color-white,
		'header-border': $color-brand-RBL-1,
		'header-text': $color-black,
		'entries-nav-bg': $color-brand-RBL-5,
		'entries-nav-headline': $color-black,
		'entries-nav-text': $color-grey-1,
		'entries-nav-icon': $color-brand-RBL-1,
		'notification-bg': $color-brand-RBL-1,
		'notification-text': $color-white,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-RBL-2,
		'hero-box-bg': $color-brand-RBL-4,
		'hero-text': $color-text,
		'banner-bg': $color-brand-RBL-1,
		'banner-text': $color-white,
		'footer-headline': $color-black,
		'footer-text': $color-text-discreet,
		'footer-hover': $color-brand-RBL-2,
		'deck-theme': $color-brand-RBL-5,
		'deck-theme-dark': $color-brand-RBL-4,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-RBL-1,
		'general-link-hover': $color-brand-RBL-3,
		'content-box-note': $color-brand-RBL-5,
		'accordion-text': $color-brand-RBL-1,
		'search-layer': $color-brand-RBL-1,
		'search-button': $color-brand-RBL-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-RBL-4,
		'card-background': $color-snow-drift,
		'card-text': $color-black,
		'card--theme-background': $color-brand-RBL-5,
		'card--theme-darker-background': $color-brand-RBL-4,
		'card--theme-banner-background': $color-brand-RBL-4,
		'card--featured-background': $color-brand-RBL-5,
		'links-list-text': $color-brand-RBL-1,
		'links-list-text-hover': $color-brand-RBL-2,
		'primary-1': $color-brand-RBL-1,
		'primary-2': $color-brand-RBL-2,
		'primary-3': $color-brand-RBL-3,
		'primary-4': $color-brand-RBL-4,
		'primary-5': $color-brand-RBL-5,
		'secondary': $color-snow-drift
		
	),
	'trivsel': (
		'site-bg': $color-white,
		'header-bg': $color-white,
		'header-border': $color-brand-trivsel-1,
		'header-text': $color-black,
		'entries-nav-bg': $color-brand-trivsel-5,
		'entries-nav-headline': $color-black,
		'entries-nav-text': $color-grey-1,
		'entries-nav-icon': $color-brand-trivsel-1,
		'notification-bg': $color-brand-trivsel-1,
		'notification-text': $color-white,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-trivsel-2,
		'hero-box-bg': $color-brand-trivsel-4,
		'hero-text': $color-text,
		'banner-bg': $color-brand-trivsel-1,
		'banner-text': $color-white,
		'footer-headline': $color-black,
		'footer-text': $color-text-discreet,
		'footer-hover': $color-brand-trivsel-2,
		'deck-theme': $color-brand-trivsel-5,
		'deck-theme-dark': $color-brand-trivsel-4,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-trivsel-1,
		'general-link-hover': $color-brand-trivsel-3,
		'content-box-note': $color-brand-trivsel-5,
		'accordion-text': $color-brand-trivsel-1,
		'search-layer': $color-brand-trivsel-1,
		'search-button': $color-brand-trivsel-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-trivsel-4,
		'card-background': $color-snow-drift,
		'card-text': $color-black,
		'card--theme-background': $color-brand-trivsel-5,
		'card--theme-darker-background': $color-brand-trivsel-4,
		'card--theme-banner-background': $color-brand-trivsel-4,
		'card--featured-background': $color-brand-trivsel-5,
		'links-list-text': $color-brand-trivsel-1,
		'links-list-text-hover': $color-brand-trivsel-2,
		'primary-1': $color-brand-trivsel-1,
		'primary-2': $color-brand-trivsel-2,
		'primary-3': $color-brand-trivsel-3,
		'primary-4': $color-brand-trivsel-4,
		'primary-5': $color-brand-trivsel-5,
		'secondary': $color-snow-drift
		
	),
	'ord': (
		'site-bg': $color-white,
		'header-bg': $color-brand-ORD-5,
		'header-border': $color-brand-ORD-1,
		'notification-bg': $color-brand-ORD-1,
		'notification-text': $color-white,
		'entries-nav-bg': $color-brand-ORD-5,
		'entries-nav-headline': $color-black,
		'entries-nav-text': $color-grey-1,
		'entries-nav-icon': $color-brand-ORD-1,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-ORD-2,
		'hero-box-bg': $color-brand-ORD-4,
		'hero-text': $color-text,
		'banner-bg': $color-brand-ORD-1,
		'banner-text': $color-white,
		'footer-bg': $color-brand-ORD-1,
		'footer-text': $color-white,
		'footer-hover': $color-brand-ORD-2,
		'deck-theme': $color-brand-ORD-5,
		'deck-theme-dark': $color-brand-ORD-4,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-ORD-1,
		'general-link-hover': $color-brand-ORD-3,
		'content-box-note': $color-brand-ORD-5,
		'accordion-text': $color-brand-ORD-1,
		'search-layer': $color-brand-ORD-1,
		'search-button': $color-brand-ORD-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-ORD-4,
		'card-text': $color-black,
		'card-background': $color-white-ice,
		'card--theme-background': $color-brand-ORD-5,
		'card--theme-darker-background': $color-brand-ORD-4,
		'card--theme-banner-background': $color-brand-ORD-4,
		'card--featured-background': $color-brand-ORD-5,
		'links-list-text': $color-brand-ORD-1,
		'links-list-text-hover': $color-brand-ORD-2,
		'primary-1': $color-brand-ORD-1,
		'primary-2': $color-brand-ORD-2,
		'primary-3': $color-brand-ORD-3,
		'primary-4': $color-brand-ORD-4,
		'primary-5': $color-brand-ORD-5,
		'secondary': $color-white-ice
		
	),
	'sosu': (
		'site-bg': $color-white,
		'header-bg': $color-white,
		'header-border': $color-brand-SOSU-1,
		'header-text': $color-black,
		'notification-bg': $color-brand-SOSU-1,
		'notification-text': $color-white,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-SOSU-2,
		'entries-nav-bg': $color-brand-SOSU-5,
		'entries-nav-headline': $color-black,
		'entries-nav-text': $color-grey-1,
		'entries-nav-icon': $color-brand-SOSU-1,
		'hero-box-bg': $color-brand-SOSU-4,
		'hero-text': $color-text,
		'banner-bg': $color-brand-SOSU-1,
		'banner-text': $color-white,
		'footer-headline': $color-black,
		'footer-text': $color-text-discreet,
		'footer-hover': $color-brand-SOSU-2,
		'deck-theme': $color-brand-SOSU-5,
		'deck-theme-dark': $color-brand-SOSU-4,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-SOSU-1,
		'general-link-hover': $color-brand-SOSU-3,
		'content-box-note': $color-brand-SOSU-5,
		'accordion-text': $color-brand-SOSU-1,
		'search-layer': $color-brand-SOSU-1,
		'search-button': $color-brand-SOSU-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-SOSU-4,
		'card-text': $color-black,
		'card-background': $color-brand-SOSU-6,
		'card--theme-background': $color-brand-SOSU-5,
		'card--theme-darker-background': $color-brand-SOSU-4,
		'card--theme-banner-background': $color-brand-SOSU-4,
		'card--featured-background': $color-brand-SOSU-5,
		'links-list-text': $color-brand-SOSU-1,
		'links-list-text-hover': $color-brand-SOSU-2,
		'primary-1': $color-brand-SOSU-1,
		'primary-2': $color-brand-SOSU-2,
		'primary-3': $color-brand-SOSU-3,
		'primary-4': $color-brand-SOSU-4,
		'primary-5': $color-brand-SOSU-5,
		'secondary': $color-brand-SOSU-6
	),
	'digitaliseringsdage': (
		'site-bg': $color-white,
		'header-bg': $color-white,
		'header-border': $color-brand-DIGIDAGE-2,
		'header-text': $color-black,
		'notification-bg': $color-brand-DIGIDAGE-1,
		'notification-text': $color-white,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-DIGIDAGE-2,
		'entries-nav-bg': $color-brand-DIGIDAGE-5,
		'entries-nav-headline': $color-black,
		'entries-nav-text': $color-grey-1,
		'entries-nav-icon': $color-brand-DIGIDAGE-1,
		'hero-box-bg': $color-white,
		'hero-text': $color-text,
		'banner-bg': $color-brand-DIGIDAGE-1,
		'banner-text': $color-white,
		'footer-headline': $color-black,
		'footer-text': $color-text-discreet,
		'footer-hover': $color-brand-DIGIDAGE-2,
		'deck-theme': $color-brand-DIGIDAGE-5,
		'deck-theme-dark': $color-brand-DIGIDAGE-4,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-black,
		'general-link-hover': $color-black,
		'content-box-note': $color-brand-DIGIDAGE-5,
		'accordion-text': $color-brand-DIGIDAGE-1,
		'search-layer': $color-brand-DIGIDAGE-1,
		'search-button': $color-brand-DIGIDAGE-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-DIGIDAGE-4,
		'card-text': $color-black,
		'card-background': $color-brand-DIGIDAGE-2,
		'card--theme-background': $color-brand-DIGIDAGE-5,
		'card--theme-darker-background': $color-brand-DIGIDAGE-4,
		'card--theme-banner-background': $color-brand-DIGIDAGE-2,
		'card--featured-background': $color-brand-DIGIDAGE-5,
		'links-list-text': $color-brand-DIGIDAGE-1,
		'links-list-text-hover': $color-black,
		'primary-1': $color-brand-DIGIDAGE-1,
		'primary-2': $color-brand-DIGIDAGE-2,
		'primary-3': $color-brand-DIGIDAGE-3,
		'primary-4': $color-brand-DIGIDAGE-4,
		'primary-5': $color-brand-DIGIDAGE-5,
		'secondary': $color-brand-DIGIDAGE-2
	),
	'aldrigmere': (
		'site-bg': $color-brand-aldrigmere-2,
		'header-bg': $color-brand-aldrigmere-1,
		'header-border': $color-brand-aldrigmere-1,
		'notification-text': $color-white,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-aldrigmere-2,
		'banner-bg': $color-brand-aldrigmere-1,
		'banner-text': $color-white,
		'footer-bg': $color-brand-aldrigmere-1,
		'footer-headline': $color-white,
		'footer-text': $color-white,
		'footer-hover': $color-brand-aldrigmere-2,
		//'deck-theme': $color-brand-aldrigmere-5,
		//'deck-theme-dark': $color-brand-aldrigmere-4,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-aldrigmere-1,
		'general-link-hover': $color-brand-aldrigmere-5,
		'accordion-text': $color-brand-aldrigmere-4,
		'content-box-note': $color-brand-aldrigmere-5,
		'search-layer': $color-brand-aldrigmere-5,
		'search-button': $color-brand-aldrigmere-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-aldrigmere-3,
		'card-text': $color-black,
		'card-background': $color-brand-aldrigmere-1,
		'card--theme-background': $color-brand-aldrigmere-5,
		'card--theme-darker-background': $color-brand-aldrigmere-5,
		'card--theme-banner-background': $color-brand-SOSU-3,
		'card--featured-background': $color-brand-aldrigmere-5,
		'links-list-text': $color-brand-aldrigmere-1,
		'links-list-text-hover': $color-brand-aldrigmere-2,
		'primary-1': $color-brand-aldrigmere-1,
		'primary-2': $color-brand-aldrigmere-2,
		'primary-3': $color-brand-aldrigmere-3,
		//'primary-4': $color-brand-aldrigmere-4,
		'primary-5': $color-brand-aldrigmere-5
	),
	'unesco': (
		'site-bg': $color-bridal-heath,
		'header-bg': transparent,
		'header-border': $color-sandy-brown,
		'header-text': $color-black,
		'notification-bg': $color-brand-UNESCO-1,
		'notification-text': $color-white,
		'entries-nav-bg': $color-brand-UNESCO-5,
		'entries-nav-headline': $color-black,
		'entries-nav-text': $color-grey-1,
		'entries-nav-icon': $color-brand-UNESCO-1,
		'hero-box-bg': $color-brand-UNESCO-4,
		'hero-text': $color-text,
		'banner-bg': $color-sandy-brown,
		'banner-text': $color-black,
		'footer-headline': $color-black,
		'footer-text': $color-text-discreet,
		'footer-hover': $color-brand-UNESCO-2,
		'breadcrumb-text': $color-grey-1,
		'breadcrumb-hover': $color-brand-UNESCO-2,
		'deck-theme': $color-brand-UNESCO-5,
		'deck-theme-dark': $color-brand-UNESCO-4,
		'article-headings': $color-black,
		'article-paragraph': $color-grey-1,
		'general-link': $color-brand-UNESCO-1,
		'general-link-hover': $color-brand-UNESCO-5,
		'content-box-note': $color-brand-UNESCO-5,
		'accordion-text': $color-brand-UNESCO-1,
		'search-layer': $color-brand-UNESCO-1,
		'search-button': $color-brand-UNESCO-1,
		'search-button-icon': $color-white,
		'search-highlight': $color-brand-UNESCO-4,
		'card-text': $color-black,
		'card-background': $color-brand-UNESCO-6,
		'card--theme-background': $color-brand-UNESCO-5,
		'card--theme-darker-background': $color-brand-UNESCO-4,
		'card--theme-banner-background': $color-brand-UNESCO-4,
		'card--featured-background': $color-brand-UNESCO-5,
		'links-list-text': $color-brand-UNESCO-1,
		'links-list-text-hover': $color-brand-UNESCO-2,
		'primary-1': $color-brand-UNESCO-1,
		'primary-2': $color-brand-UNESCO-2,
		'primary-3': $color-brand-UNESCO-3,
		'primary-4': $color-brand-UNESCO-4,
		'primary-5': $color-brand-UNESCO-5,
		'secondary': $color-brand-UNESCO-6
	)
) !default;

/// Themify mixin
/// @access private
/// @author Hugo Giraudel
/// @param {String} $property - Property to themify
/// @param {String} $key - Key color to use from theme
/// @param {Map} $themes [$themes] - Map of themes to use
@mixin themify($property, $key, $themes: $themes) {
	// Iterate over the themes
	@each $theme, $colors in $themes {
		// Create a selector (e.g. `.media.theme-unicorn, .theme-unicorn .media`)
		&.theme-#{$theme},
		.theme-#{$theme} & {
			// Output the declaration
			#{$property}: map-get($colors, $key);
		}
		&.theme--local.theme-#{$theme} {
            #{$property}: map-get($colors, $key);
        }
        .theme--local.theme-#{$theme} & {
            #{$property}: map-get($colors, $key);
        }
	}
}
/// Shorthand to themify color through `themify` mixin
/// @access public
/// @see {mixin} themify
@mixin theme-color($arguments...) {
	@include themify('color', $arguments...);
}
/// Shorthand to themify fill through `themify` mixin
/// @access public
/// @see {mixin} themify
@mixin theme-fill($arguments...) {
	@include themify('fill', $arguments...);
}
/// Shorthand to themify border-color through `themify` mixin
/// @access public
/// @see {mixin} themify
@mixin theme-border-color($arguments...) {
	@include themify('border-color', $arguments...);
}
/// Shorthand to themify background-color through `themify` mixin
/// @access public
/// @see {mixin} themify
@mixin theme-background-color($arguments...) {
	@include themify('background-color', $arguments...);
}
@mixin theme-background-color-half($key, $themes: $themes) {
	@each $theme, $colors in $themes {
		// Create a selector (e.g. `.media.theme-unicorn, .theme-unicorn .media`)
		&.theme-#{$theme},
		.theme-#{$theme} & {
			// Output the declaration
			background-image: linear-gradient(to bottom, map-get($colors, $key) 50%, transparent 50% 100%);
		}
		&.theme--local.theme-#{$theme} {
            background-image: linear-gradient(to bottom, map-get($colors, $key) 50%, transparent 50% 100%);
        }
        .theme--local.theme-#{$theme} & {
            background-image: linear-gradient(to bottom, map-get($colors, $key) 50%, transparent 50% 100%);
        }
	}
}
