// Breadcrumbs
//
// DOD: done
//
// List of `.button--breadcrumb` elements. Last one can have class `.button--breadcrumb--emphasized` to make it stand out.
//
// Markup: ../../templates/prototype/partials/blocks/breadcrumbs.twig
//
// Style guide: blocks.breadcrumbs

.breadcrumb-wrapper{		
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &,
		.theme-kanalen &
		{
			background-color: $color-gallery;
		}
		.theme-trivsel &		
		{
			background-color: $color-white;
		}
}
.breadcrumbs {
	position: relative;
	padding:rem($space-xsmall - 4) 0 rem($space-small - 4) ;

	transition: padding 0.2s ease-out;

	@include clearfix;
	font-size:rem(16);

	@include mq($from: screen-sm) {
		padding:rem($space-xsmall) 0 rem($space-small) ;
	}

	.button--breadcrumb {
		//color:$color-grey-1;
		@include theme-color('breadcrumb-text');
		.text {
			display: inline;
			font-size: 1em;
			font-weight: 400;
			line-height: 1.2em;
			transition:color 0.2s ease-out;
			vertical-align: top;
		}

		&--emphasized .text {
			font-weight: 700;
			color:$color-black;
		}
	}

	ul li {
		display: inline;
		padding:0;

		&:after {
			vertical-align: bottom;
			content:'/';
			display: inline-block;
			font-size:rem(16);
			line-height: 1.5;
			padding:0 0.5em;
			font-weight: 400;
		}


		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	.button--breadcrumb {
		display: inline;
		&:hover {
			.text {
				.theme-trivsel &
				{				
					color: $color-smalt-trivsel;
				}
				
				.theme-stuk &
				{			
					color: $color-maroon-light;
				}				
				.theme-stil &
				{				
					color: $color-oracle;
				}			
				.theme-uvm &
				{				
					color: $color-tidal-pool;
				}
				.theme-kanalen &
				{				
					color: $color-tidal-pool;
				}
			}
		}
	}

	&--crown {

		.icon {
			opacity: 0;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-60%);
			width: rem(25);
			height: rem(25);
			transition: opacity 0.2s ease-out;

			.svg-icon {
				width: rem(25);
				height: rem(25);
			}
		}
	}

	.is-sticky & {
		padding-left: rem(35);

		.icon {
			transition: opacity 0.2s ease-out 0.2s;
			opacity: 1;
		}
	}
}
