// Notification
//
// DOD: done
//
// Message block with close button.
//
// .notification--discreet			- Less noticeable version
// .notification--significant		- Black background, extra height
// .notification--critical			- Red background
//
// Weight: 1
//
// Markup: ../../templates/prototype/partials/blocks/notification.twig
//
// Style guide: blocks.notification


.notification {
	position: relative;
	//@include theme-background-color('primary-1');
	@include theme-background-color('notification-bg');
	@include theme-color('notification-text');
	//color: $color-white;


	&__inner {
		position: relative;
		padding: rem($space-small - 4) rem($space-large) rem($space-small) 0 ;
	}

	p {

		font-size:rem(16);
		color: currentColor;
		@include mq($from: screen-sm){
			// JPL: Codehouse Integration
			// JPL: Button is positioned wrongly on wide mobile when inline, therefore sm breakpoint
			display: inline;
		}

	}
	a {
		// JPL: Codehouse Integration
		color: currentColor;

		text-decoration: underline;
		transition:opacity 0.2s ease-out;
		&:hover {
			opacity:0.8;
			color: currentColor !important;
		}
		@include mq($from: screen-sm){
			display: inline;
		}
	}

	h5 {
		font-size:rem(16);
		font-weight: 700;
		color: $color-text;
	}

	hr {
		height: rem(40);
		border: 0;
		margin: 0 rem($space-medium);
		border-left: rem(1) solid $color-border;
	}

	.button--icon {
		color: currentColor;
		position: absolute;
		top:50%;
		transform:translateY(-50%);
		right:0;
		.icon,
		.svg-icon {
			fill: currentColor;
			width:rem(20);
			height:rem(20);
		}
	}


	&--significant.notification {
		background-color: $color-black;
		color:$color-white;

		p {
			font-size: rem(14);
		}

		@include mq($from: screen-sm){
			padding: rem($space-small) 0;
		}

	}


	&--discreet.notification {
		@include theme-background-color('primary-5');
		color: $color-grey-1;
		h5, p {
			font-size: rem(14);
		}
		hr {
			display: none;
		}

		.button--small {
			margin: rem($space-medium) 0 0;
			.text { color: $color-grey-1; }
			&:hover{
				background-color: $color-tidal-pool;
				color: white!important;
				.text{
					color: white!important;
				}
			}
		}

		@include mq($from: screen-sm) {
			hr {
				display: inline-block;
			}
			.notification__inner {
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
			.button--small {
				margin: 0 0 0 rem($space-medium);
			}
		}
	}

	&--critical.notification {
		background: $color-red;
		color: $color-white;
	}
}
