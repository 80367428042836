// Video
//
// DOD: done
//
// Video element
//
// Weight: 1
//
// Markup: ../../templates/prototype/partials/blocks/video.twig
//
// Style guide: blocks.video

.video {
	position: relative;
	width: 100%;

	.rich-text & {
		margin-bottom: rem($space-large);
	}

	.video__wrapper {
		position: relative;
		height: 0;
		padding-top: 56.25%;

		img, .rich-text & img {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			@include objectfit;
		}

		video, iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}


	.button--play {
		@include theme-background-color('primary-2');
		position: absolute;
		top: 50%;
		left: 50%;
		width: rem(30);
		height: rem(30);
		padding: rem(30);
		border-radius: 50%;
		transform: translateX(-50%) translateY(-50%);
		border: 0.125rem solid #ffffff;
		opacity: 0.7;
		z-index: 5;

		&.is-hidden {
			display: none;
		}
	}

	.icon {
		display: block;
		width: rem(60);
		height: rem(60);
		position: absolute;
		top: 50%;
		left: 55%;
		transform: translateX(-50%) translateY(-50%);

		.svg-icon {
			width: rem(60);
			height: rem(60);
			fill: #ffffff;
		}
	}

	figcaption {
		margin-top: rem($space-small);
	}
}
