// Update Log
//
// DOD: done
//
// List of recent events.
//
// Weight: 1
//
// Markup: ../../templates/prototype/partials/elements/update-log.twig
//
// Style guide: kanalen.updatelog

.update-log {
	position: absolute;
	top: 90%;

	right: rem($space-xsmall);
	left: rem($space-xsmall);

	@include mq($from: screen-sm) {
		left: auto;
		top: 90%;
		right: rem(60);
		width: rem(300);
	}

	background: $color-white;
	box-shadow: 0 rem(5) rem(20) rem(5) rgba(0, 0, 0, 0.1);
	font-size: rem(10);

	transition: all 0.1s ease-out;
	transform-origin: top center;
	transform: scaleY(0) translateY(#{rem(5)});
	opacity: 0;

	display: none;

	z-index: 2;

	&:before {
		content: '';
		width: 0;
		height: 0;
		border-bottom: rem(10) solid $color-white;
		border-left: rem(10) solid transparent;
		border-right: rem(10) solid transparent;
		transform: translateX(-50%);
		position: absolute;
		top: rem(-10);
		right: rem(40);
	}

	&.is-active {
		transform: scaleY(1) translateY(#{rem(5)});
		opacity: 1;
	}
	//show in DS
	&.is-visible {
		display: block;
		position: static;
	}


	> * {
		padding: rem($space-xsmall) rem($space-medium);
	}

	h4 {
		color: $color-text;
		font-weight: 700;
		font-size: rem(12);
	}
	h5 {
		color: $color-text;
		display: inline-block;
		font-weight: 700;
		font-size: rem(10);
		text-transform: uppercase;
		letter-spacing: 0.05em;
	}
	p {
		margin-top: 0.5rem;
		font-size: rem(12);
		align-self: flex-start;
	}
	h6 {
		display: inline-block;
		font-size: rem(10);
	}


	&__row {
		&--header {
			background: $color-grey-5;
		}

		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		transition: background 0.3s;
		color: $color-grey-1;

		&.button:hover {
			background: $color-grey-6;
		}
	}
	.update-log__row:not(.update-log__row--header) + .update-log__row:not(.update-log__row--header) {
		border-top: rem(1) solid $color-border-discreet;
	}


	&__mark.button {
		display: flex;
		align-items: center;
		padding-right: 0;
		.text {
			font-size: rem(10);
			margin: 0;
			color: inherit;
		}

		.icon {
			font-size: rem(10);
			width: auto;
			height: auto;
			position: relative;
			top: 0;
			left: 0;
			transform: none;
		}
	}
}
