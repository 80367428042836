.keyword-toggle {
	padding: rem($space-small) rem($space-medium);
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: rem(10);
	height: rem(40);
	box-sizing: border-box;
	color: $color-text;
	font-weight: 700;
	text-transform: uppercase;
	background: $color-grey-5;
	transition: background 0.2s ease-out;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	.svg-icon {
		width: rem(15);
		height: rem(15);
		@include theme-fill('primary-2');
		opacity: 0;
		transition: opacity 0.2s ease-out;
	}

	&.is-active {
		@include theme-background-color('primary-4');
		.svg-icon {
			opacity: 1;
		}
	}
}
