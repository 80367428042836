

.image-holder {
    &.image-holder--middle {
        font: 0/0 a;
        &:before {
            content: ' ';
            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }
        >img,
        .img {
            font: $base-font-size + px/1.4 $font-stack-primary;
            display: inline-block;
        }
    }
}

.gm-style img,
.map-canvas img[width],
.map-canvasimg[height] {
    max-width: none;
}
