.page-aside {
	$module: ".page-aside";

	h6 {
		margin-bottom: rem($space-xlarge);
	}

	&__inner {
		@include theme-background-color("secondary");			
		.theme-trivsel &
		{
			background-color: $color-stonewall-grey;
		}
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &,
		.theme-kanalen &
		{
			background-color: $color-stonewall-grey;
		}

		> p,
		h4,
		h5,
		h6 {
			display: block;
			color: $color-black;
			@extend %headline-5;
			margin-bottom: rem($space-large);
			flex: 0 0 100%;
		}
		h4,
		h5,
		h6 {		
			.theme-trivsel &
			{				
				color: $color-smalt-trivsel;
			}
		}

		padding: rem($space-small);
		@include mq($from: screen-sm) {
			padding: rem($space-xxxlarge);
			display: flex;
			flex-wrap: wrap;
		}
		@include mq($from: screen-md) {
			padding: rem($space-large);
			display: block;
		}

		+ #{$module}__inner {
			margin-top: rem($space-xsmall);
		}

		.author-box {
			flex: 0 1 45%;
			margin-right: 5%;

			& + .author-box {
				margin-top: rem($space-xxlarge);
			}

			@include mq($from: screen-sm, $until: screen-md) {
				margin-bottom: 0;
				margin-top: 0;
				& + .author-box {
					margin-top: 0;
				}

				&:nth-child(3) ~ * {
					margin-top: rem($space-xxxlarge);
				}
			}
			@include mq($from: screen-md) {
				& + .author-box {
					margin-top: rem($space-xxxlarge);
				}
			}
		}

		.contact {
			p,
			strong {
				line-height: 1.5;
			}
		}

		&--dual {
			@include mq($from: screen-md) {
				display: flex;
			}
			.author-box {
				@include mq($from: screen-md) {
					margin-bottom: 0;
					margin-top: 0;

					&:nth-child(3) ~ * {
						margin-top: rem($space-xxxlarge);
					}
					& + .author-box {
						margin-top: 0;
					}
				}
			}
		}
	}

	&--offset.grid-item {
		@include mq($from: screen-sm) {
			padding-left: 8.33333%;
		}
		@include mq($until: screen-md) {
			margin-left: 0;
		}
		@include mq($from: screen-md) {
			padding-left: 0;
		}
	}

	&:not(.grid-item) {
		margin-top: rem($space-xlarge);
		@include mq($from: screen-xs) {
			margin-right: 0;

			.contact {
				position: relative;

				img {
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
		@include mq($from: screen-sm-plus) {
			margin-right: 0;
		}
		@include mq($from: screen-md) {
			.contact {
				padding-left: rem(100);
			}
		}
		#{$module}__inner {
			@include mq($from: screen-md) {
				padding: rem($space-xxlarge);
			}
		}
	}
	&--offset:not(.grid-item) {
		@include mq($from: screen-sm) {
			padding-left: 7.5%;
			padding-right: 7.5%;
		}
		@include mq($from: screen-sm-plus) {
			padding-right: 0;
			margin-right: 38%;
		}

		.page-aside__inner {
			max-width: none;
		}
	}

	&--full {
		img {
			max-width: rem(400);
			width: 100%;
			height: auto;
		}
		#{$module}__inner {
			background-color: transparent;

			padding: 0;
			@include mq($from: screen-sm) {
				padding: 0;
			}
			@include mq($from: screen-md) {
				padding: 0;
			}
		}
	}

	> .button {
		margin-top: rem($space-large);
	}
}
