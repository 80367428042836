//JPL: All from Codehouse, migrated or custom components

table.facts {
	@include theme-background-color('primary-5');

	th {
		&.facthead {
			@include theme-background-color('primary-5');
			font-size: rem(22);
			line-height: rem(30);
			font-weight: 700;
			letter-spacing: 0;
			padding: rem($space-xxlarge) rem($space-xxlarge) 0 rem($space-xxlarge);

		}
	}
	td {
		border: none;

		&.factcontent {
			padding: rem(24) rem($space-xxlarge) rem($space-xxlarge);

			p {
				margin-bottom: rem($space-medium);
			}
		}
	}
}

.uvm_table {
	border: rem(2) solid $color-grey-4;

	th {
		padding: rem($space-medium) rem(35);
		background: $color-grey-5;
		font-size: rem(18);
		font-weight: 700;
		margin-bottom: 0;
		color: $color-black;

		strong, p {
			font-size: inherit;
			font-weight: inherit;
			color: inherit;
			margin-bottom: 0;
		}
	}

	td {
		padding: rem(15) rem(35);
		font-size: rem(17);
		color: $color-grey-2;
		width: auto !important;

		p {
			margin: 0;
			font-size: rem(17);
			color: inherit;
		}

		ul {
			li {
				font-size: inherit;
				color: inherit;
			}
		}
	}
}



/* Search result marker */
#search-results-container {
	mark {		
		.theme-stuk &
		{
			background-color: $color-rose!important;
		}				
		.theme-stil &
		{
			background-color: $color-mabel!important;
		}			
		.theme-uvm &
		{
			background-color: $color-tranquil!important;
		}
	}
}

/* KSO: Add2Any custom styling */
.a2a_svg, .a2a_count {
	border-radius: 0 !important;
}

.a2a_menu {
	border-radius: 0 !important;
	border: 0.0625rem solid #828a8f !important;
	margin-top: 2px !important;

	a {
		color: #535e63 !important;
	}
}


.error {
	color: $color-error;
	background-color: $color-red;
	padding: rem($space-small);
}

.twitterwidget {
	background-color: transparent !important;
}

.facebookwidget {
	width: 100%;
	background-color: transparent !important;
	text-align: center;
}
