.contact-card {
	$module: '.contact-card';
	position: relative;
	display: block;
	padding: rem($space-medium) rem($space-xsmall);
	box-sizing: border-box;
	background: $color-white;
	text-align: center;

	@include mq($from: screen-sm) {
		padding: rem($space-medium);
	}

	@include mq($from: screen-lg) {
		padding: rem($space-xlarge);
	}

	@at-root a#{&} {
		&:hover {
			transition: background 0.2s ease-out;
			@include theme-background-color('primary-5');
		}
	}

	.button--icon {
		width: rem(24);
		height: rem(24);
		position: absolute;
		top: rem($space-medium);
		right: rem($space-medium);

		.svg-icon {
			width: rem(24);
			height: rem(24);
			fill: $color-text;
		}

		@include mq($from: screen-lg) {
			top: rem($space-xlarge);
			right: rem($space-xlarge);
		}
	}


	hr {
		width: 30%;
		border-top: rem(1) solid $color-grey-4;
		margin: rem($space-xlarge) auto;
	}

	h4 {
		color: $color-text;
		font-size: rem(18);
		font-weight: 700;
		margin-bottom: rem($space-large);
	}
	h3 {
		@extend %headline-3;
		color: $color-text;
		margin-bottom: rem($space-large);
	}

	p {
		color: $color-grey-1;
		font-size: rem(14);
		margin-bottom: 0.25em;
		line-height: rem(24);

		strong {
			font-weight: 700;
			color: $color-text;
		}
	}

	&--simple {
		@include mq($from: screen-lg) {
			padding: rem($space-xxxlarge) rem($space-xlarge);
		}
		#{$module}__content {
			@include mq($from: screen-sm) {
				height: auto;
			}
		}
	}

	&--full {
		height: 100%;
	}

	&__content {
		position: relative;
		overflow: hidden;
		height: 360px;
	}

	&__image {
		display: inline-block;
		width: rem(100);
		height: rem(100);
		overflow: hidden;
		border-radius: 50% 50%;
		margin-bottom: rem($space-large);
		background-size: cover;
		background-position: center center;

		img {
			width: rem(100);
			height: rem(100);
			opacity: 0;
			display: block;
		}
	}

	&__social {
		display: inline-block;
		width: 80%;

		li {
			margin: 0 rem($space-xxsmall);
			display: inline-block;
		}
	}

	&__contact-info {
		display: inline-block;
		@include mq($from: screen-sm) {
			width: 80%;
		}

		li {
			text-align: left;
			color: $color-grey-1;
			font-size: rem(14);

			> span {
				position: relative;
				padding-left: rem(70);
				line-height: rem(24);
				vertical-align: top;
				display: block;

				> span {
					position: absolute;
					top: 0.3em;
					left: 0;
					display: inline-block;
					line-height: 1;
				}
			}
		}
	}

	&__logout-wrapper {
		display: block;
		text-align: center;

		@include mq($from: screen-sm) {
			position: absolute;
			left: 0;
			right: 0;
			bottom: rem($space-medium);
		}

		@include mq($from: screen-lg) {
			bottom: rem($space-xlarge);
		}

		margin-top: rem(20);


		@include theme-color('primary-1')

		.button--link-caps {
			display: inline-block;
			.text, .icon {
				font-size: rem(10);
			}
			.text { color: currentColor; }
			.svg-icon { fill: currentColor; }
		}
	}

	.grid & {
		@include mq($from: screen-sm) {
			margin-bottom: rem($space-xlarge);
		}
	}
}
