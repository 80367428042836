// Buttons
//
// DOD: done
//
// Buttons in the CVI all use the `.button` class as default, and specifies further out with additional classes. `.button` is also used as a wrapper whenever an element is interactable.<br/>
// When there is JavaScript integration, a `.js--XXXX`type class is added as a hook. No styling applies to those classes.<br/>
// The buttons can be rendered with a set of modifiers.
//
// Weight: 1
//
// Style guide: elements.button


// Large button
//
// DOD: done
//
// The large button has comes in all three theme colors and always has an animated arrow. There is no default state, as a theme is implied.
//
// .button--large.theme-uvm				- Large button type, uvm theme
// .button--large.theme-stil			- Large button type, uvm theme
// .button--large.theme-stuk			- Large button type, uvm theme
//
// Markup: ../../templates/prototype/partials/elements/button.twig
//
// Weight: 2
//
// hidedefault: true
//
// Style guide: elements.button.large


// Medium button
//
// DOD: done
//
// The medium sized button comes in a default and an inverted state.
//
// .button--medium								- Medium button type
// .button--medium.has-icon						- Medium button type with icon
//
// Markup: ../../templates/prototype/partials/elements/button.twig
//
// Weight: 3
//
// hidedefault: true
//
// Style guide: elements.button.medium


// Medium button inverted
//
// DOD: done
//
// Button medium in the inverted state without and with icon.
//
// .button--medium.button--medium--inverted				- Medium button type, inverted||background-color:#000;padding:10px;
// .button--medium.button--medium--inverted.has-icon	- Medium button type, inverted with icon||background-color: #000;padding:10px;
//
// Markup: ../../templates/prototype/partials/elements/button.twig
//
// Weight: 4
//
// hidedefault: true
//
// Style guide: elements.button.medium.inverted

// Small button
//
// DOD: done
//
// Small buttons come in both default and themed variations but with no inverted state.
//
// .button--small									- Small button type, default state
// .button--small.has-icon							- Small button type, default state with icon
// .button--small.button--small--theme.theme-uvm	- Small button type, uvm theme
// .button--small.button--small--theme.theme-stil	- Small button type, uvm theme
// .button--small.button--small--theme.theme-stuk	- Small button type, uvm theme
//
// Markup: ../../templates/prototype/partials/elements/button.twig
//
// Weight: 5
//
// hidedefault: true
//
// Style guide: elements.button.small


// Link button
//
// DOD: done
//
// Link buttons are regular text links with an optional icon.
//
// .button--link 						- link styling, default
// .button--link.button--link-heavy		- link styling heavy, used for navigation buttons
// .button--link.button--link-caps		- link styling caps, used for Kanalen
//
// Markup: ../../templates/prototype/partials/elements/button.twig
//
// Weight: 6
//
// hidedefault: true
//
// Style guide: elements.button.link


// Icon button
//
// DOD: done
//
// Icon buttons are single icons operating like buttons. They can't have text.
//
// .button--icon 						- default class
// .button--icon.button--bubble			- inside circle/bubble
//
// Markup: ../../templates/prototype/partials/elements/button-icon.twig
//
// Weight: 7
//
// hidedefault: true
//
// Style guide: elements.button.icon


// Rounded button
//
// DOD: done
//
// Rounded buttons are normal buttons with round edges.
//
// .button--rounded.button--medium 		- rounded
//
// Markup: ../../templates/prototype/partials/elements/button.twig
//
// Weight: 7
//
// hidedefault: true
//
// Style guide: elements.button.rounded


// Button modifiers
//
// DOD: done
//
// Buttons can be disabled with the `is-disabled` class.
//
// .button--large.theme-uvm.is-disabled						- Large button type, uvm theme, disabled version
// .button--medium.is-disabled								- Medium button type, disabled version
// .button--medium.button--medium--inverted.is-disabled		- Medium button type, inverted anddisabled version
// .button--small.is-disabled								- Small button type, disabled version
// .button--small.theme-uvm.is-disabled						- Small button type, uvm theme, disabled version
//
// Markup: ../../templates/prototype/partials/elements/button.twig
//
// Weight: 8
//
// hidedefault: true
//
// Style guide: elements.button.modifiers

%button-text {
	font-size: rem(12);
	letter-spacing: 0.1em;
	text-transform: uppercase;
	font-weight: 700;
}

.button {
	position: relative;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	line-height: 1.5;
	transition: all 0.2s ease-out;
	//overflow: hidden; //JPL: Necessary???
	vertical-align: middle;

	.text,
	.icon {
		line-height: 1em;
		display: inline-flex;
		vertical-align: middle;
		position: relative;
		transition: all 0.2s ease-out;
		margin: 0;
		padding: 0;
	}

	.svg-icon {
		height: 0.75em;
		width: 0.75em;
		vertical-align: middle;
		display: inline-block;
		fill: $color-grey-2;
		transition: fill 0.2s ease-out;
	}
	&:active {
		outline: 0;
	}
	.icon {
		display: none;
	}

	&.has-icon {
		.icon {
			display: inline-flex;
		}
	}

	&--white-text {
		color: $color-white;
		font-size: .75rem;
		letter-spacing: .1em;
		text-transform: uppercase;
		font-weight: 700;
	}


	&--large {
		text-align: center;

		padding: rem(20) rem(30);

		@include mq($from: screen-sm) {
			display: inline-block;
			padding: rem(20) rem(50);
			min-width: rem(220);
		}

		box-sizing: border-box;
		background: $color-grey-4;
		transition: background-color 0.3s ease-out;
		@include theme-background-color('primary-1');

		.text {
			line-height: rem(20);
			color: $color-white;
			@extend %button-text;
		}
		.icon {
			line-height: rem(20);
			transition: transform 0.3s ease-out;
			transform: translateX(10px);
			overflow: hidden;

			.svg-icon {
				transition: transform 0.3s ease-out;
				transform: translateX(-10px);

			}
		}
		.svg-icon {
			height: rem(12);
			width: rem(26);
			fill: $color-white;
		}

		&:hover {
			@include theme-background-color('primary-2');
			.svg-icon {
				transform: translateX(0px);
			}
			.icon {
				transform: translateX(10px);
			}
		}

		&.is-disabled {
			padding: rem(20) rem(50);
			cursor: default;
			background: $color-grey-4;
			.text {
				color: $color-grey-2;
			}
			.icon {
				display: none;
			}
			&:hover {
				background: $color-grey-4;
				.text {
					color: $color-grey-2;
				}
			}
		}
	}


	&--medium {
		border: rem(1) solid $color-grey-2;
		text-align: center;
		box-sizing: border-box;
		transition: all 0.3s ease-out;

		padding: rem(15) rem(30);

		@include mq($from: screen-sm) {
			display: inline-block;
			padding: rem(15) rem(45);
		}


		.text {
			line-height: rem(18);
			transition: color 0.3s ease-out;
			color: $color-grey-1;
			@extend %button-text;
		}
		.svg-icon {
			margin-left: 0.25em;
			fill: $color-grey-1;
		}
		.icon {
			line-height: rem(18);
			display: none;
			overflow: hidden;
			transition: transform 0.3s ease-out;
			transform: translateX(10px);

			.svg-icon {
				transition: all 0.3s ease-out;
				margin-left: 0;
				height: rem(12);
				width: rem(26);
				transform: translateX(-10px);
			}
		}


		&:hover {
			border: rem(1) solid $color-black;
			background-color: $color-black;
			.text {
				color: $color-white;
			}
			.svg-icon {
				transform: translateX(0px);
				fill: $color-white;
			}
			.icon {
				transform: translateX(10px);
			}
		}


		&--inverted {
			border: rem(1) solid $color-white;

			.text {
				color: $color-white;
			}
			.icon {
				.svg-icon {
					fill: $color-white;
				}
			}

			&:hover {
				border: rem(1) solid $color-white;
				background-color: $color-white;

				.text {
					color: $color-black;
				}
				.svg-icon {
					fill: $color-black;
				}
			}
		}

		&.is-disabled {
			padding: rem(15) rem(45);
			cursor: default;
			background: $color-grey-4;
			border: none;
			.text {
				color: $color-grey-2;
			}
			.icon {
				display: none;
			}
			&:hover {
				background: $color-grey-4;
				.text {
					color: $color-grey-2;
				}
			}
		}

		&--theme {
			border: none;
			@include theme-background-color('primary-1');

			.text {
				color: $color-white;
			}

			&:hover {
				border: none;
				@include theme-background-color('primary-2');

				&.is-disabled {
					.text {
						color: $color-grey-2;
					}
				}
			}
		}
	}


	&--small {
		box-sizing: border-box;
		padding: rem(10) rem(20);
		min-width: rem(100);
		border: rem(1) solid $color-grey-2;
		text-align: center;
		color: $color-grey-7;
		transition: all 0.3s ease-out;

		display: inline-flex;
		justify-content: center;
		flex-direction: row-reverse;

		&.has-icon {
			.icon {
				position: static;
				display: inline-flex;
				margin-right: 0.5em;
			}
		}


		.text {
			line-height: rem(18);
			transition: color 0.3s ease-out;
			@extend %button-text;
			color: currentColor;
		}
		.icon {
			display: none;
			position: absolute;
			top: rem(18);
			right: rem(35);
			overflow: hidden;

			.svg-icon {
				width: 1em;
				height: 1em;
				fill: currentColor;
			}
		}
		&:hover {
			border: rem(1) solid $color-black;
			background-color: $color-black;


			.svg-icon {
				transform: translateX(0px);
			}
		}

		&.is-disabled {
			cursor: default;
			background: $color-grey-4;
			border: none;
			.text {
				color: $color-grey-2;
			}
			.icon {
				display: none;
			}
			&:hover {
				background: $color-grey-4;
				.text {
					color: $color-grey-2;
				}
			}
		}

		&--theme {
			border: none;
			@include theme-background-color('primary-1');
			color: $color-white;

			&:hover {
				border: none;
				@include theme-background-color('primary-2');

				&.is-disabled {
					.text {
						color: $color-grey-2;
					}
				}
			}
		}
	}

	&--icon {
		width: rem($space-large);
		height: rem($space-large);
		position: relative;

		.icon {
			display: inline-flex;
			width: rem($space-large);
			height: rem($space-large);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		.svg-icon {
			height: 1em;
			width: 1em;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			transition: fill 0.2s ease-out;
		}

		&:hover {
			.svg-icon {
				fill: $color-grey-4;
			}
		}

		&.button--bubble {
			padding: rem($space-xxsmall);
			background: $color-grey-5;
			border-radius: 50% 50%;
			transition: background 0.2s ease-out;

			&:hover {

				@include mq($from: screen-sm) {
					background: $color-black;
					.svg-icon {
						fill: $color-white;
					}
				}
			}
		}
	}



	&--link {
		color: currentColor;

		@include clearfix;
		font-size: rem(16);
		padding: rem($space-xxxsmall);

		.text, .icon {
			display: inline-flex;
			line-height: 1;
			vertical-align: bottom;
		}
		.text {
			transition: color 0.1s ease-out;
			color: $color-grey-1;
		}
		.text + .icon {
			margin-left: 0.3em;
		}
		.icon {

			transition: transform 0.3s ease-out;
			overflow: hidden;

			.svg-icon {
				height: 0.75em;
				width: 1.625em;
				fill: $color-grey-1;
				transform-origin: bottom center;
				transform: translateX(-0.625em);

				transition: all 0.3s ease-out;
				.rich-text &{
					transform: translateX(0);
				}
			}
		}
		&:hover {
			.text {
				color: $color-grey-2;
			}
			.svg-icon {
				fill: $color-grey-2;
				transform: translateX(0px);
			}

		}

		&-heavy {
			border-bottom-width: rem(2);

			border-style: solid;

			transition: all 0.1s ease-out;

			@include theme-border-color('primary-1');

			.text {
				font-size: 1.125em;
				font-weight: 400;
				@include theme-color('primary-1');
			}


			&:hover {
				@include theme-border-color('primary-3');
				.text {
					@include theme-color('primary-3');
				}
				.svg-icon {
					@include theme-fill('primary-3');
				}
			}
		}

		&-caps {
			.text {
				@extend %button-text;
			}
			&:not(.has-icon) .icon {
				display: none;
			}
		}
		&-reverse {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			.icon {
				transform: scaleX(-1);
				margin: 0;
				height: 1.25em;
				.svg-icon {
					fill: $color-grey-2;
					height: 1em;
				}
			}
			.text {
				color: $color-grey-2;
				margin-left: 0.5em;
				line-height: 1em;
			}
			&:hover {
				.svg-icon {
					transform: translateX(-0.625em);
					fill: $color-grey-4;
				}
				.text {
					color: $color-grey-4;
				}
			}
			@include mq($until:screen-sm) {
				padding: 0;
				.text {
					display: none;
					+ .icon {
						margin: 0;
					}
				}
			}
		}

	}


	&--toggle {
		display: block;
		padding: rem($space-xsmall);
		@include theme-background-color('primary-1');
		border: rem(1) solid $color-white;
		text-align: center;

		.text {
			color: $color-white;
		}
		.icon {
			width: 2em;
			height: 2em;
			margin-right: 0.5em;

			.svg-icon {
				fill: $color-white;
				transform: translate(0,0);
				width: 2em;
				height: 2em;
			}
		}

		&:hover:not(.is-active) {
			@include theme-background-color('primary-2');
		}
		&:hover.is-active {
			.text {
				@include theme-color('primary-1');
			}
			.svg-icon {
				@include theme-fill('primary-1');
			}
		}
		&.is-active {
			background: $color-white;
			.text {
				color: $color-text;
			}
			.svg-icon {
				fill: $color-text;
			}
		}
	}

	&--rounded {
		border-radius: 30px;
	}

	&--white {
		background-color: $color-white;
		border-color: $color-white;
		border: none;
		color: $color-text;

		&:hover {
			border: none;
			background-color: $color-black;
			color: $color-white;
		}
	}
}
