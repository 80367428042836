// Meta Text
//
// DOD: done
//
// Small text for meta information, dates, tags.
//
// Weight: 1
//
// Markup: <span class="meta"><strong class="type">Nyhed</strong>&nbsp;&nbsp;&middot;&nbsp;&nbsp;13. Juli 2016</span></span>
//
// Style guide: elements.meta



.meta {

	// JPL: Codehouse Integration
	display: block;
	color: $color-grey-1;
	font-size: rem(13);
	letter-spacing: 0.06em;
	font-weight: 400;
	line-height: 1em;
	margin: 0 0 rem($space-xsmall) 0;

	strong {
		color:$color-grey-1;
		text-transform: uppercase;
	}
}
