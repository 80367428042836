
.cookie-compliance {
	position: relative;
	display: none;


	&--fixed {

		position: fixed;
		bottom:-100%;

		left:0;
		right:0;
		z-index:8000;

		&.is-active {
			bottom:0;
		}
	}

	background-color: $color-grey-6;
	padding: rem($space-xsmall) rem($space-xsmall) rem($space-small) rem($space-xsmall);

	opacity:0;
	transform-origin: bottom center;
	max-height: 0;
	overflow: hidden;
	transition: all 0.3s ease;
	padding-top: 0;
	padding-bottom: 0;

	@include mq($from: screen-sm){
		padding: rem($space-medium) rem($space-xsmall) rem($space-medium) rem(60);
		padding-top: 0;
		padding-bottom: 0;
	}

	&.is-active {
		display: block;
		max-height: rem(200);
		opacity: 1;
		padding: rem($space-xsmall) rem($space-xsmall) rem($space-small) rem($space-xsmall);
		@include mq($from: screen-sm){
			padding: rem($space-medium) rem($space-xsmall) rem($space-medium) rem(60);
		}
	}


	&__inner {
		@include mq($from: screen-xs){
			padding-right:rem(120);
		}
	}

	h3,
	p,
	a:not(.button) {
		color: $color-text;
	}
	h3 {
		font-weight: normal;
		font-size: rem(18);
	}
	p {
		max-width: rem(800);
		margin-top: rem($space-xsmall);
		font-size: rem(14);
		a {
			color: $color-text;
			text-decoration: underline;
		}
	}
	.button {
		margin-top: rem(20);
		@include mq($from: screen-xs){
			margin:0;
			position: absolute;
			top:50%;
			right:0;
			transform:translateY(-50%);
		}
	}
}
