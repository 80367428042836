.abstract-list {
	&__item {
		cursor: default;
		padding: rem($space-xlarge) 0;
		display: block;
		border-bottom: rem(1) solid $color-grey-6;

		&:last-child {
			border-bottom: 0;
		}

		&__inner {
			position: relative;

			@include mq($from: screen-sm) {
				padding: 0 rem($space-small);
				padding-left: rem(200);
				min-height: rem(180);
			}
			@include mq($media-type: print) {
				padding: 0 rem($space-small);
				padding-left: rem(200);
				min-height: rem(180);
			}
			@include mq($from: screen-lg) {
				padding-left: rem(230);
			}

			a:not(.button) {
				@extend %general-link;
			}
		}

		&__inner > .button {
			position: static;
			overflow: hidden;

			@include mq($from: screen-sm) {
				display: block;
				margin-bottom: 0.3em;
			}
			&:hover {
				h4 {
					@include theme-color("primary-1");
					.theme-trivsel &
					{
						color: $color-black!important;				
					}
					.svg-icon {
						@include theme-fill("primary-1");
					}
				}
				.image {
					opacity: 0.9;
				}
			}

			&.focus-visible .image {
				box-shadow: 0 0 rem(2) rem(2) blue;
			}
		}

		.js--reveal {
			&.is-active {
				.gradient-overlay {
					opacity: 0;
				}
			}
		}

		&--reveal {
			.div-with-gradient {
				position: static;
				overflow: hidden;

				.page-article {
					height: 6em;

					p {
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
			
			.remove-read-more {
				// JS checks if content is less than 500 characters
				.div-with-gradient {
					.page-article {
						height: auto;
					}
					.gradient-overlay {
						display: none;
					}
				}
				.trigger-button {
					display: none;
				}
			}

			.gradient-overlay {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				transition: opacity 1000ms ease;
				background: linear-gradient(transparent 50%, $color-white);
				pointer-events: none;
			}
			.trigger-button {
				line-height: 3;
				color: #00647d;
				border: none !important;
				font-family: var(--font-primary);
				text-decoration: underline;
				position: relative;
				z-index: 1;
			}
			.meta {
				display: flex;
				flex-direction: column;
				gap: rem(12);
				margin-bottom: rem(30);
				@include mq($from: screen-sm) {
					gap: 0;
					flex-direction: row;
				}
			}
			.line {
				display: none;
				@include mq($from: screen-sm) {
					display: inline;
				}
			}
		}

		.event {
			> span {
				display: block;
				line-height: 1.8em;
			}
			strong {
				text-transform: uppercase;
				display: inline-block;
				min-width: rem(70);
				font-weight: 600;
				font-size: 0.9em;
				color: $color-grey-1;
			}
		}

		// JPL: Potentially outdated
		// :not(.button) .text {
		// 	display: block;
		// 	font-weight: 300;
		// 	line-height: 1.5em;
		// 	color:$color-black;
		// 	font-size:rem(20);
		// }

		.breadcrumbs {
			font-size: rem(13);
			padding-bottom: 0;
		}

		.pdf {
			font-size: 60%;
			line-height: rem(16);
			color: $color-grey-1;
			font-weight: 700;
			display: inline;
		}

		.image {
			margin-bottom: rem($space-small);
			background-color: $color-grey-6;
			background-position: center center;
			background-size: cover;
			min-width: rem(180);
			max-height: rem(180);
			height: rem(120);

			img {
				display: block;
				width: 100%;
				height: auto;
				// visibility:hidden;
				@include objectfit;
			}
			@include mq($media-type: print) {
				position: absolute;
				top: 0;
				left: 0;
				width: rem(180);
				height: rem(180);
				margin-bottom: 0;
			}
			@include mq($from: screen-sm) {
				position: absolute;
				top: 0;
				left: 0;
				width: rem(180);
				height: rem(180);
				margin-bottom: 0;
			}
			transition: opacity 0.4s ease-out;
		}

		h4 {
			@extend %headline-4;
			color: $color-black;
			transition: color 0.4s ease-out;
			padding-bottom: 0.5em;
			
			.theme-trivsel &
			{
				color: $color-smalt-trivsel;				
			}
			.icon {
				margin-left: rem($space-xxsmall);
				width: 1em;
				height: 1em;
				vertical-align: baseline;
			}
			.svg-icon {
				width: 0.6em;
				height: 0.6em;
				fill: $color-black;
			}
		}
		p {
			@extend %bodytext-lg;
			color: $color-grey-1;
		}

		&--portrait .image {
			float: left;
			min-width: 0;

			@include mq($from: screen-sm) {
				float: none;
				width: rem(120);
			}
			@include mq($media-type: print) {
				float: none;
				width: rem(120);
			}
		}

		&--no-border {
			border-bottom: none;
		}

		&--no-image .abstract-list__item__inner {
			@include mq($from: screen-sm) {
				padding: 0 rem($space-small) 0 0;
				min-height: 0;
			}
		}

		&--portrait .abstract-list__item__inner {
			@include mq($from: screen-sm) {
				padding: 0 rem($space-small);
				padding-left: rem(150);
				min-height: rem(180);
			}
			@include mq($media-type: print) {
				padding: 0 rem($space-small);
				padding-left: rem(150);
				min-height: rem(180);
			}
			@include mq($from: screen-lg) {
				padding-left: rem(180);
			}

			.image {
				@include mq($until: screen-sm) {
					display: none;
				}
			}

			//JPL: Screws with focus rect
			// .button {
			// 	display: inline;
			// }
		}
		&--date .image {
			min-width: rem(80);
			min-height: rem(80);
			width: rem(80);
			height: rem(80);

			font-size: rem(18);

			@include mq($from: screen-sm) {
				width: rem(90);
				height: rem(90);
				font-size: rem(20);
			}
			@include mq($from: screen-md) {
				float: none;
				width: rem(120);
				height: rem(120);
				font-size: rem(24);
			}
		}
		&--date .abstract-list__item__inner {
			@include mq($from: screen-sm) {
				padding: 0 rem($space-small);
				padding-left: rem(120);
				min-height: rem(120);
			}
			@include mq($from: screen-md) {
				padding-left: rem(150);
				min-height: rem(150);
			}
		}
		&--flex {
			@include mq($from: screen-sm) {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.abstract-list__item__inner {
				flex: 1 1 50%;
				padding: 0;
				@include mq($from: screen-sm) {
					padding: 0;
				}
			}

			> .button--medium {
				margin-top: rem($space-medium);
				@include mq($from: screen-sm) {
					margin-top: 0;
					margin-left: rem($space-medium);
				}
			}
		}

		&--highlighted {
			@include theme-background-color("primary-5");
			border-bottom: none;
			margin-bottom: 2px;
		}
	}

	&--no-border {
		border-bottom: none;
	}

	&--always-border {
		.abstract-list__item:last-child {
			border-bottom: rem(1) solid $color-grey-6;
		}
	}
	&--top-border {
		border-top: rem(1) solid $color-grey-6;
	}

	&--no-padding {
		.abstract-list__item--no-image .abstract-list__item__inner {
			padding-left: 0;
			@include mq($from: screen-lg) {
				padding-left: 0;
			}
		}
	}

	&--padded {
		@include mq($until: screen-sm) {
			.abstract-list__item {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

	.breadcrumbs {
		a {
			@include theme-color("primary-1");
			&:hover {
				@include theme-color("primary-2");
			}
		}
	}

	.tags {
		padding-top: 10px;
		span {
			font-size: 13px;
		}
	}
}
