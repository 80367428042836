.status-list {
	margin-top:rem($space-large);

	@include mq($from: screen-sm) {
		display: flex;
	}

	margin:0;

	&__item {
		margin-bottom:rem($space-xsmall);

		@include mq($from: screen-sm) {
			margin-bottom:0;
			flex:1;
			padding-right:rem($space-small);
		}
		h6 {
			font-family: $font-stack-primary;
			font-size:rem(16);
			font-weight: 700;
			line-height: 1.8;
		}
		p {
			font-family: $font-stack-primary;
			font-size:rem(16);
			font-weight: 400;
			color:$color-grey-1;
			line-height: 1.5;
		}
	}
}
