.page-article {
	position: relative;

	font-size: rem(18);

	h3 {
		//color: $color-black;
		@include theme-color('article-paragraph');
	}
	h1, h2, h3, h4, h5, h6{		
		.theme-trivsel &
		{				
			color: $color-smalt-trivsel;
			.js--accordion-trigger:hover & {
				color: $color-black!important;
			}
		}
	}
	p {
		line-height: 1.5;
	}

	form {
		margin-top: 1.5rem;

		h3 {
			margin-top: 3.5rem;
		}

		p + h3{
			margin-top: 0;
		}

		label {
			color: $color-pale-grey;
		}

		input[type=submit] {
			margin-top: 2.5rem;
		}
	}

	&--offset {
		@include mq($from: screen-sm) {
			//padding-left:8.33333%;
			//padding-right:8.33333%;
			padding-left: 7.5%;
			padding-right: 7.5%;
		}
		@include mq($from: screen-sm-plus) {
			padding-right: 0;
			&.rich-text {
				.box.box--table,
				.figure.figure--full {
					margin-right: 0;
				}

				> p,
				> ul,
				> ol,
				> h4,
				> blockquote,
				> iframe,
				> .figure,
				> .box,
				> .box.box--table--slim,
				> .rich-text,
				> .accordion__wrapper {
					margin-right: 38%;
				}
			}
		}
	}

	&--left-aligned {
		@include mq($from: screen-sm) {
			padding-left: 0;
		}
	}

	.accordion__title h4 {
		@extend %bodytext-lg;
	}

	&--small {
		font-size: rem(14);

		p,
		li {
			font-size: inherit;
			line-height: rem(24);
		}
		h4 {
			font-size: rem(18);
		}
		.box:not(.box--accordion) {
			h4 {
				font-size: rem(16);
				margin-bottom: rem($space-xxsmall);
			}
			@include mq($from: screen-sm) {
				&.links-list {
					padding: rem($space-small);

					ul {
						li:last-child {
							.button {
								padding-bottom: rem($space-xsmall);
							}
						}
					}
				}
			}
			.button--link {
				line-height: 1;
				padding: rem($space-xsmall) 0;
				.text {
					max-width: rem(500);
					font-size: rem(14);
					font-weight: 700;
				}
			}
		}
		p + p {
			margin-top: rem(-$space-xsmall);
		}

		& + .panel {
			margin: rem($space-large) 0;
		}

		th {
			@include mq($from: screen-md) {
				font-size: rem(16);
				padding: rem($space-xsmall) rem($space-small);
			}
		}
		td {
			@include mq($from: screen-md) {
				font-size: rem(15);
				padding: rem($space-xsmall) rem($space-small);
			}
		}
		.accordion {
			.accordion__title {
				padding-top: rem($space-xsmall);
				padding-bottom: rem($space-xsmall);
				h4 {
					font-size: rem(14);
				}
				.icon,
				.svg-icon {
					width: rem(18);
					height: rem(18);
				}
			}
		}
	}
}
