.deck {
	position: relative;
	@include clearfix;


	// JPL: Codehouse Integration
	&.ch--full--width {
		@include mq($from: screen-sm) {
			width: 100vw;
			left: 45%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
		}
	}

	&.js--is-sticky {
	}

	&.is-sticky {
		z-index: 8080;
		right: 0;
		left: 0;
	}

	// &[aria-labelledby="pageThreeColumnsSpotHeadline1"]:nth-of-type(even) {
	// 	.card.has-image{
	// 		.theme-stuk &
	// 		{
	// 			background-color: $color-maroon-light;
	// 		}				
	// 		.theme-stil &
	// 		{
	// 			background-color: $color-oracle;
	// 		}			
	// 		.theme-uvm &
	// 		{
	// 			background-color: $color-tidal-pool;
	// 		}
	// 	}
	// 	.card__text{
	// 		a h3, a p{
	// 			.theme-stuk &,
	// 			.theme-stil &,
	// 			.theme-uvm &
	// 			{
	// 				color: $color-white;
	// 			}
				
	// 		}			
	// 	}
		
	// }
}

.deck--white {
	@include theme-background-color('site-bg');

	margin: rem($vertical-gutter-mobile) 0;
	@include mq($from: screen-xs) {
		margin: rem($vertical-gutter-tablet) 0;
	}
	@include mq($from: screen-sm-plus) {
		margin: rem($vertical-gutter-desktop) 0;
	}
}

.deck--grey {
	background: darken($color-grey-6, 0%);

	padding: rem($vertical-gutter-mobile * 0.5) 0 rem($vertical-gutter-mobile);
	@include mq($from: screen-xs) {
		padding: rem($vertical-gutter-tablet * 0.5) 0 rem($vertical-gutter-tablet);
	}
	@include mq($from: screen-sm-plus) {
		padding: rem($vertical-gutter-desktop * 0.5) 0 rem($vertical-gutter-desktop);
	}

	& + .deck--grey {
		padding-top: 0;
		@include mq($from: screen-xs) {
			padding-top: 0;
		}
		@include mq($from: screen-sm-plus) {
			padding-top: 0;
		}
	}
}

.deck--theme {
	//@include theme-background-color('primary-5');
	@include theme-background-color('deck-theme');
			.theme-trivsel &
			{				
				background-color: $color-pink-trivsel;
			}
	&--dark {
		//@include theme-background-color('primary-4');
		@include theme-background-color('deck-theme-dark');		
		
	}
	
	padding: rem($vertical-gutter-mobile * 0.5) 0 rem($vertical-gutter-mobile);
	@include mq($from: screen-xs) {
		padding: rem($vertical-gutter-tablet * 0.5) 0 rem($vertical-gutter-tablet);
	}
	@include mq($from: screen-sm-plus) {
		padding: rem($vertical-gutter-desktop * 0.5) 0 rem($vertical-gutter-desktop);
	}

	& + .deck--theme {
		padding-top: 0;
		@include mq($from: screen-xs) {
			padding-top: 0;
		}
		@include mq($from: screen-sm-plus) {
			padding-top: 0;
		}
	}
}

.deck.deck--collapse-top {
	margin-top: 0;
	padding-top: 0;
}

.deck.deck--collapse-bottom {
	margin-bottom: 0;
	padding-bottom: 0;
}

.deck.deck--white .deck--white.area--page {
	// JPL: Codehouse Integration
	margin-bottom: 0;
}

.deck--half-background {
	// background: linear-gradient(to bottom, red 50%, transparent 50% 100%);
	@include theme-background-color-half('primary-1');
	padding: rem($vertical-gutter-mobile * 0.5) 0 rem($vertical-gutter-mobile);
	@include mq($from: screen-xs) {
		padding: rem($vertical-gutter-tablet * 0.5) 0 rem($vertical-gutter-tablet);
	}
	@include mq($from: screen-sm-plus) {
		padding: rem($vertical-gutter-desktop * 0.5) 0 rem($vertical-gutter-desktop);
	}
}
