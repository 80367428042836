// .grid-item { //JPL: WHY?? Codehouse requirement?? Does not work well with SH conventions
//     &.rich-text {
.topic-selector {
	// JPL: Codehouse Integration
	margin: 0;
	padding: 0;

	@include mq($from: screen-sm) {
		display: flex;
		justify-content: space-between;
	}

	&__item {
		position: relative;
		display: block;
		margin-bottom: rem($space-xxsmall);
		@include mq($from: screen-sm) {
			flex: 25% 1 1;
			margin: 0;
			margin-right: rem($space-large);
		}
		&:last-child {
			@include mq($from: screen-sm) {
				margin: 0;
			}
		}
		input {
			position: absolute;
			top: 0;
			left: -99999px;
			opacity: 0;

			&:checked + label {
				// @include theme-border-color('primary-2');
				@include theme-background-color('primary-5');
				h4 {
					@include theme-color('primary-1');
				}

				.checkmark {
					display: block;
				}
			}
			&.focus-visible + label {
				@extend %keyboard-focus;
			}
		}
		label {
			display: block;
			box-sizing: border-box;
			padding: rem($space-small);
			padding-left: rem($space-xxxlarge);
			border: rem(2) solid rgba(0, 0, 0, 0.2);
			// box-shadow: 0 0 0 rem(1) rgba(0, 0, 0, 0.2);
			margin: 0;
			@include mq($from: screen-sm) {
				text-align: center;
				height: rem(200);
				padding: rem($space-xxxlarge) rem($space-large);
			}
			.svg-icon {
				margin: 0 auto;
				@include theme-fill('primary-1');
				transition: all 0.1s ease-out;
				width: rem(36);
				height: rem(36);
				transform: scale(0.9);
				@include mq($from: screen-sm) {
					width: rem(52);
					height: rem(52);
				}
				@include mq($media-type:print) {
					width: rem(52);
					height: rem(52);
				}
			}
			.icon {
				display: block;
				text-align: center;
				margin-bottom: rem($space-xxsmall);
				position: absolute;
				top: 50%;
				left: rem($space-xxsmall);
				transform: translateY(-50%);
				@include mq($from: screen-sm) {
					position: static;
					transform: translate(0, 0);
					margin-bottom: rem($space-small);
				}
				@include mq($media-type:print) {
					position: static;
					transform: translate(0, 0);
					margin-bottom: rem($space-small);
				}
			}
			h4 {
				color: $color-black;
				font-weight: 500;
				text-transform: uppercase;
				transition: all 0.1s ease-out;
				font-size: rem(13);
				margin-bottom: rem(3);
				letter-spacing: 0.05em;
				// JPL: Codehouse Integration
				line-height: inherit;

				@include hyphenation;
				word-break: break-word !important;

				@include mq($from: screen-sm) {
					font-size: rem(14);
					text-align: center;
					margin-bottom: rem($space-xsmall);
				}
				@include mq($media-type:print) {
					font-size: rem(14);
					text-align: center;
					margin-bottom: rem($space-xsmall);
				}
			}
			&:active {
				border-color: $color-form-border;
			}
			&:hover {
				// JPL: Codehouse Integration
				border-color: inherit;
				@include mq($from: screen-sm-plus) {
					@include theme-border-color('primary-3');
				}
			}

			.checkmark {
				display: none;
				position: absolute;
				top: 0;
				right: 15px;
				width: 1.125rem;
				height: 1.125rem;

				.icon {
					transform: none;
				}
			}
		}
	}
}
//     }
// }
