.simple-table {
	margin:1rem 0;
	border:rem(1) solid $color-border;
	width:100%;
	table-layout:fixed;

	&__row {
		border-bottom:rem(1) solid $color-border;
		&--head {
			.simple-table__cell__inner {
				text-align: left;
				span {font-size:120%;}
			}
		}

		td:last-child {
			border-right:none;
		}
	}

	&__cell {
		border-right:rem(1) solid $color-border;

		&__inner {
			text-align: left;
			padding: rem(5);
			font-size: rem(16);
			line-height: rem(20);
		}
	}
}
.enhanced-table {
	display:block;
	margin:1rem 0;
	border:rem(1) solid $color-border;

	.button	 {
		display: block;

		.text {
			display: inline-block;
		}
		.icon,
		.svg-icon {
			width:1em;
			height:1em;
			font-size:rem(16);
			fill:$color-text-discreet;
		}

	}

	@include mq($from: screen-sm) {
		display: table;
		width: 100%;
	}
	@include mq($until:screen-sm) {
		tbody {
			display: block;
			width: 100%;
		}
	}

	&__row {
		display:block;
		border-bottom:rem(1) solid $color-border;
		padding:rem(10) 0;
		transition:all 0.2s ease-out;

		&--body{

			&:first-child {
				border-top:rem(1) solid $color-border;
			}

			&:hover {
				background:$color-grey-4;
			}
		}
		@include mq($from: screen-sm) {
			display: table-row;
			padding:0;
		}

		&--head {
			text-align: left;
			display: none;

			@include mq($from: screen-sm) {
				display: table-row;
			}

			.enhanced-table__cell__inner {
				text-align: left;
				padding:rem(10);
			}

			span {
				font-size:rem(12);
				text-transform: uppercase;
				color:$color-text-discreet;
			}
		}

		&--last {
			border:none;
		}
	}

	&__cell {
		display:block;
		vertical-align: top;
		margin-bottom:rem($space-xsmall);
		position: relative;

		&__inner {
			font-size: rem(16);
			line-height: rem(20);

			@extend %responsive-label;

			&:before {
				width:rem(90);
			}
			padding-left:rem(100);

			@include mq($from: screen-sm) {
				padding: rem(20) rem(10);
			}
			span {
				display: inline-block;
			}
		}

		@include mq($from: screen-sm) {
			margin-bottom: 0;
			display: table-cell;
		}
	}

	.button--icon {
		padding:0;

		.icon {
			float: left;
			@include mq($from: screen-sm) {
				float: none;
			}
		}
		&.button--link {
			margin-right: 1em;
			@include mq($from: screen-md) {
				float: right;
				padding: 0 0.3em;
				margin:0;
			}
		}
	}



}
