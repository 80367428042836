// Box
//
// DOD: done
//
// Container for article content. Either download links, notes, link lists and accordions. Shown below in UVM theme in different content variations.<br/>
// <strong>NOTE:</strong> Boxes only work get correct typographies with a `.rich-text` class.
//
// Markup: ../../templates/prototype/partials/elements/box.twig
//
// Style guide: elements.box

.theme-digitaliseringsdage .box--note{
	margin: auto;
	width:70%;
	max-width: 1170px;
}

.box {
	margin: rem($space-large) 0;
	@include mq($from: screen-sm) {
		margin: rem($space-xxlarge) 0;
	}
	position: relative;

	&.rich-text {
		p {
			margin-bottom: 2em;
		}
	}

	p {
		margin: 0 0 1em;
	}
	&:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	&__image {
		position: relative;
		display: block;
		max-height: rem(200);
		background-size: cover;
		background-position: center center;
		overflow: hidden;
		margin-bottom: rem($space-small);

		img {
			width: 100%;
			height: auto;
			visibility: hidden;
		}

		@include mq($from: screen-sm) {
			position: absolute;
			top: rem($space-xxlarge);
			left: rem($space-xxlarge);
			width: auto;
			max-width: rem(140);

			background: transparent;
			background-size: 0 0;
			img {
				visibility: visible;
			}
		}

		@include mq($from: screen-md) {
			max-width: rem(100);
		}
	}

	&--download {
		padding: rem($space-small) rem($space-small) rem($space-large);
		@include mq($from: screen-sm) {
			padding: rem($space-xxlarge) rem($space-xxlarge) rem($space-xxlarge)
				30%;
		}
		@include mq($from: screen-md) {
			padding: rem($space-xxlarge) rem($space-xxlarge) rem($space-xxlarge)
				rem(170);
		}
		@include theme-background-color("primary-5");

		.box__image {
			background-image: none !important;
			max-height: none;

			img {
				visibility: visible;
				width: auto;
				max-height: rem(200);
			}
		}
	}

	&--note {
		padding: rem($space-small);
		@include mq($from: screen-sm) {
			padding: rem($space-xxlarge);
		}
		//@include theme-background-color("primary-5");
		@include theme-background-color("content-box-note");

		h2,
		h4,
		h5 {
			color: $color-black;
		}
		.theme-trivsel &
		{				
				background-color: $color-pink-trivsel;
		}
	}

	&--table {
		border: rem(2) solid $color-grey-4;

		&--slim {
			.table-wrapper {
				overflow: auto;
			}
			.box__table-scroll {
				display: none;
			}
			&.box--fade {
				.table-wrapper {
					display: flex;
					table {
						border-right: 1px solid $color-grey-4;
					}

					//Adds extra width to scrollarea
					&:after {
						content: "";
						display: block;
						flex: 0 0 50px;
					}
				}
				.box__table-scroll {
					display: block;
					appearance: none;
					background-image: linear-gradient(
						to left,
						rgba(255, 255, 255, 1) 60%,
						rgba(255, 255, 255, 0) 100%
					);
					border: none;
					padding: 0;
					position: absolute;
					height: 100%;
					right: 0;
					width: 50px;
					&:before {
						content: "";
						display: block;
						position: absolute;
					}

					&:before {
						background-image: url(/svg/arrow-right-angle.svg);
						background-repeat: no-repeat;
						right: 10px;
						top: 50%;
						transform: translateY(-50%);
						z-index: 1;
						width: 15px;
						height: 15px;
						opacity: 0.5;
					}
				}
			}
		}
	}

	&--accordion {
		border: rem(2) solid $color-grey-4;
		.accordion {
			.accordion__title {
				padding-left: rem($space-small);
				padding-right: rem($space-small);
				.icon {
					right: rem($space-small);
				}
			}
			.js--accordion-content {
				padding-left: rem($space-small);
				padding-right: rem($space-small);

				p + p {
					margin-top: 0;
				}
			}
		}
	}

	&.links-list {
		border: rem(2) solid $color-grey-4;
		background: $color-white;
		padding: rem($space-small);

		h4 {
			color: $color-black;			
			.theme-trivsel &
			{				
				color: $color-black!important;
			}	
		}

		@include mq($from: screen-sm) {
			padding: rem($space-xlarge) rem($space-xlarge) rem($space-small);
		}
		ul {
			.button--link {
				display: block;
				max-width: 100%; //IE overflow fix
			}
			li:last-child {
				border: none;
				@include mq($from: screen-sm) {
					.button {
						padding-bottom: rem($space-small);
					}
				}
			}
		}
	}
}
