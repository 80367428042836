.search-results {
	position: relative;

	> p {
		margin:0 0 rem($space-large);
		padding-top: rem($space-small);
		@include mq($from: screen-sm) {
			padding-top: rem($space-xlarge);
		}
	}

	&--empty {
		> p {
			padding-bottom: rem($space-xxxlarge);
		}
	}


	&__footer {
		> * {
			display: inline-block;
		}
		@include mq($from: screen-sm) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.result-count {
		display: block;
		padding: rem($space-small) 0;

		.form__field {
			min-width: rem(200);
			max-width: rem(300);
			margin: 0;
		}

		& + .paging {
			@include mq($until:screen-sm) {
				padding-top: 0;
			}
		}
	}


}
