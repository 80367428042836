.keyword-create {
	margin: rem(1);
	border: rem(1) dashed $color-grey-4;
	height: rem(40);
	box-sizing: border-box;
	padding: rem($space-small);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-transform: uppercase;
	font-size: rem(10);
	font-weight: 700;
	color: $color-grey-1;
	transition: all 0.3s;

	.svg-icon {
		margin-left: rem($space-xsmall);
		fill: $color-grey-1;
		width: rem(12);
		height: rem(12);
	}
	&:hover {
		border: rem(1) dashed $color-text;
		color: $color-text;
		.svg-icon {
			fill: $color-text;
		}
	}
}
