.js--animated-reveal {
	&[data-animtype] {
		opacity: 0;
	}
	&[data-animtarget] {
		opacity: 1;
	}
}

.js--animated-reveal-child {
	opacity: 0;
}
