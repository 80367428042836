.check-list {
	background: $color-white;
	padding: rem($space-xlarge);
	.form__field,
	.form__label {
		margin-bottom: 0;
	}
	.form__field:not(:last-child) {
		margin-bottom: rem($space-medium);
	}
}
