// Status Controls
//
// DOD: done
//
// Small popout with edit and delete buttons
//
// .designguide-only		- Class for outer wrapper, ensure design guide visibility
//
// Weight: 1
//
// hidedefault: true
//
// Markup: ../../templates/prototype/partials/elements/status-controls.twig
//
// Style guide: kanalen.statuscontrols


.status-controls {
	position: absolute;
	top: rem(0);
	right: rem(-20);

	.designguide-only > & {
		transform: scaleY(1) translateY(#{rem(5)});
		display: inline-flex;
		position: static;
		opacity: 1;
	}

	ul {
		display: flex;
	}

	ul,
	ul li {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	a {
		display: block;
		font-size: rem(10);
		padding: rem(10) rem(4);
		transition: background 0.2s ease-out;


		.svg-icon {
			width: 2em;
			height: 2em;
			fill: $color-brand-UVM-1;
		}
		&:hover .svg-icon {
			fill: $color-brand-UVM-1 !important;
		}
	}
}
