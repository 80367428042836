// Hero
//
// DOD: done
//
// The hero block is used for RBL. Contains a background image, an optional topical icon and a text box with a button.
//
// .hero--offset					- Box is offset from viewport center
// .hero--unconfined				- No box, text directly on background
// .hero--ambience					- Only background image
//
// Markup: ../../templates/prototype/partials/blocks/hero.twig
//
// Style guide: blocks.hero



.hero {
	$module: '.hero';
	position: relative;

	background-size: cover;
	padding: rem(60) 0;

	&__inner {
		position: relative;

		padding: rem($space-medium);
		@include mq($from: screen-sm) {
			padding: rem($space-xxlarge);
		}

		//color: grey;
		//@include theme-color('primary-4');
		@include theme-color('hero-box-bg');
		.theme-trivsel &		
		{
			background: rgba($color-pink-trivsel, .95);
		}		
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &{
			color: $color-tranquil;
		}	
		

		&:before {
			content: '';
			display: block;
			opacity: 0.95;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: currentColor;
			z-index: 0;
			.theme-trivsel &			
			{
				content: none;
			}
		}		
		h1 {
			@extend %headline-1;
		}
		h2 {
			@extend %headline-2;
		}
		h1, h2 {
			position: relative;
			z-index: 1;
			//color: $color-text;
			@include theme-color('hero-text');
			@include hyphenation;
			
			.theme-trivsel &			
			{
				color: $color-green-rbl!important;
			}
			.theme-trivsel &,
			.theme-uvm &,
			.theme-stuk &,
			.theme-stil &,
			.theme-kanalen &
			{
				font-family: var(--font-primary);
				font-weight: 700;
			}	
		}
		p {
			position: relative;
			z-index: 1;
			//color: $color-text;
			@include theme-color('hero-text');
			margin-top: rem($space-medium);
			@extend %bodytext-lg;
			.theme-trivsel &,
			.theme-uvm &,
			.theme-stuk &,
			.theme-stil &,
			.theme-kanalen &
			{
				font-family: var(--font-primary);
				font-weight: normal;
			}	
		}
		.button {
			margin-top: rem($space-xlarge);
		}	
		
	}


	&--unconfined {
		padding: rem(60) 0;
		@include mq($from: screen-sm) {
			padding: rem(80) 0;
		}
		@include mq($from: screen-md) {
			padding: rem(100) 0;
		}
		@include mq($from: screen-xl) {
			padding: rem(120) 0;
		}

		#{$module}__inner {
			padding-top: 0;
			padding-bottom: 0;

			text-align: center;

			&:before {
				display: none;
			}
			h1, h2, p {
				color: $color-white;
			}
		}

		&:before {
			content: '';
			display: block;
			opacity: 0.4;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $color-black;
			z-index: 0;
		}
	}

	&--offset {
		padding: 0;
		min-height: rem(200);
		@include mq($from: screen-md) {
			padding-top: rem(80);
			min-height: rem(400);
			margin-bottom: rem(80);
		}
		@include mq($from: screen-sm) {
			display: flex;
			justify-content: flex-end;
		}


		#{$module}__inner {
			box-sizing: border-box;
			padding: rem($space-medium);

			@include mq($from: screen-xs) {
				padding: rem($space-large);
			}
			@include mq($from: screen-sm) {
				padding: rem($space-xlarge);
				width: 50vw;
			}
			@include mq($from: screen-md) {
				padding: rem(70);
				position: absolute;
				right: 0;
				bottom: rem(-80);
			}

			@include theme-color('secondary');

			&:before {
				opacity: 0.8;
				@include mq($from: screen-sm) {
					opacity: 1;
				}
			}

			h1, h2, h3, h4 {
				@include theme-color('primary-1');
			}
			p {
				max-width: rem(700);
			}
		}
	}


	&--ambience {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 20vh;

		@include mq($from: screen-md) {
			height: 30vh;
		}

		.icon {
			overflow: hidden;
			width: rem(70);
			height: rem(70);
			padding: rem(40);
			border-radius: 50% 50%;
			background-color: grey;
			@include theme-background-color('primary-4');
			display: flex;
			align-items: center;
			justify-content: center;

			.svg-icon {
				@include theme-fill('primary-1');
			}

		}
	}
}
