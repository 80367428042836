.keyword-edit {

	input {
		&:focus,
		&:active {
			border: rem(1) solid $color-grey-2;
		}

		&::-ms-clear, &::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}
	}


	&__title {
		position: relative;
		display: flex;
		justify-content: space-between;

		input {

			height: rem(42);
			box-sizing: border-box;
			border: rem(1) solid $color-grey-5;
			padding: rem($space-small) rem(50) rem($space-small) rem($space-small);
			text-transform: uppercase;
			font-size: rem(10);
			font-weight: 700;
			color: $color-text;
			width: 100%;

		}
		> .button {
			position: absolute;
			bottom: rem(1);
			right: rem(1);
			top: rem(1);
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(50);
			border: 0;
			background: $color-white;
			border-left: rem(1) solid $color-grey-5;
			transition: background 0.2s ease-out;

			.svg-icon {
				width: rem(20);
				height: rem(20);
			}

			&:hover {
				.svg-icon {
					fill: $color-text;
				}
			}
		}
	}

	&__link {
		margin-bottom: rem($space-xxxsmall);
		margin-top: rem($space-xxsmall);
		border: rem(1) solid $color-grey-5;
		height: rem(38);
		box-sizing: border-box;
		padding: rem($space-small);
		font-size: rem(12);
		color: $color-grey-1;
		width: 100%;

	}

}
