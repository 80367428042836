
// this grid should exist in union with modernizr compiled with no-flexbox check, in order for fallbacks to work in ie9.


// SORTHVID EDITS
@function calc-width($cols, $col){
	$number: ($col / $cols) * 100%;
	@return $number;
}
@function calc-width-gutter($cols, $col){
	$percent: ($col / $cols) * 100%;
	$halfgutter: $grid-gutter / 2;
	$halfgutter: to-length($halfgutter, "em");
	$percent: $percent - $halfgutter;
	@return $percent;
}

$halfgutter: $grid-gutter / 2;

// @mixin flex-item($mq, $col){
// 	@if ($mq == screen-sm) {
// 		//background-color: #ccc;
// 		@include mq($from: screen-sm){
// 			@include flex(1, 0, calc-width(12, $col));
// 		}
// 	} @else if ($mq == screen-md) {
// 		//background-color: green;
// 		@include mq($from: screen-md){
// 			@include flex(1, 0, calc-width(12, $col));
// 		}
// 	} @else if ($mq == screen-lg) {
// 		//background-color: yellow;
// 		@include mq($from: screen-lg){
// 			@include flex(1, 0, calc-width(12, $col));
// 		}
// 	} @else {
// 		@include flex(1, 0, calc-width(12, $col));
// 	}
// 	//@include flex(1, 0, (100% / $col)); // grow shrink basis(width)
// }

@mixin flexbox-std($wrap: wrap, $justify: flex-start){
	@include flexbox;
	@include flex-flow;
	@include align-items;
	@include align-content;
	@include flex-wrap($wrap);
	@include justify-content($justify); // like text-align
}
@mixin flexbox-std-fallback(){
	.no-flexbox & {
		display: block;
		font-size: 0;
		width: 100%;
	}
}
@mixin flex-item-fallback($width){
	.no-flexbox & { //fallback
		display: inline-block;
		//display: table-cell;
		width: $width;
		font-size: 16px;
		vertical-align: top;
	}
}
.grid { // flex-grid

	@include mq($from: screen-xs) {

		.no-flexbox & {
			@include flexbox-std-fallback();
		}

		@include flexbox;
		@include flex-flow;
		@include align-items;
		@include align-content;
		@include flex-wrap(wrap);
		//@include justify-content(flex-start);
		@include justify-content(space-between);

		margin-left: -1%;
		margin-right: -1%;
		> .grid-item {
			margin-left: 1%;
			margin-right: 1%;
			box-sizing: border-box;
		}
	}

	&.no-gutter {
		margin-left: auto;
		margin-right: auto;

		> .grid-item {
			margin-left: 0;
			margin-right: 0;
		}
	}
	&.no-wrap {
		@include flex-wrap(no-wrap);
	}
	&.align-center {
		@include align-content(center);
	}
	&.align-left {
		@include justify-content(flex-start);
	}
	&.align-spacebetween {
		// remember to set custom flex-basis when using this..
		@include justify-content(space-between);
	}
	&.align-spacearound {
		@include justify-content(space-around);
	}

	// roen = offset starts at 2, thinking that 1 isn't useful - saving some lines of css
	@for $i from 2 through $grid-columns - 1 {
		> .grid-item.col--#{$i} { // grid item width with no MQ
			//display: flex;
			@include flex(0, 1, calc-width(12, $i) - 2);
			@include flex-item-fallback(calc-width(12, $i) - 2);
		}

		&.no-gutter > .grid-item.col--#{$i} {
			@include flex(0, 1, calc-width(12, $i));
		}
	}
	> .grid-item.col--12 {
		@include flex(0, 1, 100%);
	}

	@for $i from 1 through $grid-columns {
		> .grid-item.col-offset--#{$i} {
		    margin-left: calc-width(12, $i);
		}
	}
	@for $i from 2 through $grid-columns {
		> .grid-item.col-xs--#{$i} {
			//display: flex;
			@include mq($from: 'screen-xs') {
				@include flex(0, 1, calc-width(12, $i) - 2);
				// .no-gutter & {
				// 	@include flex(0, 1, calc-width(12, $i));
				// }
				@include flex-item-fallback(calc-width(12, $i) - 2);
			}
		}
		&.no-gutter > .grid-item.col-xs--#{$i} {
			@include mq($from: 'screen-xs') {
				@include flex(0, 1, calc-width(12, $i));
			}
		}
	}
	@for $i from 1 through $grid-columns {
		@include mq($from: 'screen-xs') {
			> .grid-item.col-xs-offset--#{$i} {
			    margin-left: calc-width(12, $i);
			}
		}
	}
	@for $i from 2 through $grid-columns {
		> .grid-item.col-sm--#{$i} {
			//display: flex;
			@include mq($from: 'screen-sm') {
				@include flex(0, 1, calc-width(12, $i) - 2);
				// .no-gutter & {
				// 	@include flex(0, 1, calc-width(12, $i));
				// }
				@include flex-item-fallback(calc-width(12, $i) - 2);
			}
		}
		&.no-gutter > .grid-item.col-sm--#{$i} {
			@include mq($from: 'screen-sm') {
				@include flex(0, 1, calc-width(12, $i));
			}
		}
	}
	@for $i from 1 through $grid-columns {
		@include mq($from: 'screen-sm') {
			> .grid-item.col-sm-offset--#{$i} {
			    margin-left: calc-width(12, $i);
			}
		}
	}
	@for $i from 2 through $grid-columns {
		> .grid-item.col-md--#{$i} {
			//display: flex;
			@include mq($from: 'screen-md') {
				@include flex(0, 1, calc-width(12, $i) - 2);
				// .no-gutter & {
				// 	@include flex(0, 1, calc-width(12, $i));
				// }
				@include flex-item-fallback(calc-width(12, $i) - 2);
			}
		}
		&.no-gutter > .grid-item.col-md--#{$i} {
			@include mq($from: 'screen-md') {
				@include flex(0, 1, calc-width(12, $i));
			}
		}
	}
	@for $i from 1 through $grid-columns {
		@include mq($from: 'screen-md') {
			> .grid-item.col-md-offset--#{$i} {
			    margin-left: calc-width(12, $i);
			}
		}
	}
	@for $i from 2 through $grid-columns {
		> .grid-item.col-lg--#{$i} {
			//display: flex;
			@include mq($from: 'screen-lg') {
				@include flex(0, 1, calc-width(12, $i) - 2);
				// .no-gutter & {
				// 	@include flex(0, 1, calc-width(12, $i));
				// }
				@include flex-item-fallback(calc-width(12, $i) - 2);
			}
		}
		&.no-gutter > .grid-item.col-lg--#{$i} {
			@include mq($from: 'screen-lg') {
				@include flex(0, 1, calc-width(12, $i));
			}
		}
	}
	@for $i from 1 through $grid-columns {
		@include mq($from: 'screen-lg') {
			> .grid-item.col-lg-offset--#{$i} {
			    margin-left: calc-width(12, $i);
			}
		}
	}
	@for $i from 2 through $grid-columns {
		> .grid-item.col-xl--#{$i} {
			//display: flex;
			@include mq($from: 'screen-xl') {
				@include flex(0, 1, calc-width(12, $i) - 2);
				// .no-gutter & {
				// 	@include flex(0, 1, calc-width(12, $i));
				// }
				@include flex-item-fallback(calc-width(12, $i) - 2);
			}
		}
		&.no-gutter > .grid-item.col-xl--#{$i} {
			@include mq($from: 'screen-xl') {
				@include flex(0, 1, calc-width(12, $i));
			}
		}
	}
	@for $i from 1 through $grid-columns {
		@include mq($from: 'screen-xl') {
			> .grid-item.col-xl-offset--#{$i} {
			    margin-left: calc-width(12, $i);
			}
		}
	}
	// @for $i from 2 through $grid-columns {
	// 	> .grid-item.col-xxl--#{$i} {
	// 		//display: flex;
	// 		@include mq($from: 'screen-xxl') {
	// 			@include flex(0, 1, calc-width(12, $i) - 2);
	// 			// .no-gutter & {
	// 			// 	@include flex(0, 1, calc-width(12, $i));
	// 			// }
	// 			@include flex-item-fallback(calc-width(12, $i) - 2);
	// 		}
	// 	}
	// 	&.no-gutter > .grid-item.col-xxl--#{$i} {
	// 		@include mq($from: 'screen-xxl') {
	// 			@include flex(0, 1, calc-width(12, $i));
	// 		}
	// 	}
	// }
	// @for $i from 1 through $grid-columns {
	// 	@include mq($from: 'screen-xxl') {
	// 		.col-xxl-offset--#{$i} {
	// 		    margin-left: calc-width(12, $i);
	// 		}
	// 	}
	// }
}


.grid {
	+ .grid {
		margin-top: rem($grid-gutter/2);
		@include mq($from: screen-md) {
			margin-top: rem($grid-gutter);
		}
	}
	.grid-item {

		@include mq($from: screen-sm-plus) {
			&--padding-left {
				padding-left:rem($space-xxlarge);
			}
			&--padding-right {
				padding-right:rem($space-xxlarge);
			}
			&--padding-left-fat {
				padding-left:rem($space-xxxlarge);
			}
			&--padding-right-fat {
				padding-right:rem($space-xxxlarge);
			}
			&--padding-top {
				padding-top:rem($space-xxlarge);
			}
			&--margin-left {
				margin-left:rem($space-xxlarge);
			}
			&--margin-right {
				margin-right:rem($space-xxlarge);
			}
		}
	}
}

.grid:not(.grid--no-vertical-margin) {


	[class*="col-"] + [class*="col-"] {
		margin-top: rem($halfgutter);
	}

	@include mq($from: screen-xs ) {
		[class*="col-xs"] + [class*="col-xs"] {
			margin-top: 0;
		}
	}
	@include mq($from: screen-sm ) {
		[class*="col-sm"] + [class*="col-sm"] {
			margin-top: 0;
		}
	}
	@include mq($from: screen-md ) {
		[class*="col-md"] + [class*="col-md"] {
			margin-top: 0;
		}
	}

}
