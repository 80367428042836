.section-title {
	text-align:center;
	position: relative;
	margin:0 0 rem($space-large);

	> h6 {

		// JPL: Codehouse Integration
		margin-bottom: 0;

		position: relative;
		display: inline-block;
		@extend %sectionheadline;
		@include theme-background-color('site-bg');
		z-index: 3;
		max-width: 80%;
		padding: 0 0.5em;
		@include mq($from: screen-sm) {
			padding: 0 1em;
		}
	}
	.deck--grey & {
		h6 {
			background: $color-grey-5;
		}
	}

	.deck--theme & {
		h6 {
			@include theme-background-color('primary-5');
		}
	}
	.deck--theme--dark & {
		h6 {
			@include theme-background-color('primary-4');
		}
	}

	&:before {
		position: absolute;
		content: '';
		display: block;
		height: rem(2);
		background: $color-black;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		z-index: 1;
	}
}
.section-title-nobackground {
	&:before {
		background: $color-white;
	}

}
