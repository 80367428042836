// Issue
//
// DOD: done
//
// Full width element with title, paragraph, meta info and small button
//
// Markup: ../../templates/prototype/partials/blocks/issue.twig
//
// Style guide: blocks.issue



.issue {
	@include mq($from: screen-xs) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}


	h3 + .meta {
		margin-top: rem($space-large);
	}
	h3 {
		@extend %headline-4;
	}

	& + .issue {
		padding-top: rem($space-large);
		border-top: rem(1) solid $color-border-discreet;
		margin-top: rem($space-large);
	}
	.button {
		margin-top: rem($space-xsmall);
		@include mq($from: screen-xs) {
			margin: 0;
		}
	}

	&:last-child {
		border-bottom: rem(1) solid $color-border-discreet;
		padding-bottom: rem($space-large);
		margin-bottom: rem($space-xlarge);
	}
}
