// Shortcuts
//
// DOD: done
//
// Interactive elmenet for Kanalen with activator button and dropdown options menu
//
// .designguide				- Needed to show element in designguide
//
// hidedefault: true
//
// Markup: ../../templates/prototype/partials/elements/shortcuts.twig
//
// Style guide: kanalen.shortcuts



.shortcuts {
	$module: '.shortcuts';
	$shortcuts-button-width: 60;

	z-index: 50;

	transition: all 0.1s ease-out;
	transform: translateY(#{rem(-30)}) translateZ(0);

	&__inner {
		position: absolute;

		right: 0;
		top: 0;

		@include mq($from: screen-sm) {
			top: rem(10);
		}
		@include mq($from: screen-xl) {
			right: rem(-$shortcuts-button-width/2);
		}

	}



	.button--shortcuts {
		position: relative;
		width: rem($shortcuts-button-width);
		height: rem($shortcuts-button-width);
		border-radius: 50% 50%;
		@include theme-background-color('primary-1');
		box-shadow: 0 rem(1) rem(2) 0 rgba(0,0,0,0.25);

		color: $color-white;

		z-index: 4;

		.icon {
			width: rem(60);
			height: rem(60);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			svg circle{
				.theme-stuk &
				{
					fill: $color-maroon-light;
				}				
				.theme-stil &
				{
					fill: $color-oracle;
				}			
				.theme-uvm &
				{
					fill: $color-tidal-pool;
				}
				.theme-kanalen &
				{
					fill: $color-tidal-pool;
				}
			}
			.svg-icon {
				width: rem(60);
				height: rem(60);
				fill: $color-white;
			}
		}
		.icon2 {
			width: rem(30);
			height: rem(30);
			.svg-icon {
				width: rem(30);
				height: rem(30);
			}
		}

		.icon2 {
			display: none;
		}
		&.focus-visible {
			@extend %keyboard-focus;
			box-shadow: 0 0 rem(10) rem(5) blue;
		}
	}


	&.js-is-sticky {
		transform: translateY(0);
	}



	&__dropdown {
		position: absolute;
		width: rem(250);
		right: rem($shortcuts-button-width/2);
		top: rem($shortcuts-button-width/2);

		transform: scale(0);
		transform-origin: top right;

		display: none;

		transition: all 0.1s ease-out;
		z-index: 3;

		color: $color-white;

		h4 {
			@include theme-background-color('primary-1');
			position: relative;
			padding-top: rem(30);
			padding-left: rem(30);
			padding-right: rem(30);
			margin: 0;
			@extend %title;
			&:after {
				left: rem(30);
			}
		}
		ul {
			@include theme-background-color('primary-1');
			padding: rem(30) rem(30) 0;
		}

		li:not(:last-child) {
			margin-bottom: rem(10);
		}


		.button--link {
			font-size: rem(12);
			.svg-icon { fill: $color-white; }
			.text { color: $color-white; }
		}
		.button--link-caps {
			margin: rem(20) 0;
		}

		.link {
			box-sizing: content-box;
			display: block;
			border-top: rem(1) solid $color-white;
			border-bottom: 0;
			padding: rem(15) rem(30);
			font-size: rem(12);
			transition: background 0.2s ease-out;
			color: $color-white;

			.icon .svg-icon { fill: $color-white; font-size: rem(16); }
			.text {
				margin-left: 0.25em;
				display: inline-block;
				color: $color-white;
			}
			@include theme-background-color('primary-1');

			&:hover {
				@include theme-background-color('primary-2');
				.text { color: $color-white; }
				.icon .svg-icon {
					fill: $color-white;
				}
			}
		}

	}

	&.is-active {

		#{$module}__dropdown {
			transform: scale(1);
		}
		.button--shortcuts {
			.icon {
				display: none;
			}
			.icon2 {
				display: inline-block;
			}
		}
	}

	&.designguide {
		min-height: 400px;

		#{$module}__inner {
			position: relative;
			right: auto;
			top: auto;
		}
		#{$module}__dropdown {
			transform: scale(1);
			display: block;
			left: rem($shortcuts-button-width/2);
		}
		.button--shortcuts {
			.icon {
				display: none;
			}
			.icon2 {
				display: inline-block;
			}
		}
	}
}

//JPL: IE10+ hacks
@media all and (max-width: 1279px) and (-ms-high-contrast: none) {
	.shortcuts.js-is-sticky {
		right: rem(10);
	}
}
@media all and (min-width: 1280px) and (-ms-high-contrast: none) {
	.shortcuts.js-is-sticky {
		right: rem(40);
	}
}
