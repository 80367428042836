// Login Banner
//
// DOD: done
//
// The login banner is used for access to locked content
//
// .theme-stil-stat					- Banner in stilstat theme (only current application)
//
// Markup: ../../templates/prototype/partials/blocks/login-banner.twig
//
// hidedefault: true
//
// Style guide: blocks.loginbanner


.login-banner {
	padding: rem($space-small);
	@include theme-background-color('primary-5');

	@include mq($from: screen-sm) {
		display: flex;
		align-items: center;
		padding: rem($space-xlarge);
	}


	&__icon {
		display: none;
		margin-right: 1em;
		@include mq($from: screen-sm) {
			display: block;
		}

		.svg-icon {
			fill: $color-grey-1;
			width: rem(20);
			height: rem(20);

			@include mq($from: screen-sm) {
				width: rem(30);
				height: rem(30);
			}
		}
	}

	.button--subnav h4 {
		@extend %subheadline;

		@include mq($until: screen-sm) {
			font-size: rem(16);
		}
	}
}
