.page-layout {
	margin-top:rem($space-small);

	&.grid {
		margin-left:0;
	}

	> .accordion__wrapper {
		.accordion:first-child {
			border-top:none;
		}
	}

	&--panel {
		background: $color-white;
		padding: rem($space-medium) rem($space-xsmall);
		@include mq($from: screen-sm) {
			padding: rem($space-medium);
		}
		@include mq($from: screen-md) {
			padding: rem($space-xlarge);
		}
		@include mq($from: screen-lg) {
			padding: rem($space-xxxlarge);
		}
	}
}
