// Content Excerpt
//
// DOD: done
//
// Main list item for Kanalen frontpage. Shown inside a `.content-excerpt-list` element.
// Content excerpts can be div or anchor elements, depending on whether the item serves as link or has reactions with separate buttons.
// Can contain mood icon, image, trumpet, headline, bodytext, tags and reactions (likes and comments).<br>
// NOTE: Due to content complexity, the content excerpt can only be shown with simple content in the designguide. See <a href="/examples/pages_examples/content-excerpts">the example page</a> for variations.
//
// .content-excerpt--date					- With date-icon
// .content-excerpt--even-split				- 50/50 content division
// .content-excerpt--uneven-split			- 60/40 content division
// .content-excerpt--contact				- With room for a person image
// .content-excerpt--icon					- With room for a mood icon
// .content-excerpt--large					- With larger font size
//
// Weight: 1
//
// wrapperstyles: margin:10px 0;padding:10px 0;border-top:1px solid #eee;
//
// Markup: ../../templates/prototype/partials/elements/content-excerpt.twig
//
// Style guide: kanalen.contentexcerpt


.content-excerpt {
	$module: '.content-excerpt';

	display: block;
	position: relative;

	font-size: rem(24);

	h5 {
		color: $color-grey-1;
		margin-bottom: 0.5em;
		font-size: 0.5em;
	}
	h3 {
		margin-bottom: 0.1em;
		font-weight: 700;
		font-size: 0.5em;
		font-size: 0.65em;
		color: $color-text;
		transition: color 0.2s ease-out;
	}
	p {
		margin-top: 0.5em;
		font-size: 0.6em;
		color: $color-grey-1;

		> a {
			@include theme-color('primary-1');
		}

		strong {
			font-weight: bold;
		}
	}
	ol, ul {
		color: $color-grey-1;
		font-size: 0.5em;

	}
	ul {
		list-style: disc;
		li {
			margin-left: 2.1em;
		}
	}
	ol {
		list-style: decimal;
		margin-left: 1.2em;
		li {
			margin-left: 0.5em;
    		padding-left: 0.5em;
		}
	}

	.via {
		display: block;
		font-size: 0.5em;
		color: $color-grey-1;

		strong {
			font-weight: 700;
		}
	}

	.tags {
		line-height: 1.5;
		display: block;
		color: $color-grey-1;
		font-size: 0.5em;
	}

	.category {
		display: block;
		font-size: 0.5em;
		color: $color-grey-1;
		position: absolute;
		top: 0;
		right: 0;
	}

	.reactions {
		margin-top: 1em;
	}

	@at-root a#{&} {
		&:hover {
			h3 {
				@include theme-color('primary-1');
			}
		}
	}

	.news-item-header{
		.sticky-date-header{
			display: inline;
			width: 50%;
		}
		.sticky-news-tag{
			display: inline;
			width: 50%;
			small{
				font-size: 8px;
				text-transform: uppercase;
				background-color: #d3e7ec;
			}
		}
	}

	&__content {
		position: relative;
		> * + * {
			margin-top: 1em;
		}

		&__truncated {
			display: flex;
			flex-direction: row-reverse;
			align-items: flex-end;

			.content-excerpt__content--truncated {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

			}

			.excerpt-un-truncate {
				visibility: hidden;
				width: 60px;
				flex: 1 0 60px;
				position: relative;
				margin: 0;
				@include theme-color('primary-1');
				cursor: pointer;

				&:before {
					position: absolute;
					left: 0;
					top: 0;
					visibility: visible;
					content: attr(value);
					font-size: rem(10);
					@include theme-color('primary-1');
				}

				&:checked {
					display: none;
				}
			}

			.excerpt-un-truncate:checked + .content-excerpt__content--truncated {
				white-space: normal;
			}
		}


	}

	&__date {
		font-size: rem(16);

		display: block;
		width: rem(50);
		height: rem(50);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		@include theme-background-color('primary-4');
		@include theme-color('primary-1');
		text-transform: uppercase;

		.date {
			font-size: 1.125em;
		}
		.month {
			font-size: 0.625em;
		}
		@include mq($until: screen-sm) {
			margin-bottom: rem(10);
		}
		.theme-kanalen &{
			background-color: $color-gallery;
			color: $color-tidal-pool;
		}
	}


	&__image {
		margin-bottom: 0.5em;
		background-size: cover;
		background-position: center center;

		img {
			opacity: 0;
		}

		#{$module}:not(.content-excerpt--contact) & {
			height: 50vw;

			@include mq($from: screen-sm) {
				height: auto;
			}
		}

	}

	&__icon {
		width: rem(72);
		height: rem(72);

		.icon .svg-icon {
			display: inline-block;
			width: rem(72);
			height: rem(72);
			@include theme-fill('primary-1');
		}

	}


	&__content + &__image {
		margin-bottom: 0;
		margin-top: 0.5em;
	}

	.button--manage {
		@include theme-color('primary-1');
		font-size: 0.65em;
		position: absolute;
		top: 0;
		right: 0;
	}

	&--date {
		@include mq($from: screen-sm) {
			display: flex;
			justify-content: space-between;

			#{$module}__content {
				display: inline-block;
				width: calc(100% - #{rem(50+20)});
			}
		}
	}
	&--even-split {
		@include mq($from: screen-sm) {
			display: flex;
			justify-content: space-between;

			#{$module}__image,
			#{$module}__content {
				margin-bottom: 0;
				display: inline-block;
				width: calc(50% - #{rem(10)});
			}
		}

	}

	&--uneven-split {
		@include mq($from: screen-sm) {
			display: flex;
			justify-content: space-between;

			#{$module}__image,
			#{$module}__content {
				margin-bottom: 0;
				display: inline-block;
			}
			#{$module}__image {
				width: 40%;
			}
			#{$module}__content {
				width: calc(60% - #{rem(20)});
			}
		}
	}

	&--icon {
		@include mq($from: screen-sm) {
			display: flex;
			justify-content: space-between;

			#{$module}__content {
				width: calc(100% - #{rem(72 + 20)});
			}
		}
	}

	&--contact {
		#{$module}__image {
			width: rem(72);
			height: rem(72);
			overflow: hidden;
			border-radius: 50% 50%;
			img {
				width: rem(72);
				height: rem(72);
				display: block;
			}
		}

		@include mq($from: screen-sm) {
			display: flex;
			justify-content: space-between;

			#{$module}__image,
			#{$module}__content {
				margin-bottom: 0;
				display: inline-block;
			}
			#{$module}__content {
				width: calc(100% - #{rem(72 + 20)});
			}
		}

		&--slim {
			#{$module}__image,
			#{$module}__image img {
				width: rem(50);
				height: rem(50);
			}

			@include mq($from: screen-sm) {
				#{$module}__content {
					width: calc(100% - #{rem(50 + 20)});
					padding-top: rem($space-medium);
				}
			}
		}
	}

	&--large {
		font-size: rem(25);

		.tags {
			font-weight: 700;
		}

		&#{$module}--contact {

			#{$module}__image {
				width: rem(90);
				height: rem(90);
			}
			@include mq($from: screen-sm) {
				#{$module}__content {
					width: calc(100% - #{rem(90 + 20)});
				}
			}
		}
	}

	& + .page-article {
		margin-top: rem($space-xlarge);
	}
}
