.search-sort {
  display: flex;
  white-space: nowrap;

  > div {
    margin: rem(20) rem(20) rem(20) 0;

    @include mq($from: screen-sm) {
      margin: 0 rem(20) 0 0;
    }
}

@media (max-width: 320px) {
	flex-direction: column;
    > div {
		margin: rem(20) 0 0 0;
    }
  }
}
