@import "colors";

// Brand colors
//
// DOD: done
//
// Shades of the three main brand colors
//
// Markup: <div class="styleguide-color {{modifier_class}}"></div>
//
//	.c--uvm-primary-1  			- blue-lagoon
//	.c--uvm-primary-2  			- pelorous
//	.c--uvm-primary-3  			- seagull
//	.c--uvm-primary-4  			- mabel
//	.c--uvm-primary-5  			- oyster-bay
//	.c--uvm-secondary  			- moon-glow
//	.c--stuk-primary-1   		- ironside-grey
//	.c--stuk-primary-2   		- concord
//	.c--stuk-primary-3   		- mountain-mist
//	.c--stuk-primary-4   		- mercury
//	.c--stuk-primary-5   		- white-smoke2
//	.c--stuk-secondary   		- moon-glow
//	.c--stil-primary-1   		- genoa
//	.c--stil-primary-2   		- gossamer
//	.c--stil-primary-3   		- shadow-green
//	.c--stil-primary-4   		- jet-stream
//	.c--stil-primary-5   		- tranquil
//	.c--stil-secondary   		- moon-glow
//	.c--stil-stat-primary-1   	- seance
//	.c--stil-stat-primary-2   	- affair
//	.c--stil-stat-primary-3   	- prelude
//	.c--stil-stat-primary-4   	- snuff
//	.c--stil-stat-primary-5   	- selago
//	.c--stil-stat-secondary   	- moon-glow
//	.c--spsu-primary-1   		- contessa
//	.c--spsu-primary-2   		- charm
//	.c--spsu-primary-3   		- rose
//	.c--spsu-primary-4   		- dust-storm
//	.c--spsu-primary-5   		- amour
//	.c--spsu-secondary   		- moon-glow
//	.c--rbl-primary-1   		- smalt
//	.c--rbl-primary-2   		- free-speech-blue
//	.c--rbl-primary-3   		- polo-blue
//	.c--rbl-primary-4   		- hawkes-blue
//	.c--rbl-primary-5   		- alice-blue
//	.c--rbl-secondary   		- ash
//	.c--rbl-tertiary   			- azalea
//
// hidedefault: true
//
// Style guide: identity.colors.brands

//Brand colors


.c--text {
	background-color:$color-text;
}
.c--border {
	background-color:$color-border;
}

.c--text-remarkable {
	background-color:$color-text-remarkable;
}
.c--text-discreet {
	background-color:$color-text-discreet;
}
.c--sunset-orange {
	background-color:$color-sunset-orange;
}

.c--black {
	background-color:$color-black;
}
.c--grey-1 {
	background-color:$color-grey-1;
}
.c--grey-2 {
	background-color:$color-grey-2;
}
.c--grey-3 {
	background-color:$color-grey-3;
}
.c--grey-4 {
	background-color:$color-grey-4;
}
.c--grey-5 {
	background-color:$color-grey-5;
}
.c--grey-6 {
	background-color:$color-grey-6;
}
.c--white {
	background-color:$color-white;
}

.c--uvm-primary-1 {
	background-color:$color-brand-UVM-1;
}
.c--uvm-primary-2 {
	background-color:$color-brand-UVM-2;
}
.c--uvm-primary-3 {
	background-color:$color-brand-UVM-3;
}
.c--uvm-primary-4 {
	background-color:$color-brand-UVM-4;
}
.c--uvm-primary-5 {
	background-color:$color-brand-UVM-5;
}
.c--uvm-secondary {
	background-color:$color-beige;
}

.c--stil-primary-1 {
	background-color:$color-brand-STIL-1;
}
.c--stil-primary-2 {
	background-color:$color-brand-STIL-2;
}
.c--stil-primary-3 {
	background-color:$color-brand-STIL-3;
}
.c--stil-primary-4 {
	background-color:$color-brand-STIL-4;
}
.c--stil-primary-5 {
	background-color:$color-brand-STIL-5;
}
.c--stil-secondary {
	background-color:$color-beige;
}

.c--stuk-primary-1 {
	background-color:$color-brand-STUK-1;
}
.c--stuk-primary-2 {
	background-color:$color-brand-STUK-2;
}
.c--stuk-primary-3 {
	background-color:$color-brand-STUK-3;
}
.c--stuk-primary-4 {
	background-color:$color-brand-STUK-4;
}
.c--stuk-primary-5 {
	background-color:$color-brand-STUK-5;
}
.c--stuk-secondary {
	background-color:$color-beige;
}
.c--stil-stat-primary-1 {
	background-color:$color-brand-STILSTAT-1;
}
.c--stil-stat-primary-2 {
	background-color:$color-brand-STILSTAT-2;
}
.c--stil-stat-primary-3 {
	background-color:$color-brand-STILSTAT-3;
}
.c--stil-stat-primary-4 {
	background-color:$color-brand-STILSTAT-4;
}
.c--stil-stat-primary-5 {
	background-color:$color-brand-STILSTAT-5;
}
.c--stil-stat-secondary {
	background-color:$color-beige;
}

.c--spsu-primary-1 {
	background-color:$color-brand-SPSU-1;
}
.c--spsu-primary-2 {
	background-color:$color-brand-SPSU-2;
}
.c--spsu-primary-3 {
	background-color:$color-brand-SPSU-3;
}
.c--spsu-primary-4 {
	background-color:$color-brand-SPSU-4;
}
.c--spsu-primary-5 {
	background-color:$color-brand-SPSU-5;
}
.c--spsu-secondary {
	background-color:$color-beige;
}


.c--rbl-primary-1 {
	background-color:$color-brand-RBL-1;
}
.c--rbl-primary-2 {
	background-color:$color-brand-RBL-2;
}
.c--rbl-primary-3 {
	background-color:$color-brand-RBL-3;
}
.c--rbl-primary-4 {
	background-color:$color-brand-RBL-4;
}
.c--rbl-primary-5 {
	background-color:$color-brand-RBL-5;
}
.c--rbl-secondary {
	background-color:$color-snow-drift;
}
.c--rbl-tertiary {
	background-color:$color-azalea;
}

.c--ord-primary-1 {
	background-color:$color-brand-ORD-1;
}
.c--ord-primary-2 {
	background-color:$color-brand-ORD-2;
}
.c--ord-primary-3 {
	background-color:$color-brand-ORD-3;
}
.c--ord-primary-4 {
	background-color:$color-brand-ORD-4;
}
.c--ord-primary-5 {
	background-color:$color-brand-ORD-5;
}
.c--ord-secondary {
	background-color:$color-white-ice;
}


.c--sosu-primary-1 {
	background-color:$color-brand-SOSU-1;
}
.c--sosu-primary-2 {
	background-color:$color-brand-SOSU-2;
}
.c--sosu-primary-3 {
	background-color:$color-brand-SOSU-3;
}
.c--sosu-primary-4 {
	background-color:$color-brand-SOSU-4;
}
.c--sosu-primary-5 {
	background-color:$color-brand-SOSU-5;
}
.c--sosu-secondary {
	background-color:$color-grey-6;
}


.c--aldrigmere-primary-1 {
	background-color:$color-brand-aldrigmere-1;
}
.c--aldrigmere-primary-2 {
	background-color:$color-brand-aldrigmere-2;
}
.c--aldrigmere-primary-3 {
	background-color:$color-brand-aldrigmere-3;
}
.c--aldrigmere-primary-4 {
	background-color:$color-brand-aldrigmere-4;
}
.c--aldrigmere-primary-5 {
	background-color:$color-brand-aldrigmere-5;
}


.c--unesco-primary-1 {
	background-color:$color-brand-UNESCO-1;
}
.c--unesco-primary-2 {
	background-color:$color-brand-UNESCO-2;
}
.c--unesco-primary-3 {
	background-color:$color-brand-UNESCO-3;
}
.c--unesco-primary-4 {
	background-color:$color-brand-UNESCO-4;
}
.c--unesco-primary-5 {
	background-color:$color-brand-UNESCO-5;
}
.c--unesco-secondary {
	background-color:$color-grey-6;
}


