hr.hr {
	width: 100%;
	max-width: 250px;
	margin-left: auto;
	margin-right: auto;

	&--full-width {
		max-width: none;
	}

	&--theme {
		@include theme-border-color('primary-1');
	}
}
