.filter-buttons {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	&__item {
		display: flex;
		align-items: center;

		background-color: $color-grey-6;
		justify-content: center;
		text-align: center;
		font-size: rem(10);
		line-height: rem(14);
		text-transform: uppercase;
		color: $color-text;
		height: rem(60);
		font-weight: 700;
		padding: 0 rem(5);
		box-sizing: border-box;

		margin-right: 3%;
		margin-bottom: 3%;
		flex: 48.5% 0 0;
		@include mq($until:screen-xs) {
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
		@include mq($from: screen-xs) {
			flex: 22.75% 0 0;
			&:nth-child(4n) {
				margin-right: 0;
			}
		}
		@include mq($from: screen-sm-plus) {
			margin-right: 2.5%;
			margin-bottom: 2.5%;
			flex: 18% 0 0;
			&:nth-child(4n) {
				margin-right: 2.5%;
			}
		}
		span {
			display: inline-block;
		}


		&.is-active {
			@include theme-background-color('primary-4');
			.theme-stuk &
			{
				background-color: $color-maroon-light;
				color: $color-white;
			}				
			.theme-stil &
			{
				background-color: $color-oracle;
				color: $color-white;
			}			
			.theme-uvm &
			{
				background-color: $color-tidal-pool;
				color: $color-white;
			}
			.theme-kanalen &
			{
				background-color: $color-tidal-pool;
				color: $color-white;
			}
		}
	}
}
