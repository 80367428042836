s// Sub Navigation
//
// DOD: done
//
// This component is used for the universe frontpages
//
// .theme-uvm						- Shown in uvm theme
// .theme-stuk						- Shown in stuk theme
// .theme-stil						- Shown in stil theme
//
// Markup: ../../templates/prototype/partials/blocks/sub-navigation.twig
//
// hidedefault: true
//
// Style guide: blocks.subnavigation

.sub-navigation {
	@include clearfix;

	ul {

	}

	li {
		margin-bottom: rem($space-small);
	}

	h1 {
		@extend %headline-1;
		margin-bottom: rem($space-large);
	}
	// JPL: Not necessary anymore
	// .button--subnav {
	// 	@include mq($until:screen-sm) {
	// 		padding-bottom: rem($space-xsmall);
	// 		&--small {
	// 			padding-bottom: rem($space-xxsmall);
	// 		}
	// 	}
	// }

	.grid {
		padding-bottom: 0;
	}
	.grid + .grid {
		margin-top: 0;
		margin-bottom: 0;
	}
	.grid-item {
		padding-bottom: rem($space-small);
		@include mq($from: screen-sm) {
			padding-bottom: rem($space-xxlarge);
		}
	}
	[class*="col-"] + [class*="col-"] {
		margin-top: 0;
		margin-bottom: 0;
	}

	&:not(.sub-navigation--mixed-content) {

		.grid:last-child {
			.grid-item:last-child,
			.grid-item:last-child .button {
				padding-bottom: 0;
			}
			.grid-item:nth-last-child(2),
			.grid-item:nth-last-child(2) .button {
				@include mq($from: screen-sm) {
					padding-bottom: 0;
				}
			}
		}

	}

	&--align-heights {
		.button--subnav {
			box-sizing: border-box;
			padding-bottom: rem($space-medium);
			@include mq($from: screen-sm) {
				min-height: rem(100);
			}
		}

		.grid:last-child {
			.grid-item:last-child,
			.grid-item:last-child .button {
				padding-bottom: rem($space-medium);
			}
			.grid-item:nth-last-child(2),
			.grid-item:nth-last-child(2) .button{
				@include mq($from: screen-sm) {
					padding-bottom: rem($space-medium);
				}
			}
		}
	}


	&--css-columns {
		ul {
			@include mq($from: screen-sm) {
				columns: 2;
			}
		}
	}

	&--extensive {
		.button--subnav {
			h4 {
				//min-height: 3em;
			}
			h4 {
				font-size: rem(18);
			}
			p {
				@extend %bodytext-md;
			}
		}
	}

	&--mixed-content {
		.grid-item:not(.card) {
			padding-bottom: 0;
			@include mq($from: screen-sm) {
				padding-bottom: 0;
			}
		}
		.button.button--subnav {
			padding-bottom: rem($space-small);
			@include mq($from: screen-sm) {
				padding-bottom: rem($space-xxlarge);
			}

			&:last-child {
				padding-bottom: 0;
				@include mq($from: screen-sm) {
					padding-bottom: 0;
				}
			}
		}

		.card--banner {
			@include mq($from: screen-lg) {
				padding: rem(100) rem($space-xxlarge) rem($space-xxlarge);
			}
		}

	}


	&--padding-bottom {		
			padding-bottom: rem($space-xxlarge);		
	}

}
