.site-header {
	position: relative;
	z-index: 2000; //to force on top of site

	padding:rem($space-xsmall) 0;
	//background:$color-white;
	//@include theme-background-color('primary-5');
	@include theme-background-color('header-bg');

	border-bottom-width:rem(4);
	border-style: solid;
	//@include theme-border-color('primary-1');
	@include theme-border-color('header-border');	
	.theme-trivsel &
	{
		border-color: $color-green-rbl!important;
	}
	&--slim {
		@include mq($from: screen-sm-plus) {
			padding:rem($space-medium) rem($space-xsmall);
		}
		@include mq($from: screen-md) {
			padding:rem($space-medium) 0;
		}
		@include mq($from: screen-xl) {
			padding:rem($space-large) 0;
		}
		@include mq($from: screen-xxl) {
			padding:rem($space-xlarge) 0;
		}

	}
	&:not(&--slim) {
		@include mq($from: screen-sm) {
			padding:rem($space-xlarge) rem($space-xsmall);
		}
		@include mq($from: screen-md) {
			padding:rem($space-xlarge) 0;
		}
	}

	&--background {
		@include theme-background-color('primary-5');
	}
}
