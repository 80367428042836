// Fonts
$font-stack-primary: 'Lato', tahoma, helvetica, arial, sans-serif;
$font-stack-secondary: 'Volkhov', Georgia, Times, 'Times New Roman', serif;
$font-stack-tertiary: 'Merriweather', Georgia, Times, 'Times New Roman', serif;



.font-primary {
	font-family:$font-stack-primary;
}
.font-secondary {
	font-family:$font-stack-secondary;
}
.font-tertiary {
	font-family:$font-stack-tertiary;
}
