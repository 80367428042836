// Subscribe Panel
//
// DOD: done
//
// Popup panel for newsletter subscription, floats on top of content
//
// Markup: ../../templates/prototype/partials/blocks/subscribe-panel.twig
//
// Style guide: blocks.subscribepanel



.subscribe-panel {

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: $color-white;

	z-index: 4000;

	box-sizing: border-box;

	padding: rem($space-xxlarge) rem($space-xsmall) rem($space-xsmall);

	@include mq($from: screen-sm) {
		top: auto;
		left: auto;
		max-width: rem(500);
		padding: rem($space-xxlarge);
	}
	@include mq($from: screen-sm-plus) {
		padding: rem($space-xxxlarge);
	}
	transform-origin: center bottom;

	transition: all 0.4s ease-out;
	transform: translateY(100%);
	opacity: 0;

	&.is-active {
		transform: translateY(0);
		opacity: 1;
	}


	&__inner {
		overflow: auto;
		max-height: calc(100vh - #{rem($space-xxlarge + $space-xsmall)});
		@include mq($from: screen-sm) {
			overflow: visible;
			max-height: none;
		}
	}
	h5 {
		@extend %headline-5;
	}

	.button--icon {
		position: absolute;
		right: rem($space-xxsmall);
		top: rem($space-xxsmall);
		z-index: 2;

		.svg-icon {
			width: rem(30);
			height: rem(30);
			@include theme-fill('primary-1');
		}
		@include mq($from: screen-sm) {
			right: rem($space-small);
			top: rem($space-small);
		}
	}

	.form__section--footer {
		margin-bottom: 0;

		> .button {
			margin: 0;
		}
	}
}
