.attributes-panel {
	padding: rem($space-medium) rem($space-xsmall);
	box-sizing: border-box;
	background: $color-white;
	position: relative;

	@include mq($from: screen-xs) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	@include mq($from: screen-sm) {
		padding: rem($space-xlarge);
	}

	@include mq($from: screen-lg) {
		padding: rem($space-xxxlarge) rem($space-xlarge);
	}



	.attribute-block:not(:last-child) {
		margin-bottom: rem($space-xlarge);
	}

	.button--icon {
		width: rem(24);
		height: rem(24);
		position: absolute;
		top: rem($space-medium);
		right: rem($space-medium);

		.svg-icon {
			width: rem(24);
			height: rem(24);
			fill: $color-text;
		}

		@include mq($from: screen-lg) {
			top: rem($space-xlarge);
			right: rem($space-xlarge);
		}
	}
}
