.rich-text {
	// max-width: rem($max-page-width-narrow);
	font-family: $font-stack-primary;

	// @include clearfix;

	h1 {
		@extend %headline-1;
		margin-bottom: 1em;
	}
	h2 {
		@extend %headline-3;
		vertical-align: middle;
		margin-bottom: rem($space-medium);

		strong {
			@extend %headline-2;
			font-weight: 400;
			@include theme-color('primary-1');
			margin-right: 0.5em;
			vertical-align: middle;
		}
	}

	* + h2 {
		margin-top: rem($space-xxlarge);
		@include mq($from: screen-sm){
			margin-top: rem(40);
		}
	}

	h3 {
		@extend %headline-4;
		margin-bottom: 0.5em;
	}
	h4 {
		@extend %headline-4;
		font-size: rem(18);
		line-height: rem(26);
		@include mq($from: screen-sm){
			font-size: rem(20);
			line-height: rem(28);
		}
		margin-bottom: 1em;
	}
	h5 { //manchet
		@extend %headline-5;
		margin-bottom: 1em;
	}
	h6 { //sektionsoverskrift
		@extend %headline-6;
		margin-bottom: 1em;
	}
	h1,h2,h3,h4,h5,h6{
		.theme-trivsel &,
		.theme-uvm &,
		.theme-stuk &,
		.theme-stil &,
		.theme-kanalen &
		{
			font-family: var(--font-primary);
			font-weight: 700;
		}	
	}

	p {
		//color: $color-grey-1;
		@include theme-color('article-paragraph');
		margin-bottom: 2em;
		@extend %bodytext-lg;
		.theme-trivsel &,
		.theme-uvm &,
		.theme-stuk &,
		.theme-stil &,
		.theme-kanalen &
		{
			font-family: var(--font-primary);
			font-weight: normal;
		}	
		&.bodytext-xl-plus {
			@extend %bodytext-xl-plus;
		}
		&.bodytext-xl {
			@extend %bodytext-xl;
		}
		&.bodytext-lg {
			@extend %bodytext-lg;
		}
		&.bodytext-md {
			@extend %bodytext-md;
		}
		&.bodytext-sm {
			@extend %bodytext-sm;
		}
		.icon svg {
			max-width: 1.5em;
    		max-height: 1.5em;
		}
		.svg-icon {
			fill: $color-grey-1;
			vertical-align: middle;
		}
		> span {
			vertical-align: middle;
			display: inline-block;
		}
		svg + span {
			margin-left: 0.5em;
		}
		a:hover{
			.theme-uvm &{
				color: $color-pelorous!important;
			}
			.theme-stil &{
				color: $color-gossamer!important;
			}
			.theme-stuk &{
				color: $color-concord!important;
			}
		}
	}

	> p + p,
	> p + ul {
		margin-top: rem(-$space-medium);
	}
	> h4 + p,
	> h5 + p,
	> h6 + p {
		margin-top: rem(-$space-xsmall);
	}
	strong {
		font-weight: 700;
	}

	p a,
	li a {
		@extend %general-link;
	}

	blockquote {
		margin: 0 0 rem($space-xxxlarge);
	}


	ul, ol {
		margin-left: rem($space-xsmall);
		@include mq($from: screen-sm){
			margin-left: rem($space-medium);
		}
		margin-bottom: rem($space-xxlarge);

		font-weight: 100;
		padding-left: rem(15, $article-font-size);
		> li {
			color: $color-grey-1;
			margin-bottom: 0.625em;
			@extend %bodytext-lg;
			font-size: inherit;
		}
	}
	ul:not(:last-child), ol:not(:last-child) {
		margin-bottom: rem($space-xxlarge);
	}

	.box + ul,
	.box + ol {
		margin-top: rem($space-medium);
	}


	ol {
		list-style: decimal outside;
		&.no-numbers {
			list-style: none;
		}
	}
	ul {
		list-style: disc outside;
		&.no-bullets {
			list-style: none;
		}
	}
	time {
		color: $color-black;
	}
	em {
		font-style: italic;
		&.light {
			color: $color-text;
		}
	}




	small {
		@extend %t--small;
    display: block;
    line-height: 1.4em;
    margin-top: 5px;
	}
	iframe, object, embed {
		background: transparent;
		display: block;
		width: 100%;
		margin-bottom: 2em;

		// @extend %keyboard-focus;
	}
	th {
		background: $color-grey-5;
		text-align: left;
		font-weight: 700;
		font-size: rem(16);
		padding: rem(5);
		@include mq($from: screen-sm){
			padding: rem(15) rem(10) rem(10);
		}
		@include mq($from: screen-md){
			font-size: rem(18);
			padding: rem(20) rem(35) rem(15);
		}
	}
	td {
		vertical-align: top;
		border: rem(2) solid $color-grey-5;
		text-align: left;
		color: $color-grey-1;
		font-size: rem(15);
		font-weight: 400;
		padding: rem(5);
		@include mq($from: screen-sm){
			padding: rem(10) rem(15);
		}
		@include mq($from: screen-md){
			font-size: rem(17);
			padding: rem(15) rem(35);
		}
	}
	tbody {
		tr:first-child td {
			border-top: none;
		}
		tr:last-child td {
			border-bottom: none;
		}
	}
	tr {
		td:first-child {
			border-left: none;
		}
		td:last-child {
			border-right: none;
		}
	}

	.box.box--table.box--table--slim {

		@include mq($from: screen-sm){
			width: 100%;
			box-sizing: border-box;
			margin-right: 38%;
		}

		td span, th span {
			padding: 0;
		}
		td, th {
			padding: rem(5);
			text-align: right;
			@include mq($from: screen-sm){
				padding: rem(5);
			}
			&:first-child {
				text-align: left;
			}
		}
		th {
			font-size: rem(15);
			@include mq($from: screen-sm){
				font-size: rem(15);
			}

		}
	}

	.box.has-caption {
		margin-bottom: 0;
	}

	.table-caption {
		padding: rem($space-xsmall);
		text-align: left;
		@extend %bodytext-sm;
		background: $color-grey-5;
		margin-bottom: rem($space-xxlarge);

		// JPL: Codehouse Integration
		margin-top: 0;
	}

	table {
		width: 100%;
		//border: rem(2) solid $color-grey-4;

	}
	sup {
		font-size: 70%;
		position: relative;
		top: -0.5em;
	}
	sub {
		font-size: 70%;
		position: relative;
		top: 0.5em;
	}

	// JPL: Codehouse Integration
	img {
		height: auto;
	}

	figure {
		display: block;
		margin-bottom: rem($space-xlarge);

		img {
			width: 100%;
			height: auto;
		}
		a {
			display: block;
		}

		&.figure--video {
			position: relative;
			cursor: pointer;
			z-index: 3;

			.button--play {
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				width: rem(30);
				height: rem(30);
				padding: rem(30);
				border-radius: 50% 50%;
				transform: translateX(-50%) translateY(-50%);
				border: rem(2) solid $color-white;
				@include theme-background-color('primary-2');
				opacity: 0.7;

				z-index: 5;

				.icon {
					display: block;
				}
			}
			figcaption + .button--play {
				top: 45%;
			}
			.icon {
				width: rem(60);
				height: rem(60);
				position: absolute;
				top: 50%;
				left: 55%;
				transform: translateX(-50%) translateY(-50%);
			}
			.svg-icon {
				width: rem(60);
				height: rem(60);
				fill: $color-white;
			}
			&:hover {
				.button--play {
					opacity: 1;
				}
			}
		}
		&.figure--left {

			width: 50%;
			@include mq($from: screen-sm){
				float: left;
				margin: 0 rem($space-medium) rem($space-small) 0;

				figcaption {
					text-align: right;
				}
			}
		}

		&.figure--right {
			width: 50%;
			@include mq($from: screen-sm){
				float: right;
				margin: 0 0 rem($space-medium) rem($space-small);
				figcaption {
					text-align: left;
				}
			}
		}

		&.figure--full {
			display: block;
			width: 100%;
			height: auto;
			margin: 0 0 rem($space-large);

		}

		// JPL: Codehouse Integration
		figcaption {
			margin-top: rem($space-xsmall);
			font-size: rem(16);
			color: $color-grey-1;
			font-weight: 400;
		}

	}

	span.meta {
		display: block;

		margin-top: 3em;

		@extend %bodytext-lg;
		color: $color-grey-1;
	}

	.accordion {
		div > p + p,
		div > p + ul {
			margin-top: rem(-$space-medium);
		}
		div > h4 + p,
		div > h5 + p,
		div > h6 + p {
			margin-top: rem(-$space-xsmall);
		}
	}
	.facts{
		.theme-trivsel &	
		{
			background-color: $color-pink-trivsel;
		}	
		.facthead{
			.theme-trivsel &			
			{
				background-color: $color-pink-trivsel;
			}	
		}
		h1,h2,h3,h4,h5,h6{
			.theme-trivsel &			
			{
				color: $color-smalt-trivsel;
			}
		}	
	}
	// JPL: Codehouse Integration

	//JPL: Removed, as it messes with link-lists
	/*
	.accordion__wrapper .accordion {
		ul {
			list-style: none;

			li {
				list-style: none;

				a {
					text-decoration: none;
					border-bottom-width: rem(2);
					border-bottom-color: transparent;
					@include theme-color('primary');

					.text, .icon {
						color: inherit;
					}

					&:hover {
						border-bottom-width: rem(2);
						border-style: solid;
						@include theme-color('primary');
					}
				}
			}
		}
	}
	*/

	* {
		&.rich-text__last{
			margin-bottom: 0 !important;
		}
	}



	.links-list {
		font-size: inherit;
	}
}
