// Comment Form
//
// DOD: done
//
// Used for Kanalen content excerpt comments. Has two modes, either with Send button or with update buttons to edit a comment. Shown below in update mode.
//
// .designguide		- Necessary to show hidden content
//
// hidedefault: true
//
// Markup: ../../templates/prototype/partials/blocks/comment-form.twig
//
// Style guide: kanalen.commentform


.comment-form {
	$module: '.comment-form';
	max-width: rem(400);
	margin-bottom: rem($space-medium);

	&__text {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: rem($space-medium);

		.form__field {
			margin: 0;
			transition: all 0.3s ease-out;
		}
	}

	&__tags {
		display: none;
		font-size: 0;

		transition: opacity 0.3s ease-out;
		opacity: 0;

		p {
			display: inline-block;
			font-size: rem(12);
			color: $color-grey-1;
			line-height: rem(20);
			vertical-align: middle;
			margin: 0 rem(3) rem(3) 0;
		}

	}
	.tags {
		margin-bottom: rem(30);
		.button {
			// @include theme-background-color('primary-3');
			border: 1px solid;
			@include theme-border-color('primary-1');
			@include theme-color('primary-1');
			background-color: transparent;

			&.is-active {
				@include theme-background-color('primary-1');
				color: $color-white;
			}
		}
	}

	.dual-buttons {
		margin-top: rem($space-medium);
		display: flex;
		.button {
			padding: rem($space-xxsmall) 0;

			flex: 50% 0 0;
			box-sizing: border-box;
			border: rem(1) solid black;
			@include theme-border-color('primary-1');
			line-height: rem(12);
			.text {
				@include theme-color('primary-1');
				font-size: rem(10);
			}

			&:hover,
			&:active {
				color: $color-black;
				background: transparent;
				border-color: $color-black;
				.text { color: $color-black; }
			}
		}
		.button.button--theme {
			.text { color: $color-white; }
			@include theme-background-color('primary-1');

			&:hover,
			&:active {
				background-color: $color-black;
				border-color: $color-black;
				.text { color: $color-white; }
			}
		}
	}

	.button--link {
		display: none;
		transition: opacity 0.3s ease-out;
		opacity: 0;
		margin-left: rem($space-medium);
	}
	&.designguide {
		.button--link {
			opacity: 1;

		}
		#{$module}__tags {
			opacity: 1;
			display: block;
		}
	}
	&.is-active {
		.button--link {
			opacity: 1;
		}
		#{$module}__tags {
			opacity: 1;
		}
	}
}
