.tags {
	letter-spacing: 0;
	font-size: 0;

	&--reveal {
		font-size: 0.8rem;
		padding-top: 0;
		display: flex;
		letter-spacing: 0;

		li {
			&:after {
				content: '·';
				margin: 0 5px;
			}

			&:last-child:after {
				display: none;
			}
		}
	}

	.button, span {
		position: relative;
		display: inline-block;
		font-weight: 400;
		font-size: rem(16);
		line-height: 1.875em;

		transition: color 0.2s ease-out;

		&:after {
			display: inline-block;
			padding:0 rem($space-xxsmall);
			content:'\b7';
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	&:not(&--boxed) {
		.button {
			@include theme-color('primary-1');
			&:hover {
				@include theme-color('primary-2');
			}
		}
	}



	&--boxed {
		.button {
			font-size: rem(12);
			margin: 0 rem(5) rem(5) 0;
			padding: rem(5);
			border-radius: rem(3);
			line-height: 1;

			color: $color-grey-1;
			background: $color-grey-5;

			&.is-active {
				background: $color-grey-4;
			}
			&:after {
				display: none;
			}
		}
	}
}
