// Shortcut Banner
//
// DOD: done
//
// The shortcut banner is used redirect to important content
//
// .theme-uvm					- Banner in uvm theme
// .theme-spsu					- Banner in spsu theme
//
// Markup: ../../templates/prototype/partials/blocks/shortcut-banner.twig
//
// Style guide: blocks.shortcutbanner



.shortcut-banner {
	line-height: 1.5;

	color: $color-text;
	font-size: rem(14);

	padding: rem($space-xsmall);
	@include mq($from: screen-sm) {
		padding: rem($space-small) rem($space-xlarge);
	}
	@include mq($from: screen-sm) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	@include theme-background-color('primary-4');
	&__inner {
		margin-bottom: rem($space-large);
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include mq($from: screen-xs) {
			align-items: flex-start;
			flex-wrap: nowrap;
		}
		@include mq($from: screen-sm) {
			margin-right: rem($space-small);
			align-items: center;
			margin-bottom: 0;
		}
	}
	h6 {
		font-weight: 700;
		margin-bottom: rem($space-xsmall);
		padding: rem($space-xxsmall) 0;
		border-bottom: rem(1) solid $color-text-discreet;
		@include mq($from: screen-xs) {
			border-bottom: 0;
			border-right: rem(1) solid $color-text-discreet;
			padding-right: rem($space-small);
			margin-right: rem($space-small);
		}
		@include mq($from: screen-sm) {
			padding: rem($space-xxsmall) rem($space-large) rem($space-xxsmall) 0;
			margin-right: rem($space-large);
			margin-bottom: 0;
		}
	}
	p {
		flex: 0 0 100%;
		@include mq($from: screen-xs) {
			flex: 1 1 100%;
			flex-basis: unset;
		}
		color: $color-text-discreet;
	}

	&--dark {
		@include theme-background-color('primary-1');
		color: $color-white;

		p {
			color: $color-white;
		}

		h6 {
			border-color: $color-white;
		}
	}
}
