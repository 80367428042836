.section-footer {
	padding: rem($space-xlarge) 0 0;
	margin-bottom: rem($space-xlarge);
	@include mq($from: screen-sm) {
		padding: rem($space-large) 0 0;
		margin-bottom: 0;
	}

	text-align: center;

	&.align-left {
		text-align: left;
	}
	&.align-right {
		text-align: right;
	}
}
