.search-filters-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 7000;
	background: $color-white;

	display: none;
	transform-origin: center top;
	transition: all 0.2s ease-out;
	transform: scaleY(0);

	&.is-active {
		display: block;
		transform: scaleY(1);
		.search-filters-overlay__inner {
			opacity: 1;
			max-height: rem(400);
		}
	}
	&__inner {
		opacity: 0;
		max-height: 0;
		transition: all 0.2s ease-out;
	}
	&__content {
		position: relative;
		height: calc(100vh - 109px); //minus header+footer h
		transform: translateZ(0);
		transition: transform 0.2s ease-out;

		&__nav {
			li a {
				display: block;
				padding: rem($space-medium);
				@extend %trumpet;
				line-height: 1.7;
				border-bottom: rem(1) solid $color-border-discreet;

				p {
					position: absolute;
					text-transform: none;
					letter-spacing: 0;
					right: 6em;
					top: 50%;
					transform: translateY(-50%);
					font-size: rem(12);
					opacity: 0;
					transition: opacity 0.2s ease-out;
					color: $color-text-discreet;

					&.is-active {
						opacity: 1;
					}
				}
				.icon {
					position: absolute;
					right: rem($space-medium);
					top: 50%;
					transform: translateY(-50%);
					.svg-icon {
						fill: $color-text-discreet;
					}
				}
			}
		}
		&__inner {
			position: absolute;
			left: 100%;
			top: 0;
			width: 100vw;
			height: calc(100vh - 109px); //minus header+footer h
			overflow-x: hidden;
			overflow-y: scroll;


			.criterion-list {
				padding-bottom: rem(64); //~extra item, to compensate for mobile navbar
				display: none;
				&.is-active {
					display: block;
				}

				li a {
					padding: rem($space-medium);
					line-height: 1.7;
					border-bottom: rem(1) solid $color-border-discreet;
				}
			}
		}

		&.is-navigated {
			transform: translateX(-100%);
		}
	}

	&__header {
		position: relative;
		text-align: center;
		@include theme-background-color('primary-4');
		color: $color-text;
		padding: rem($space-small) rem($space-medium);

		h5 {
			@extend %bodytext-lg;
			font-weight: 600;
		}

		.button--back {
			position: absolute;
			top: 50%;
			left: rem($space-medium);
			transform: translateY(-50%);
			.svg-icon {
				fill: $color-text;
				transform: scaleX(-1);
			}
		}
		.button--clear {
			font-size: rem(12);
			color: $color-text;
			position: absolute;
			top: 50%;
			right: rem($space-medium);
			transform: translateY(-50%);

			.text { text-decoration: underline; }

			display: none;
			&.is-active {
				display: block;
			}
		}
	}


	&__footer {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		.button {
			text-align: center;
			padding: rem($space-medium);
			color: $color-white;
			font-size: rem(12);
			text-transform: uppercase;
			font-weight: 600;
			display: none;
			&.is-active {
				display: block;
			}

			&--close {
				background: lighten($color-black,20%);
			}
			&--submit {
				@include theme-background-color('primary-1');
			}
		}
	}
}
