// Entry Button
//
// DOD: done
//
// Used in entry navigation blocks.
//
// Markup: ../../templates/prototype/partials/elements/button-entry.twig
//
// wrapperstyles: overflow: hidden;
//
// Style guide: elements.buttonentry


.button--entry {
	transition: none;
	display: block;
	box-sizing: border-box;
	margin:rem($space-small) 0;
	padding-left:rem($space-xxxlarge);

	&.is-animating {
		transition:margin-bottom 0s ease-out;
	}


	@include mq($from: screen-sm) {
		text-align:center;
		float: left;
		width: 50%;
		padding: 0 rem($space-xsmall);
		margin:rem($space-medium) 0;
	}

	@include mq($from: screen-md) {
		text-align: center;
		width: 33%;
		padding: 0 rem($space-small);
		margin:rem($space-xxlarge) 0;
	}

	.entries-navigation--5-col & {
		@include mq($from: screen-sm) {
			text-align: left;
			width: 100%;
			padding: 0 rem(60);
			margin:rem($space-small) 0;
		}

		@include mq($from: screen-md) {
			text-align: left;
			width: 100%;
			padding: 0 rem(60);
			margin:rem($space-small) 0;
		}

		@include mq($from: screen-xl) {
			text-align: center;
			padding: 0 rem($space-medium);
			float: left;
			width: 20%;
			margin:rem($space-xxlarge) 0;
		}
	}


	@include mq($media-type:print){
		text-align:center;
		float: left;
		width: 50%;
		padding: 0 rem($space-xsmall);
		margin:rem($space-large) 0;
	}


	&.is-styleguide {
		float: none !important;
	}

	.svg-icon {
		margin:0 auto;
		//@include theme-fill('primary-1');
		@include theme-fill('entries-nav-icon');
		transition:all 0.1s ease-out;

		width:rem(36);
		height:rem(36);
		transform:scale(0.9);

		@include mq($from: screen-sm) {
			width:rem(44);
			height:rem(44);
		}
		@include mq($from: screen-xl) {
			width:rem(52);
			height:rem(52);
		}
		@include mq($media-type:print){
			width:rem(52);
			height:rem(52);
		}
	}
	.icon {
		display: block;
		margin-bottom:rem($space-xxsmall);

		position: absolute;
		top:50%;
		left:0;
		transform: translateY(-50%);

		@include mq($from: screen-sm) {
			position: static;
			transform: translate(0,0);
			margin-bottom:rem($space-small);
		}

		.entries-navigation--5-col & {
			@include mq($from: screen-sm) {
				position: absolute;
				top:50%;
				left:0;
				transform: translateY(-50%);
			}
			@include mq($from: screen-xl) {
				position: static;
				transform: translate(0,0);
				margin-bottom:rem($space-small);
			}
		}

		@include mq($media-type:print){
			position: static;
			transform: translate(0,0);
			margin-bottom:rem($space-small);
		}
	}
	h4 {
		//color:$color-black;
		@include theme-color('entries-nav-headline');
		font-weight: 700;
		text-transform: uppercase;
		transition:all 0.1s ease-out;
		font-size: rem(13);
		margin-bottom:rem(3);
		letter-spacing: 0.05em;

		@include mq($from: screen-sm) {
			font-size: rem(14);
		}

		@include mq($from: screen-xl) {
			font-size: rem(14);
			text-align:center;
			margin-bottom:rem($space-xsmall);
		}
		@include mq($media-type:print){
			font-size: rem(14);
			text-align:center;
			margin-bottom:rem($space-xsmall);
		}
	}

	p {
		font-size:rem(14);
		line-height: rem(16);
		font-weight: 400;
		//color:$color-grey-1;
		@include theme-color('entries-nav-text');
		transition:all 0.1s ease-out;
		overflow:hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		height: 1.5em;

		@include mq($from: screen-sm) {
			font-size:rem(15);
		}

		@include mq($from: screen-xl) {
			height: auto;
			//overflow:visible; //why???
			text-align:center;
			font-size:rem(15);
			line-height: rem(18);
		}
	}

	&:hover {
		.svg-icon {
			@include theme-fill('primary-2');
			transform:scale(1) translateY(3%);
		}
		h4, p {
			//@include theme-color('primary-2');
		}
	}
}
