.button-collection {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    li {
      flex: 0 1 auto;
      margin: 10px 5px 0;
    }
  }
}