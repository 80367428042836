// Subnav Button
//
// DOD: done
//
// Used in sub navigation blocks.
//
// Markup: ../../templates/prototype/partials/elements/button-subnav.twig
//
// Style guide: elements.buttonnav

.button--subnav {
	display: block;
	&:hover {
		.text {
			border-color: $color-black;
		}
		.svg-icon {
			transform: translateX(0px);
		}
		.icon {
			transform: translateX(10px);
		}
	}

	.text {
		font-size: rem(18);
		font-weight: 600;
		display: inline;
		padding-bottom: rem(2);
		border-bottom: rem(2) solid transparent;
		transition: border-color 0.4s ease-out;
	}
	.icon {
		transition: transform 0.3s ease-out;
		transform: translateX(8px);
		overflow: hidden;
		display: inline-flex;
	}
	.svg-icon {
		transform: translateX(-8px);
		transition: transform 0.3s ease-out;
		width: 1em;
		height: 1em;
		vertical-align: middle;
		fill: $color-black;
	}

	.headline {
		display: inline-block;
		@extend %headline-5;
		color: $color-black;
		font-size: rem(18);
	}
	.tagline {
		font-size: rem(14);
		line-height: 1.5;
	}
	p {
		padding-top: rem($space-xsmall);
		@extend %bodytext-md;
		color: $color-grey-1;
	}

	& + .button--subnav {
		margin-top: rem(8);
	}

	&--small {
		.text {
			font-weight: 400;
			font-size: rem(16);
			padding-bottom: 0;
		}
		.icon {
			transform: translateX(7px);
		}
		.svg-icon {
			transform: translateX(-7px);
		}
		p {
			padding-top: 0;
		}

		&:hover {
			.text {
				border-color: $color-grey-2;
			}
		}
	}
}
