.page-header {
	position: relative;
	margin-top: rem($space-small);
	margin-bottom: rem($space-small);
	border-bottom: rem(3) solid $color-black;
	line-height: 1.5;

	padding-bottom: rem($space-small);

	@include mq($from: screen-sm) {
		padding-bottom: rem($space-xlarge);
		margin-top: rem($space-xxlarge);
		margin-bottom: rem($space-xxlarge);
	}

	// h1, h6, p {
	// 	color: $color-black; // a11y: text needs a color defined
	// }

	.deck--theme & {
		margin-top: 0;
	}

	&--tall {
		margin-top: rem(70);
		@include mq($from: screen-sm) {
			margin-top: rem(100);
		}
	}
	&--no-margin {
		margin-bottom: 0;
	}
	&--no-border {
		border-bottom: none;
	}
	&--diminished {
		h1 {
			@include mq($from: screen-xs) {
				width: calc(100% - 360px);
			}
			@include mq($from: screen-sm) {
				width: calc(100% - 340px);
			}
		}
	}
	&--small {
		margin-top: 0;

		h1 {
			@extend %headline-3;
		}
		.subheadline {
			font-size: rem(16);
		}
	}

	@include clearfix;
	.button--link {
		margin-right: rem($space-medium);
		.icon {
			vertical-align: middle;
		}
	}

	h1 {
		@extend %headline-1;
		@include hyphenation;
		.theme-trivsel &		
		{
			color: $color-smalt-trivsel;
		}
		.theme-trivsel &,
		.theme-uvm &,
		.theme-stuk &,
		.theme-stil &,
		.theme-kanalen &
		{
			font-family: var(--font-primary);
			font-weight: 700;
		}			
		
	}

	.subheadline {
		padding-bottom: rem(30);
		.theme-trivsel &,
		.theme-uvm &,
		.theme-stuk &,
		.theme-stil &,
		.theme-kanalen &
		{
			font-family: var(--font-primary);
			font-weight: normal;
		}	
	}

	.subheadline + p,
	.subheadline {
		@extend %subheadline;
		@include mq($from: screen-sm-plus) {
			max-width: 85%;
		}
		@include mq($from: screen-md) {
			max-width: 70%;
		}
	}

	.meta + h1,
	h1 + .subheadline,
	h1 + .form,
	h1 + .meta {
		margin-top: rem($space-small);
		@include mq($from: screen-md) {
			margin-top: rem($space-medium);
		}
	}

	.subheadline + .meta,
	.subheadline + p + .meta {
		margin-bottom: rem($space-medium);
	}
	// .resultcount {
	// 	@extend %subheadline;
	// 	color:$color-grey-1;
	// 	padding-bottom: rem($space-xxlarge);
	// 	border-bottom: rem(2) solid $color-black;
	// 	margin-bottom: rem($space-xxlarge);
	// }

	&__status {
		padding: rem($space-small);
		background: $color-grey-5;

		margin: rem($space-small) 0 0;

		@include mq($from: screen-md) {
			padding: rem($space-large) 0;
		}
	}
	.form {
		display: block;

		.form__inner {
			margin-bottom: rem($space-small);
			display: flex;

			@include mq($from: screen-sm) {
				margin-bottom: rem($space-large);
				width: 60%;
				max-width: rem(600);
				min-width: rem(400);
			}
		}
		.js--page-header-search-field {
			border-right: 0;
		}
		.button--clear {
			.icon {
				overflow: visible;
			}
			.icon,
			.svg-icon {
				transform: translateZ(0);
			}
			&.is-hidden {
				display: none;
			}
			&:hover {
				transform: translateZ(0);
			}
		}

		.button--show {
			@include mq($from: screen-sm-plus) {
				display: none;
			}
			&.is-hidden {
				display: none;
			}
		}

		.resultcount {
			display: block;
			vertical-align: middle;
			margin-top: rem(20);
			margin-right: rem(50);
			line-height: 1.5;
		}
	}

	.form__list-filters{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include mq($from: screen-sm-plus) {
			justify-content: flex-start;
			align-items: flex-end;
			flex: 1 1 1;
		}

		.form__list-filters-item {
			width: 49%;
			@include mq($from: screen-sm-plus) {
				display: list-item;
				width: auto;
			}
		}
	}

	.form__list-filters-link {
		background: $color-grey-5;
		color: $color-black;
		margin-right: .8rem;
		margin-bottom: .4rem;
		width: 100%;

		@include mq($from: screen-sm-plus) {
			width: auto;
			min-width: 10rem;
		}


		&:hover,
		&.is-clicked {
			@include theme-background-color("primary-1");
			color: $color-white;
			.theme-trivsel &			
			{
				background: $color-smalt-trivsel!important;					
			}
		}
	}

	.form__filters {
		justify-content: space-between;
		align-items: flex-end;

		opacity: 0;
		display: none;
		transition: opacity 0.3s ease-out;

		&.is-active {
			opacity: 1;
		}

		@include mq($from: screen-sm-plus) {
			display: flex !important;
			opacity: 1 !important;
		}

		>ul {
			flex: 1 1 70%;
			@include mq($from: screen-sm) {
				display: flex;
			}
			>li {
				&:last-child {
					margin-right: rem(0);
				}
				&.form-filters--wide {
					flex: 0 0 rem(250);
				}
				@include mq($from: screen-sm) {
					flex: 0 0 rem(170);
					margin-right: rem(10);
				}
				@include mq($from: screen-lg) {
					flex: 0 0 rem(185);
					margin-right: rem(20);
				}
				@include mq($from: screen-xxl) {
					flex: 0 0 rem(200);
				}

			}
		}

		.form__field {
			margin-bottom: rem($space-xxsmall);
		}
		.sorting {
			margin-top: rem($space-medium);
			@include mq($from: screen-sm-plus) {
				margin-top: 0;
			}

			max-width: rem(180);
			flex: 0 0 rem(180);
		}
	}

	&__auxilliary {
		.page-header__status + & {
			margin-top: rem($space-small);
			@include mq($from: screen-xs) {
				margin-top: 0;
			}
		}
		@include mq($from: screen-xs) {
			position: absolute;
			top: 0;
			right: 0;
		}
		@include mq($from: screen-sm) {
			top: rem($space-xsmall);
			width: rem(340);
		}
	}

	.button--signup {
		display: inline-block;
		margin-right: rem($space-xsmall);
		margin-bottom: rem($space-xsmall);
		@include mq($from: screen-xs) {
			margin-right: 0;
			float: right;
		}
	}
	.button--signup ~ .button--share {
		@include mq($from: screen-xs) {
			margin-left: 0;
			margin-right: rem($space-xsmall);
		}
		@include mq($from: screen-sm) {
			margin-right: 0;
		}
	}
	.button--share {
		display: inline-block;
		margin: 0 0 rem($space-xsmall);
		@include mq($from: screen-xs) {
			float: right;
		}
		@include mq($from: screen-sm) {
			margin: 0;
		}
	}

	.button--filter {
		margin-top: rem($space-large);
		padding: rem($space-small);
		@include theme-background-color("primary-1");
		color: $color-white;
		justify-content: space-between;
		display: flex;
		max-width: rem(400);

		@include mq($from: screen-sm-plus) {
			display: none;
		}
		.svg-icon {
			fill: $color-white;
		}
	}
}
