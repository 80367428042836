// Social Circle
//
// DOD: done
//
// Circle with icon
//
// Markup: <div class="social-circle"><svg focusable="false" class="svg-icon svg-icon--social-facebook"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/svg/svg-sprite.svg#social-facebook"></use></svg></div>
//
// Style guide: kanalen.socialcircle


.social-circle {
	flex: 30px 0 0;
	width: rem(30);
	height: rem(30);
	overflow: hidden;
	border-radius: 50% 50%;
	border: rem(2) solid $color-black;
	@include theme-border-color('primary-1');
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transition: background 0.2s ease-out;


	.svg-icon {
		width: rem(15);
		height: rem(15);
		@include theme-fill('primary-1');
		transition: fill 0.2s ease-out;
	}

	&:hover {
		@include theme-background-color('primary-1');
		.svg-icon {
			fill: $color-white;
		}
	}
}
