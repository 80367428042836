// Category Card
//
// DOD: done
//
// Card with optional mood icon, a list of navigation links and a link button
//
// .category-card--icon				- Center aligned content and fixed height
//
// Markup: ../../templates/prototype/partials/elements/category-card.twig
//
// Style guide: kanalen.categorycard



.category-card {

	$module: '.category-card';

	padding: rem($space-medium) rem($space-xsmall);
	box-sizing: border-box;
	background: $color-white;

	@include mq($from: screen-sm) {
		padding: rem($space-medium);
	}

	@include mq($from: screen-lg) {
		padding: rem($space-xlarge);
	}

	&--single {
		margin-right: rem(30);
	}


	&:hover {
		transition: background 0.2s ease-out;
		@include theme-background-color('primary-5');
	}


	&__inner {
		position: relative;
		overflow: hidden;


		@include mq($from: screen-sm) {
			height: rem(280);
		}

	}

	&__icon {
		display: inline-block;
		width: rem(50);
		height: rem(50);
		margin-bottom: rem($space-medium);
		.svg-icon {
			display: inline-block;
			width: rem(50);
			height: rem(50);
			@include theme-fill('primary-1');
		}
	}


	h3 {
		font-weight: 700;
		color: $color-text;
		font-size: rem(12);
		text-transform: uppercase;
		margin-bottom: 0.5em;
		@include mq($from: screen-sm) {
			margin-bottom: 0;
			min-height: rem(40);
		}
	}

	ul,
	ul li {
		margin: 0;
		padding: 0;
	}
	ul li:not(:last-child) {
		position: relative;
		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 100%;
			left: 0;
			height: rem(1);
			width: rem(70);
			background: $color-grey-4;
		}
	}
	ul li a {
		overflow: hidden;
		display: block;
		font-size: rem(14);
		@include theme-color('primary-1');
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: rem($space-xxsmall) 0;

		&:hover {
			text-decoration: underline;
		}
		@include mq($from: screen-sm) {
			padding: rem(8) 0;
		}
	}

	.button--link {
		position: absolute;
		bottom: 0;
		right: 0;
		display: inline-block;
		.text, .icon {
			font-size: rem(10);
		}
		.text { color: currentColor; }
		.svg-icon { fill: currentColor; }
	}

	&--icon {
		text-align: center;

		ul li:not(:last-child):after {
			left: 50%;
			transform: translateX(-50%);
		}

		#{$module}__inner {
			height: rem(350);
		}
	}
}
