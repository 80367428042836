.d-none      			{ display: none !important; }
.d-inline      		{ display: inline !important; }
.d-inline-block    { display: inline-block !important; }
.d-block      			{ display: block !important; }
.d-table      			{ display: table !important; }
.d-table-row      	{ display: table-row !important; }
.d-table-cell      { display: table-cell !important; }
.d-flex      			{ display: flex !important; }
.d-inline-flex     { display: inline-flex !important; }

@for $i from 1 to length($mq-breakpoint-names)+1 {
	$breakpoint-name: unquote(nth($mq-breakpoint-names, $i));
	@include mq($from: screen-#{$breakpoint-name}) {
		.d-none--#{$breakpoint-name}      			{ display: none !important; }
		.d-inline--#{$breakpoint-name}      		{ display: inline !important; }
		.d-inline-block--#{$breakpoint-name}    { display: inline-block !important; }
		.d-block--#{$breakpoint-name}      			{ display: block !important; }
		.d-table--#{$breakpoint-name}      			{ display: table !important; }
		.d-table-row--#{$breakpoint-name}      	{ display: table-row !important; }
		.d-table-cell--#{$breakpoint-name}      { display: table-cell !important; }
		.d-flex--#{$breakpoint-name}      			{ display: flex !important; }
		.d-inline-flex--#{$breakpoint-name}     { display: inline-flex !important; }
	}
}
