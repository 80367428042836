.alphabetic-navigation {
	border-top: rem(1) solid $color-border-discreet;
	border-left: rem(1) solid $color-border-discreet;

	display: block;
	font-size: 0;
	@include mq($from: screen-sm) {
		border: 0;
		display: flex;
		justify-content: stretch;
	}

	.button {
		border-right: rem(1) solid $color-border-discreet;
		border-bottom: rem(1) solid $color-border-discreet;
		box-sizing: border-box;
		display: inline-block;
		text-align: center;
		padding: rem(10) 0;
		font-size: rem(12);
		line-height: rem(12);
		font-weight: 700;
		text-transform: uppercase;
		color: $color-text;
		width: 14.2857%;

		&.is-active {
			@include theme-background-color('primary-5');
		}

		@include mq($from: screen-sm) {
			border: rem(1) solid $color-border-discreet;
			border-right: 0;
			width: auto;
			padding: rem(10) 0;
			flex: 1;
		}

		&:last-child {
			border-right: rem(1) solid $color-border-discreet;
		}

		&:first-child {
			font-size: rem(10);
			@include mq($from: screen-sm) {
				flex-basis: rem(20);
			}
			@include mq($from: screen-md) {
				flex-basis: rem(30);
			}
		}
	}

	& + * {
		margin-top: rem($space-large);
		.link {
			@include mq($from: screen-sm) {
				float: right;
			}
		}
	}
}
