// Paging
//
// DOD: done
//
// List of `.button--bubble` elements used to represent page links below a list.
//
// Markup: ../../templates/prototype/partials/elements/paging.twig
//
// Style guide: elements.paging


.paging{
	padding:rem($space-xxlarge) 0;
	@include mq($from: screen-sm) {
		padding:rem($space-small) 0;
	}
	@include mq($from: screen-sm-plus) {
		padding:rem($space-xxxlarge) rem($space-medium) rem($space-xxxlarge) 0;
	}
	text-align:center;

	a {
		font-weight: 400;
		display: inline-block;
		padding:0 rem($space-xxsmall);
		@include mq($from: screen-sm-plus) {
			padding:0 rem($space-medium);
		}
		@extend %bodytext-lg;
		@include theme-color('primary-1');
		transition:color 0.2s ease-out;

		&:hover {
			@include theme-color('primary-3');
		}

		&.is-active {
			font-weight: 700;
			color:$color-grey-1;
			&:hover {
				color:$color-grey-1;
				.theme-stuk &
				{
					color: $color-maroon-light;
				}				
				.theme-stil &
				{
					color: $color-oracle;
				}			
				.theme-uvm &
				{
					color: $color-tidal-pool;
				}
			}
		}
	}


	.button--bubble {
		margin:0 0.3em;
		@include mq($from: screen-sm) {
			margin:0 1em;
		}
		.icon {

		}
		.svg-icon { fill:$color-black;}

		&.is-hidden {
			display: none;
		}

		&:first-child {
			.svg-icon {
				transform:scaleX(-1) translate(50%,-50%);
			}
		}
	}
	> span {
		margin:0 0.5em;
	}
}
