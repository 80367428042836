.content-excerpt-list {
	margin: 0;
	padding: 0;

	> li:not(:last-child) {
		padding-bottom: rem($space-medium);
		margin-bottom: rem($space-medium);

		position: relative;

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 100%;
			left: 0;
			height: rem(1);
			width: rem(70);
			background: $color-grey-4;
		}
	}

	.content-excerpt-list {
		> li:not(:last-child):after {
			left: rem(20);
		}
	}

	&--tight {
		> li:not(:last-child) {
			padding-bottom: rem($space-xsmall);
			margin-bottom: rem($space-xsmall);

			&:after {
				display: none;
			}
		}
	}
}

