.site-footer {
	$module: '.site-footer';
	@include theme-background-color('footer-bg');

	&__top {
		padding: rem($space-medium) 0;

		@include mq($from: screen-sm){
			padding: rem(100) 0;
		}

		p,
		p a {
			font-size: rem(16);
			font-weight: 400;
			//color: $color-text-discreet;
			@include theme-color('footer-text');
			transition: color 0.3s ease-out;
		}

		h6 {
			//color: $color-black;
			@include theme-color('footer-headline');
		}

		p a:not(.button) {
			@extend %general-link;
		}

		p {
			line-height: 1.5em;
			margin-bottom: rem($space-xsmall);
			@include theme-color('footer-text');
		}

		p .button {
			padding-bottom: 0;
			border-bottom: 0 solid $color-grey-4;
			margin-top: 0;
			//color: $color-text-discreet;
			@include theme-color('footer-text');
			vertical-align: initial;
			display: inline;
			&:hover {
				.theme-trivsel & {
					color: $color-smalt-trivsel !important;
				}
				.theme-stuk &
				{
					color: $color-maroon-light;
				}				
				.theme-stil &
				{
					color: $color-oracle;
				}			
				.theme-uvm &
				{
					color: $color-tidal-pool;
				}
			}
		}
		.button {
			margin-top: 0.5em;
			//color: $color-text-discreet;
			@include theme-color('footer-text');
			display: inline-block;
			padding-bottom: rem(3);
			border-bottom: rem(2) solid $color-grey-4;

			&:hover {
				//@include theme-color('primary-2');
				@include theme-color('footer-hover');
				//@include theme-border-color('primary-2');
				@include theme-border-color('footer-hover');
			}
		}

		.grid-item > .button {
			font-weight: 400;
			color: $color-text-discreet;
		}
	}

	&__bottom {
		border-top: rem(1) solid $color-grey-5;
		padding: rem($space-medium) 0;
		//text-align: center;
		@include mq($from: screen-xs){
			text-align: left;
		}
		.button--link {
			font-size: 0.875em;
		}

		li {
			display: inline-block;
			@include mq($from: screen-xs){
				float: left;
			}
		}

		&--social {
			margin-top: rem($space-small);
			@include mq($from: screen-sm){
				margin-top: 0;
				float: right;
			}
			li {
				margin-right: rem($space-small);
				@include mq($from: screen-sm){
					margin-right: 0;
				}
			}
			.button--icon:hover{
				.svg-icon{
					.theme-trivsel & {
						fill: $color-smalt-trivsel !important;
					}
					.theme-stuk &
					{
						fill: $color-maroon-light!important;
					}				
					.theme-stil &
					{
						fill: $color-oracle!important;
					}			
					.theme-uvm &
					{
						fill: $color-tidal-pool!important;
					}
				}
			}
		}
		&--aux {
			li {
				font-size: rem(16);
				display: block;
				margin-bottom: rem($space-xxsmall);

				@include mq($from: screen-xs){
					padding: 0 0.5em;
					// border-left: rem(1) solid $color-grey-5;
					display: inline-block;
				}
				&:first-child {
					border-left: none;
					padding-left: 0;
				}
				a:hover{
					.text{
						.theme-trivsel & {
							fill: $color-smalt-trivsel !important;
						}
						.theme-stuk &
						{
							color: $color-maroon-light!important;
						}				
						.theme-stil &
						{
							color: $color-oracle!important;
						}			
						.theme-uvm &
						{
							color: $color-tidal-pool!important;
						}
					}
				}
			}
		}
	}
	
	.button--link {
		.text {
			font-weight: 400;
			line-height: 1.8em;
			margin: 0;
			//color: $color-text-discreet;
			@include theme-color('footer-text');
		}
		.icon,
		.svg-icon {
			width: 1.5em;
			height: 1.5em;
			//fill: $color-text-discreet;
			@include theme-fill('footer-text');
		}
		&:hover {
			.text,.svg-icon {
				fill: $color-grey-3;
				color: $color-grey-3;
			}
		}
	}
	.button--icon {
		width: rem(30);
		height: rem(30);
		padding-top: 1em;

		@include mq($from: screen-sm){
			padding-top: 0;
			padding-left: 0.5em;
		}
		.svg-icon {
			width: 1em;
			height: 1em;
		}
	}
	.button--language {
		display: none;
		padding-left: 1.5em;
		.icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
		}
		.svg-icon {
			transform: translate(0,0);
		}
		&.is-active {
			display: inline-block;
		}
		.text { margin-left: 0.3em; }
	}
	h6 {
		font-weight: 500;
		font-size: rem(20);
		margin-bottom: rem($space-xsmall);
		margin-top: rem($space-xxlarge);

		@include mq($from: screen-sm) {
			margin-bottom: rem($space-large);
			margin-top: 0;
		}
	}
	.grid-item:first-child h6 {
		margin-top: rem($space-large);
		@include mq($from: screen-sm) {
			margin-top: 0;
		}
	}

	&--border {

		#{$module}__top {
			border-top: rem(4) solid $color-black;
			.theme-trivsel & {
				border-top: 0.25rem solid $color-green-rbl !important;
			}
		}

	}

	.footer-image {
		width: 17.5rem;
		&--logo{
			margin-left: -20px;
		}
	}
}
