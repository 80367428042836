.simple-footer {
	border-top: rem(1) solid $color-border-discreet;
	border-bottom: rem(1) solid $color-border-discreet;
	padding: rem($space-small) 0;

	@include mq($from: screen-sm) {
		padding: rem($space-medium);
		display: flex;
		align-items: center;
	}


	h5,
	.button--subnav {
		margin-right: 2em;
		@extend %headline-5;
	}
	.button--subnav {
		margin-top: rem($space-xxsmall);
		@include mq($from: screen-sm) {
			margin-top: 0;
		}
	}

}
