// Reactions
//
// DOD: done
//
// Component with likes and comments. Used inside content excerpts.
//
// Weight: 1
//
// Markup: ../../templates/prototype/partials/elements/reactions.twig
//
// Style guide: kanalen.reactions



.reactions {

	&.js--accordion {
		overflow: visible;
		&.is-active {
			overflow: visible;
		}
	}
	&__controls {
		display: flex;

		.button {
			display: flex;
			font-size: rem(10);
			@include theme-color('primary-1');
			.svg-icon {
				fill: currentColor;
				width: 2em;
				height: 2em;
			}
			.text {
				line-height: 2em;
				margin-left: 0.5em;
				@include hyphenation;
			}
			.icon2 {
				display: none;
			}

			& + .button {
				margin-left: 1em;
			}

			&.is-liked {
				.icon {
					display: none;
				}
				.icon2 {
					display: inline-block;
				}
			}
		}
	}

	&__comments {
		position: relative;
		// max-height: 0;
		overflow: hidden;
		background-color: rgba($color-white, 0.48);
		opacity: 0;
		padding: 0 rem($space-xsmall);
		transition: max-height 0.3s, opacity 0.3s, padding 0.3s, margin-top 0.1s 0.2s;

		ul {
			list-style: none;
		}

		&:before {
			content: '';
			width: 0;
			height: 0;
			border-bottom: rem(6) solid rgba($color-white, 0.48);
			border-left: rem(6) solid transparent;
			border-right: rem(6) solid transparent;
			transform: translateX(-50%);
			position: absolute;
			top: rem(-6);
			left: rem(20);
		}



		@include mq($from: screen-sm) {
			padding: 0 rem($space-medium) 0 0;
			margin-left: rem(-92); //Image + margin
			margin-right: rem(-10);
			.content-excerpt--large & {
				margin-left: rem(-110); //Image + margin
			}
			&:before {
				left: 50%;
			}
		}
		@include mq($from: screen-lg) {
			margin-right: rem(-20);
		}

		.content-excerpt {

			.content-excerpt__image,
			.content-excerpt__image img {
				width: rem(50);
				height: rem(50);
			}


			@include mq($from: screen-sm) {
				.content-excerpt__image {
					margin-left: rem(20);
				}
				.content-excerpt__content {
					width: calc(100% - #{rem(72 + 20)});
				}
			}

			.content-excerpt--large & {
				.content-excerpt__image,
				.content-excerpt__image img {
					width: rem(60);
					height: rem(60);
				}

				@include mq($from: screen-sm) {
					.content-excerpt__image {
						margin-left: rem(25);
					}
					.content-excerpt__content {
						width: calc(100% - #{rem(90 + 20)});
					}
				}
			}
		}
	}

	&.is-active &__comments {
		padding: rem($space-xsmall);
		margin-top: rem($space-medium);
		display: block;
		overflow: visible;
		opacity: 1;

		transition: max-height 0.4s, opacity 0.6s 0.1s, padding 0.4s, margin-top 0.3s;


		@include mq($from: screen-sm) {
			padding: rem($space-medium) rem($space-medium) rem($space-medium) 0;
		}

	}


}
