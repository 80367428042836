// Headline List
//
// DOD: done
//
// List of `button--headline` elements
//
// Markup: ../../templates/prototype/partials/blocks/headline-list.twig
//
// Style guide: blocks.headlinelist


.headline-list {
	text-align:left;

	@include mq($from: screen-sm-plus){
		margin-bottom:rem($space-medium);
	}
	ul {
		margin:0;
		padding:0;
	}
	li {
		// JPL: Codehouse Integration
		position: relative;
		margin:0;
		display: block;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: rem(2);
			width: rem(70);
			background: $color-grey-4;
		}
		&:last-child .button {
			padding-bottom:0;
		}
	}

	.button--headline {

		// JPL: Codehouse Integration
		border: none;

		padding: rem($space-medium) 0 rem($space-xxlarge);

		@include mq($from: screen-sm-plus){
			padding: rem($space-medium) 0 rem($space-xxlarge);
		}

		&:hover .text {
			color: $color-grey-1;
		}

		.text {
			display: block;
			font-weight: 300;
			line-height: 1.25em;
			color: $color-black;
			font-size: rem(20);
		}
		.subtext {
			display: block;
			font-weight: 400;
			margin-top: 0.75em;
			line-height: 1.125em;
			color: $color-text-discreet;
		}

	}
}
