.accordion__wrapper {

	&:not(:last-child) {
		margin-bottom: rem(50);
		@include mq($from: screen-sm-plus) {
			margin-bottom: rem($vertical-gutter-desktop);
		}
	}

	.js--accordion-controller-trigger {
		margin-left: auto;
		font-size: rem(16);
	}

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: rem($space-medium);

		.accordion__wrapper__title {
			margin-bottom: 0;
		}
	}

	// margin-top: rem($space-large);
	.accordion:last-of-type {
		border-bottom: rem(1) solid $color-grey-5;
	}
	+ .accordion__wrapper {
		margin-top: rem(30);
	}

	&.accordion__wrapper--margin {
		margin-bottom: rem($space-xxlarge);
	}
}
.accordion__wrapper__title {
	margin-bottom: 0;	
	.theme-trivsel &
	{				
		color: $color-black!important;
	}
}
.js--accordion {
	overflow: hidden;

	.js--accordion-trigger,
	.js--accordion-trigger-nested {
		cursor: pointer;
		display: block;
	}
	.js--accordion-content,
	.js--accordion-content-nested {
		// &[aria-hidden="true"] {
		// 	display: none;
		// }
		display: none;
		// height: auto;
		// // transition: all 0.3s ease-out;
		// overflow: hidden;
		// opacity: 0;
	}
	.js--accordion-content-nested {
		transition: none;
	}

	&.is-active {
		.accordion__title .icon {
			transform: rotateZ(0deg);
		}

		.js--accordion-content,
		.js--accordion-content-nested {
			// opacity: 1;
			// height: auto;
		}
	}
}
.accordion {
	border-top: rem(1) solid $color-grey-5;

	.accordion__subtitle {
		margin-top: 10px;
		margin-bottom: 0;

		@include mq($from: screen-md) {
			display: flex;
			align-items: baseline;
		}

		&-meta {
			flex: 0 0 auto;
			font-size: rem(13);
			color: $color-grey-1;
			margin-bottom: 0;
			line-height: 1.5;
		}

		&-tags {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			list-style: none;
			margin: 0 !important;
			padding: 0;

			@include mq($from: screen-md) {
				padding: 0 0 0 15px;
				&:before {
					content: "";
					margin: 0 8px;
					display: block;
					width: 1px;
					height: calc(100% - 0.5em);
					background-color: $color-grey-2;
					position: absolute;
					left: 0;
				}
			}

			li {
				margin: 0;
				font-size: 13px;
				color: $color-grey-1;
				line-height: 1.5em;
				&:after {
					content: "·";
					margin: 0 5px;
				}

				&:last-child:after {
					display: none;
				}
			}
		}
	}

	&__title {
		position: relative;
		padding: rem($space-small) 0;

		@include clearfix;

		h4,
		h5 {
			margin: 0;
			transition: all 0.2s ease-out;
			//@include theme-color("primary-1");
			@include theme-color("accordion-text");
			@include clearfix;

			padding-right: 2em;
		}
		h4 {
			@extend %bodytext-lg;

			> span {
				margin-left: rem($space-large);
				letter-spacing: 0;
				font-size: rem(12);
				text-transform: none;
				color: $color-text-discreet;
			}
		}
		h5 {
			@extend %bodytext-lg;
		}
		.icon {
			display: block;
			position: absolute;
			top: 1em;
			transform-origin: center center;
			transform: rotateZ(-45deg);
			transition: all 0.2s ease-out;

			right: 0;
			width: rem(24);
			height: rem(24);

			.svg-icon {
				width: rem(24);
				height: rem(24);
				//@include theme-fill("primary-1");
				@include theme-fill("accordion-text");
			}
		}

		&--truncated h4 {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	&__heading{
		&:hover{
			.theme-stuk &,								
			.theme-stil &,						
			.theme-uvm &,				
			.theme-kanalen &
			{				
				color: $color-black!important;
			}
			.theme-trivsel &			
			{				
				color: $color-black!important;
			}
		}
	
	}
	&__icon{
		.accordion__trigger:hover &{
			.theme-stuk &,								
			.theme-stil &,						
			.theme-uvm &,				
			.theme-kanalen &
			{				
				fill: $color-black!important;
			}
			.theme-trivsel &
			{				
				fill: $color-black!important;
			}
		}
	}

	&--discreet {
		.accordion__title {
			h5 {
				@extend %trumpet;
			}

			.icon {
				transform: scaleY(1);
				top: 1.125em;
				width: rem(12);
				height: rem(12);
				.svg-icon {
					fill: $color-text;
					width: rem(12);
					height: rem(12);
				}
			}

			p {
				position: absolute;
				right: 2em;
				top: 1.6em;
				font-size: rem(12);
				opacity: 0;
				transition: opacity 0.2s ease-out;
				color: $color-text-discreet;

				&.is-active {
					opacity: 1;
				}
			}
		}

		&.is-active {
			.accordion__title .icon {
				top: 1.5em;
				transform: scaleY(-1);
			}
		}
	}

	//JPL: Emergency hack to ensure smoother animations for accordions with rich-text content
	.rich-text & .js--accordion-content,
	& .rich-text .js--accordion-content {
		p {
			margin-bottom: 0;
			padding-bottom: 2em;
		}
	}
}

.accordion__wrapper .accordion .js--accordion-trigger:hover {
	.theme-trivsel &
	{	
		h1, h2, h3, h4, h5, h6{
			color: $color-black!important;
		}			
		
	}
	
}