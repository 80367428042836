// Panel
//
// DOD: done
//
// Container for Kanelen layouts. The panels are given sizer classes when shown inside a masonry layout.</br>
// NOTE: The column class widths are not visible in the designguide - needs masonry.
//
// .panel--theme-primary.theme-kanalen			- Gives light primary theme color background
// .panel--col1--sm								- 1 column wide on 768px viewports
// .panel--col2--sm								- 2 columns wide on 768px viewports
// .panel--col1--md								- 1 column wide on 1150px viewports
// .panel--col2--md								- 2 columns wide on 1150px viewports
// .panel--col3--md								- 3 columns wide on 1150px viewports
// .panel--col1--lg								- 1 column wide on 1680px viewports
// .panel--col2--lg								- 2 columns wide on 1680px viewports
// .panel--col3--lg								- 3 columns wide on 1680px viewports
// .panel--col4--lg								- 4 columns wide on 1680px viewports
//
// Weight: 1
//
// wrapperstyles: background:#EEE;padding:20px;display:block;
//
// Markup: ../../templates/prototype/partials/elements/panel.twig
//
// Style guide: elements.panel

// Panel Banner
//
// DOD: done
//
// Full width, horizontal panel layout
//
// .panel--theme-secondary.theme-kanalen		- Gives secondary theme color background
//
// Weight: 1
//
// wrapperstyles: background:#EEE;padding:20px;display:block;
//
// Markup: ../../templates/prototype/partials/elements/panel-banner.twig
//
// Style guide: elements.panel.banner

.panel {
	$module: ".panel";

	box-sizing: border-box;
	background: $color-white;

	padding: rem($space-medium) rem($space-xsmall);
	@include mq($from: screen-sm) {
		padding: rem($space-medium);
	}

	@include mq($from: screen-lg) {
		padding: rem($space-xlarge);
	}

	&--transparent {
		background: transparent;
	}

	&--theme-primary {
		@include theme-background-color("primary-5");
	}
	&--theme-secondary {
		@include theme-background-color("secondary");
	}
	&--theme-primary-dark {
		@include theme-background-color("primary-1");
	}
	&--theme-tertiary {
		background-color: $color-link-box;
	}

	&__image {
		max-width: rem(300);
		background-size: cover;

		img {
			// opacity: 0;
			@include objectfit;
			width: rem(300);
			max-width: 100%;

			@include mq($from: screen-sm) {
				max-width: rem(300);
			}
		}
	}
	&__image--full {
		padding: 0;
		#{$module}__image,
		#{$module}__image img {
			max-width: none;
			width: 100%;
		}
	}

	&__content {
		color: $color-text;

		font-size: rem(24);

		#{$module}--theme-primary-dark & {
			color: $color-white;
		}
		.button--link-caps {
			display: inline-block;
			.text,
			.icon {
				font-size: rem(10);
			}
			.text {
				color: currentColor;
			}
			.svg-icon {
				fill: currentColor;
			}
		}

	
		&--slim {
			@include mq($from: screen-lg) {
				padding: 0;
			}
		}

		h4 {
			@extend %title;
			display: inline-block;
			margin-bottom: rem($space-large);

			&:after {
				content: "";
				display: inline-block;
				position: absolute;
				top: 100%;
				left: 0;
				height: 0.3125rem;
				width: 3.125rem;
				background: #00647d;
			}
		}

		h5 {
			font-size: rem(12);
			text-transform: uppercase;
			margin-bottom: 1em;
			font-weight: 600;
		}

		h6 {
			font-size: rem(10);
			text-transform: uppercase;
			margin-bottom: 3em;
			font-weight: 400;
		}

		p {
			font-size: 0.6em;
			color: $color-grey-1;

			> a {
				@include theme-color("primary-1");
			}

			strong {
				font-weight: bold;
			}
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;

		h4 {
			@extend %title;
			display: inline-block;
			margin-bottom: rem($space-large);
			font-family: var(--font-primary)!important;
		}
		h5 {
			margin: 0 0 1em;
			color: $color-text;
			font-size: rem(18);
			font-weight: 600;
			font-family: var(--font-primary)!important;
		}
		.button--link {
			height: 1.5em;
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		align-items: center;

		padding: rem($space-medium) 0;
		@include mq($from: screen-sm) {
			padding: rem($space-xxlarge) 0;
		}

		.button--link {
			align-self: flex-end;
		}

		&--slim {
			padding: rem($space-xsmall) 0 0;
			@include mq($from: screen-sm) {
				padding: rem($space-medium) 0 0;
			}
		}
	}

	&__header,
	&__footer {
		@include theme-color("primary-1");

		.button--link-caps {
			display: inline-block;
			.text,
			.icon {
				font-size: rem(10);
			}
			.text {
				color: currentColor;
			}
			.svg-icon {
				fill: currentColor;
			}
		}
	}

	.form,
	.form__section {
		margin: 0;
		padding: 0;

		.form__field {
			max-width: rem(400);
		}
	}

	.accordion {
		@include mq($from: screen-sm) {
			padding-left: rem($space-medium);
			padding-right: rem($space-medium);
		}

		&__title {
			h4 {
				font-size: rem(12);
				font-weight: 700;
				text-transform: uppercase;
				color: $color-text;
			}
		}
		&__content {
			padding-bottom: rem($space-medium);
		}
	}
	.form__container{
		.filter-buttons__item-sticky{
			.svg-icon{
				fill: $color-tidal-pool!important;
			}
		}
	}
	.panel-layout--masonry & {
		width: 100%;
		margin-bottom: rem($space-xsmall);

		@include mq($from: screen-sm) {
			margin-bottom: rem($space-xlarge);

			&.panel--col1--sm {
				width: calc(100% / 2 - #{rem($space-xlarge)});
			}
			&.panel--col2--sm {
				width: calc(100% - #{rem($space-xlarge)});
			}
		}

		@include mq($from: screen-lg) {
			&.panel--col1--md {
				width: calc(100% / 3 - #{rem($space-xlarge)});
			}
			&.panel--col2--md {
				width: calc(100% / 1.5 - #{rem($space-xlarge)});
			}
			&.panel--col3--md {
				width: calc(100% - #{rem($space-xlarge)});
			}
		}
		@include mq($from: screen-xxxl) {
			&.panel--col1--lg {
				width: calc(100% / 4 - #{rem($space-xlarge)});
			}
			&.panel--col2--lg {
				width: calc(100% / 2 - #{rem($space-xlarge)});
			}
			&.panel--col3--lg {
				width: calc(100% / 1.333333333332 - #{rem($space-xlarge)});
			}
			&.panel--col4--lg {
				width: calc(100% - #{rem($space-xlarge)});
			}
		}
	}

	.button--subnav {
		.headline {
			font-size: rem(15);
		}
		p {
			font-size: rem(14);
			line-height: 1.5;
		}
		.svg-icon {
			transform: translateX(#{rem(-5)});
		}
		&:hover {
			.svg-icon {
				transform: translateX(0);
			}
		}
	}
}
