// Modals
//
// DOD: done
//
// Modals are created using `<script>` templates with data attributes and `.js--XXXX` triggers.<br/>
// The base template markup is present in the DOM at page load, and additional content is rendered, when the modal is activated and shown inside an overlay.
//
// Markup: ../../templates/prototype/partials/examples/modal-example.twig
//
// examplelink: /examples/pages_kanalen/modals||The modal is best demonstrated at the example page here
//
// Style guide: elements.modal

.modal {

	$module: '.modal';

	transition: transform .2s;
	position: relative;
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
	transform: scale(0);
	border-radius: 0;
	// border:rem(1) solid $color-grey-4;

	.is-active & {
		transition: transform .2s;
		transform: scale(1);
	}

	.button--icon {
		position: absolute;
		right: rem($space-xxsmall);
		top: rem($space-xxsmall);
		z-index: 2;

		.svg-icon {
			width: rem(30);
			height: rem(30);
		}
		@include mq($from: screen-sm) {
			right: rem($space-small);
			top: rem($space-small);
		}
	}

	@include mq($from: screen-sm) {
		width: rem($modal-small);
	}
	@include mq($from: screen-md) {
		width: rem($modal-medium);
	}

	&.modal--xsmall {
		@include mq($from: screen-sm) {
			width: rem($modal-xsmall);
		}
		@include mq($from: screen-md) {
			width: rem($modal-xsmall);
		}

		#{$module}__top,
		#{$module}__middle {
			padding: rem($space-medium);
		}
		#{$module}__bottom {
			padding: rem($space-medium) rem($space-medium) rem($space-xlarge);
		}
	}
	&.modal--small {
		#{$module}__top {
			padding: rem($space-medium);
		}
		@include mq($from: screen-md) {
			width: rem($modal-small);
		}
	}
	&.modal--large {
		@include mq($from: screen-sm) {
			width: rem($modal-small);
		}
		@include mq($from: screen-md) {
			width: rem($modal-large);
		}
	}

	&.modal--centered {
		text-align: center;
	}


	&__top {
		position: relative;
		background: $color-white;
		padding: rem($space-xsmall) rem($space-xsmall) rem($space-xxsmall);
		@include mq($from: screen-sm) {
			padding: rem($space-xxxlarge) rem($space-xxxlarge) rem($space-medium);
		}

		h4 {
			@extend %headline-4;
		}
		h5 {
			@extend %headline-5;
		}

		&--shaded {
			z-index: 1;
			box-shadow: 0 rem(5) rem(5) rem(0) rgba(0, 0, 0, 0.1);
		}

		.button--link {
			position: absolute;
			top: rem($space-xsmall);
			left: rem($space-xxxsmall);
			@include mq($from: screen-sm) {
				top: rem($space-small);
				left: rem($space-xxsmall);
			}
		}
	}
	&__middle {
		position: relative;
		background: $color-white;
		padding: rem($space-xxsmall) rem($space-xsmall);
		@include mq($from: screen-sm) {
			padding: rem($space-medium) rem($space-xxxlarge);
		}

		p {
			font-size: rem(14);
			color: $color-grey-1;
		}
		h5 {
			margin-bottom: rem($space-xsmall);
			margin-top: rem($space-medium);
			font-size: rem(14);
			font-weight: 700;
		}


		.rich-text {
			max-width: rem(520);
			margin: 0 auto;

			*:last-child {
				margin-bottom: 0;
			}
		}
		.form {
			@include theme-color('primary-1');
			.button--link-caps {
				display: block;
				.text, .icon {
					font-size: rem(10);
				}
				.text { color: currentColor; }
				.svg-icon { fill: currentColor; }
			}
			.form__section:first-child {
				margin-top: 0;
			}

			.form__field {
				margin: 0;
			}

			&--flex {
				padding-top: rem($space-medium);
				margin-top: rem($space-medium);

				position: relative;

				&:after {
					content: '';
					display: inline-block;
					position: absolute;
					bottom: 100%;
					left: 0;
					height: rem(1);
					width: rem(70);
					background: $color-grey-4;
				}
				.form__section {

					margin: 0;
					&:not(:last-child) { margin-bottom: rem($space-xsmall); }
				}
				.form__field {
					flex: 100% 1 1;
					margin: 0;
					&:last-child {
						margin-left: rem($space-xsmall);
					}
				}
			}
		}


		.contact-card__image {
			margin-bottom: 0;
		}


		max-height: rem(400);
		overflow-y: auto;
	}

	&__bottom {
		position: relative;
		padding: rem($space-xxsmall) rem($space-xsmall) rem($space-xsmall);
		@include mq($from: screen-sm) {
			padding: rem($space-medium) rem($space-xxxlarge) rem($space-xxxlarge);
		}
		background: $color-white;
		.button {
			margin: 0.5em;
			@include mq($from: screen-md) {
				margin: 0 1em;
			}
		}
		@include mq($from: screen-md) {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&--ritzau {
		.modal__middle {
			iframe {
				height: 500px !important;
			}
			max-height: none;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
}
