@media print {
	* {
		overflow: visible !important;
	}

	@page {
		margin: 25mm !important;
	}

	body {
		font-size: 12px !important;
		width: 800px !important;
	}

	a {
		transition: none !important;
		font-weight: bolder;
		&:hover {
			color: inherit;
		}
	}

	header {
		page-break-after: avoid !important;
		page-break-before: avoid !important;
	}

	.headline-1,
	.rich-text h1,
	.sub-navigation h1,
	.page-header h1,
	.overview-navigation h1 {
		font-size: 32px !important;
		line-height: 1.6;
	}

	.banner h2,
	.headline-2,
	.card h2 {
		font-size: 24px !important;
		line-height: 1.4;
	}

	.headline-3,
	.card h3,
	.headline-4,
	.rich-text h3,
	.rich-text h4,
	.form .form__section h4,
	.accordion__title h4,
	.card__text h4,
	.abstract-list__item h4,
	.overview-navigation .button--category h4 {
		font-size: 20px !important;
	}
	.headline-list .button--headline .text,
	.subheadline,
	.sub-navigation > p,
	.page-header h5 {
		font-size: 18px !important;
	}

	.links-list .button--link .text,
	.headline-6,
	.rich-text h6 {
		font-size: 15px !important;
	}
	.links-list {
		margin-bottom: 0 !important;
	}
	.links-list li {
		border-bottom: 0 !important;
	}
	.links-list .bytt .bodytext-lg,
	.rich-text p,
	.rich-text p.bodytext-lg,
	.rich-text ul > li,
	.rich-text ol > li,
	.rich-text span.meta,
	.form__label__text h5,
	.form__label__text p,
	.contact p,
	.card p,
	.paging a,
	.abstract-list__item p,
	.overview-navigation .button p {
		font-size: 14px !important;
		line-height: 1.5;
	}

	.page-article--offset {
		padding: 0 !important;
	}
	.page-header--diminished h1 {
		width: auto !important;
	}

	.rich-text .accordion div > p + p,
	.rich-text .accordion div > p + ul,
	.rich-text > p + p,
	.rich-text > p + ul,
	.rich-text .accordion div > h4 + p,
	.rich-text .accordion div > h5 + p,
	.rich-text .accordion div > h6 + p {
		margin-top: 0 !important;
	}
	.rich-text p {
		margin-bottom: 1em !important;
	}

	.rich-text blockquote span {
		&:before {
			display: none !important;
		}
	}
	.rich-text blockquote {
		&:before {
			background: none !important;
			left: -5px !important;
			border-right: 5px solid black;
		}
	}
	.rich-text blockquote p,
	.rich-text blockquote p strong {
		font-size: 18px !important;
		line-height: 1.2 !important;
	}

	.entries-navigation--mobile,
	.entries-navigation--tablet,
	.button--large,
	.button--small,
	.main-navigation__list,
	.site-footer,
	.accordion__title .icon,
	.js--search-toggle,
	.button--toggle,
	.notification,
	.v--mobile-only,
	.form,
	.sorting,
	.cookie-compliance {
		display: none !important;
	}
	.rich-text table th {
		background: none !important;
		border-bottom: 2px solid $color-grey-5;
	}
	.rich-text .table-caption {
		margin-top: 0.5em !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
		background: none !important;
	}

	.entries-navigation--desktop {
		display: block !important;
	}
	.abstract-list__item__inner,
	.card__image {
		.image {
			background: none !important;
		}
		img,
		.image__inner {
			visibility: visible !important;
		}
	}

	.pagebreak {
		display: block;
		//page-break-before: always;
	}
	iframe {
		border: 1px solid $color-grey-5;
	}
	figure {
		img {
			border: 1px solid $color-grey-5;
			max-width: rem(500);
		}
		.button--play {
			display: none !important;
		}
		//page-break-before: always;
	}
	.banner {
		padding: 1em !important;
	}
	.box--download,
	.box--note,
	.box.links-list {
		padding: 1em !important;
		background: none !important;
		border: 1px solid $color-grey-5 !important;

		.box__image {
			position: static !important;
		}
		&--secondary{
			padding: 0;
			border: 0;
			h4{
				margin-bottom: 0.5em;
			}
			a{
				padding-top: 0;
			}
		}
	}
	.links-list .button--link {
		padding: 0.5em 0 !important;
		.text {
			padding: 0 !important;
			font-size: 16px !important;
		}
	}
	.js--accordion,
	.js--accordion-content {
		max-height: none !important;
		overflow: visible !important;
	}

	.w--normal,
	.w--wide,
	.w--wider,
	.w--narrow {
		max-width: none !important;
		padding: 0 !important;
		margin: 0 0 1em !important;
	}
	.deck {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.entries-navigation .button--entry {
		width: 33% !important;
	}

	.page-aside__inner {
		page-break-before: always !important;
		background: none !important;
		padding: 1em !important;
		border: 1px solid $color-grey-5 !important;
		max-width: rem(400) !important;
	}

	.grid,
	.grid-item {
		display: block !important;
		width: auto !important;
		flex: none !important;
		max-width: none !important;
		margin: 0 !important;
		padding-top: 0 !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
		padding-bottom: 0 !important;
	}

	.section-title {
		padding-bottom: 1em !important;
		border-bottom: 1px solid $color-grey-3;
	}
	.section-title:before {
		content: "" !important;
		display: none !important;
	}
	.card {
		background: none !important;
		margin-bottom: 3em !important;
	}
	.card__text {
		padding: 1em 0 !important;
	}
	.card__text h2:before {
		content: "" !important;
		display: none !important;
	}

	.card__text p {
		margin-top: 1em !important;
	}

	.page-article--offset.rich-text > p,
	.page-article--offset.rich-text > ul,
	.page-article--offset.rich-text > ol,
	.page-article--offset.rich-text > h4,
	.page-article--offset.rich-text > blockquote,
	.page-article--offset.rich-text > iframe,
	.page-article--offset.rich-text > .figure,
	.page-article--offset.rich-text > .box,
	.page-article--offset.rich-text > .box.box--table--slim,
	.page-article--offset.rich-text > .accordion__wrapper {
		margin-right: 0 !important;
	}

	.main-navigation .button--logo .icon {
		display: none !important;
	}
	.main-navigation .button--logo .print-icon {
		display: inline-block !important;
	}
	.main-navigation .button--logo .text {
		padding: 0.5em 0 0 1em !important;
	}

	.split-list:before {
		content: "" !important;
		display: none !important;
	}
}
