// Forms / inputs
//
// DOD: done
//
// The `.form` class is used to wrap `.form__section`s wrapping `.form__section__inner`s.<br/>
// `.form__section--split` for a 70/30 or 60/40 split set of two `.form__section__inner`s - additionally with `.form__section--reverse` for reversed order;<br/>
// finally `.form__section--fat` for fatter form fields.
//
// Markup: <div class="form c--uvm-primary-5" style="padding:10px;">"form"<div class="form__section c--uvm-primary-4" style="padding:10px;">"form__section"<div class="form__section__inner c--uvm-primary-3" style="padding:10px;margin-bottom:10px;">"form__section__inner", form elements go here</div><div class="form__section__inner c--uvm-primary-3" style="padding:10px;">"form__section__inner", form elements go here</div></div></div>
//
// Style guide: elements.form

.form {
	position: relative;
	counter-reset: formgroup;

	.form__field {
		&--text,
		&--date {
			.form__item {
				height:rem($form-height);
			}
		}
		&--select {
			.form__item {
				height:rem($form-height);
				line-height:1.5;
			}
		}
		&--text,
		&--date,
		&--select,
		&--textarea {
		}


		&--slim {
			&.form__field--text,
			&.form__field--date,
			&.form__field--select {
				.form__item {
					height:rem($form-height-slim);
				}
			}
		}
	}

	.form__section {
		margin: rem($space-large) 0;

		> h4 {
			@extend %headline-4;
			margin-bottom:rem($space-large);
		}
		> p {
			@extend %bodytext-lg;
			color: $color-grey-1;

			@include mq($until:screen-sm) {
				font-size: rem(16);
				line-height: 1.3;
			}
			margin-bottom:rem($space-large);
		}

		&--footer {

			@include mq($from: screen-sm) {
				display: flex;
				justify-content: space-between;
				flex-direction: row-reverse;
				align-items: center;
			}

			> .button {
				display: inline-block;
				margin-bottom: rem($space-small);
				@include mq($from: screen-sm) {
					margin: 0;
				}
			}

			> a:not(.button) {
				display: inline-block;
				font-size: rem(18);
				@include theme-color('primary-1');
				text-decoration: underline;
			}
		}
		&--list {
			display: flex;

			.form__label {
				margin: 0;
			}
			.form__field {
				margin: 0 rem($space-large) 0 0;
			}
			.form__section__inner:last-child .form__field {
				margin-bottom: 0;
			}
		}
	}

	.form__section__footer {
		position: relative;

		.button {
			display: block;
			margin-bottom: rem($space-xxsmall);
		}

		@include mq($from: screen-xs) {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.button {
				display: inline-block;
				margin-left: rem($space-xsmall);
				margin-right: 0;
				margin-bottom: 0;
			}

			.button:first-child {
				position: absolute;
				margin-left: 0;
				top: 0;
				left: 0;
			}
		}

	}

	.form__item:not(:disabled) {
		&:focus,
		&:active {
			background-color: $color-form-bg;
			border-color: $color-black;
			color: $color-text;
		}
	}

	.form__section--split {
		.form__section__inner {
			float: left;
			margin:0;
			&:first-of-type {
				width:calc(60% - 1px);
				@include mq($from: screen-xs){
					width:calc(70% - 1px);
				}
			}
			&:last-of-type {
				width:40%;
				@include mq($from: screen-xs){
					width:30%;
				}
				.form__item {
					border-left-color:transparent;
					&:not(:disabled) {
						&:focus,
						&:active {

							border-left-color: $color-black;

						}
					}
				}
			}
		}


		&--reverse {
			.form__section__inner {
				&:first-of-type {
					width:40%;
					@include mq($from: screen-xs){
						width:30%;
					}
				}
				&:last-of-type {
					width:calc(60% - 1px);
					@include mq($from: screen-xs){
						width:calc(70% - 1px);
					}
				}
			}
		}
	}

	&--combo {
		margin-bottom: rem($space-medium);


		.form__inner {
			justify-content: space-between;
			align-items: center;

			@include mq($from: screen-xs) {
				display: flex;
			}
		}

		.button.button--large {
			margin-top: rem($space-xsmall);
			display: flex;
			justify-content: center;
			align-items: center;
			flex: rem(150) 0 1;

			@include mq($from: screen-xs) {
				display: flex;
				margin-top: 0 !important;
				height:rem(60);
			}
			@include mq($from: screen-sm) {
				display: flex;
				height:rem(70);
			}

		}


		.form__field--checkbox {
			margin-top: rem($space-small);

			p {
				margin: 0;
			}
		}


		input {
			flex: rem(150) 1 1;
			@extend %form-item;
			display: inline-block;
			border: rem(2) solid $color-grey-4;
			border-radius: none;
			line-height: 1em;
			font-size: rem(20);
			font-weight: 300;
			box-sizing: border-box;
			line-height: rem(50);
			height: rem(60);

			padding: rem($space-xsmall);

			@include mq($from: screen-xs) {
				border-right: 0;
				width: auto;
			}
			@include mq($from: screen-sm) {
				padding: rem($space-small) rem($space-large);
				height: rem(70);
			}

			&:focus,
			&:active {
				&:not(:disabled) {
					outline: 0;
					box-shadow: 0 0 0 black;
					border-color: $color-form-border-active;
				}
			}
		}
		input + .button {
			padding: rem($space-xsmall);

			@include mq($from: screen-sm) {
				padding: rem(15);
			}

		}
		.button--search {

			max-width: rem(70);

			transition:background 0.1s ease-out;
			position:relative;
			//@include theme-background-color('primary-1');
			@include theme-background-color('search-button');

			&:before {
				position: absolute;
				content:'';
				top:100%;
				right:0;
				left:0;
				bottom:0;
				background:$color-black;
				//@include theme-background-color('search-button-hover'); unfortunately themify does not work on pseudo elements
				transition:top 0.1s ease-out;
			}

			.icon {
				display: block;
				width:rem(40);
				height:rem(40);
			}
			.svg-icon {
				width: 100%;
				height: 100%;
				//fill:$color-white;
				@include theme-fill('search-button-icon');
				transition:fill 0.3s ease-out;
			}

			&:hover {
				&:before {
					top:0;
				}
				.svg-icon {
					//fill:$color-white;
					@include theme-fill('search-button-icon');
				}
			}
		}
	}

	&--flex {
		.form__section {
			display: flex;
			justify-content: space-between;
			align-items: center;
			&:not(:last-child) { margin-bottom: rem($space-xsmall); }
		}
		.form__field {
			&:last-child {
				margin-left: rem($space-xsmall);
			}
			margin: 0;
		}
	}

	&--full-width {
		width: 100%;
	}
}
