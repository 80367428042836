.keyword-list {
	margin: 0;
	padding: 0;

	> li:not(:last-child) {
		margin-bottom: rem($space-xsmall);
		position: relative;
	}
}

