.form-error {
  position: relative;
  padding: 20px 20px 20px 30px;
  background-color: $color-error-message;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10px;
    background-color: $color-contessa;
  }

  strong {
    font-weight: 700;
  }
}