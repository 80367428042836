// Feature card
//
// DOD: done
//
// The card is a central interface element. It can take numerous forms and can be inserted in numerous ways.<br/>
// Without a theme, the card is white. The beige background is the secondary theme color - case in point, a card needs a theme to appear correctly, so all cards below have the theme-uvm class.<br/>
// The card below is not representative, as there's a lot of logic involved. Therefore, make sure to look at the example page.<br/>
//
// .theme-uvm.card								- Default card
// .theme-uvm.card--significant					- Significant card, more spacing around text
// .theme-uvm.card--board						- Centered content, crown, usually used two by two
// .theme-uvm.card--horizontal.has-image		- Usually wide, image on the left, text on the right, needs has-image class
// .theme-uvm.card--entry						- Card with centered icon and title for site entries
// .theme-uvm.card--entry.card--theme			- Entry card in UVM theme colors
// .theme-uvm.card--banner						- Used as a full width banner only in theme color, has an embedded button instead of entire area being clickable (not possible in styleguide)
// .theme-uvm.card--banner.card--theme			- Card in UVM theme, darker than entry cards
//
// Markup: ../../templates/prototype/partials/blocks/featured-card.twig
//
// hidedefault: true
//
// Style guide: blocks.featuredcard

.featured-cards {
	.card:last-child {
		border:none;
	}

	.card {
		border-right:rem(1) solid $color-white;
		//@include theme-background-color('primary-5');
		@include theme-background-color('card--featured-background');
		.theme-stuk &
		{
			background-color: $color-maroon-light;
		}				
		.theme-stil &
		{
			background-color: $color-oracle;
		}			
		.theme-uvm &
		{
			background-color: $color-tranquil;
		}
		
		.button--link {
			.text {
				font-weight: 400;
			}
		}


		h4 {
			display: inline-block;
			margin-right: 10px;
		}
		.card__image{
			display: flex;
		}
		.card__text {	
			.text{
				line-height: 22px;
			}		
			h4, .text{
				.theme-stuk &,
				.theme-stil &				
				{
					color: $color-white;
				}
				.theme-uvm &
				{
					color: $color-black;
				}
			}
			.svg-icon{
				.theme-stuk &,
				.theme-stil &,
				.theme-uvm &
				{
					fill: $color-white;
				}
			}
			p {
				font-size: rem(16);
				line-height: 1.3rem;
			}
		}

		.button.button--link {
			display: block;
		}

		.icon.feature-headline-icon {
			display: inline-block;
			vertical-align: baseline;
			svg {
				fill: $color-black;
			}
		}
	}
}
