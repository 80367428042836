.type-ahead {
	$module: '.type-ahead';

	$type-ahead-padding-left: $space-xlarge;
	$type-ahead-padding-right: $space-xlarge;
	$type-ahead-padding-top: $space-xsmall;
	$type-ahead-padding-bottom: $space-xsmall;


	position: absolute;
	top: 100%;
	left: rem(-1);
	right: rem(-1);

	background: $color-white;
	border: rem(1) solid transparent;
	@include theme-border-color('primary-1');

	z-index: 60;

	display: none;
	transition: all 0.2s ease-out;

	transform-origin: center top;
	transform: scaleY(0);
	opacity: 0;

	&.is-active {
		display: block;
		opacity: 1;
		transform: scaleY(1);
	}

	&__group {
		padding-bottom: rem($space-xlarge);

		&:not(:first-child) {
			border-top: rem(1) solid $color-border-discreet;
		}

		&__header {
			justify-content: space-between;
			border-bottom: rem(1) solid $color-border-discreet;
			padding: rem($type-ahead-padding-top);
			@include mq($from: screen-xs){
				display: flex;
			}
			@include mq($from: screen-sm){
				padding: rem($type-ahead-padding-top) rem($type-ahead-padding-right) rem($type-ahead-padding-bottom) rem($type-ahead-padding-left);
			}
			overflow: hidden;
			.title {
				display: block;
				font-weight: 600;
			}
			.count {
				font-weight: 600;
				font-size: rem(11);
			}
			.button--link-caps {
				display: inline-block;
				@include theme-color('primary-1');
			}
		}
		&__list {

			li a {
				position: relative;
				border-bottom: rem(1) solid $color-border-discreet;
				overflow: hidden;
				display: block;
				padding: rem($type-ahead-padding-top);
				@include mq($from: screen-sm){
					padding: rem($type-ahead-padding-top) rem($type-ahead-padding-right) rem($type-ahead-padding-bottom) rem($type-ahead-padding-left);
				}
			}
			.image {
				width: rem(50);
				height: rem(50);
				overflow: hidden;
				border-radius: 50% 50%;
				background-size: cover;
				display: inline-block;
				float: left;
				margin-right: rem($space-xsmall);

				img {
					width: rem(50);
					height: rem(50);
					display: block;
					opacity: 0;
				}
			}
			.name {
				font-weight: 600;
				line-height: rem(28);
			}
			.title {
				display: inline-block;
				font-size: rem(14);
				float: left;
			}
			.location {
				font-size: rem(14);
				float: right;
			}
		}
		&__footer {
			padding: rem($type-ahead-padding-top);
			@include mq($from: screen-sm){
				padding: rem($type-ahead-padding-top) rem($type-ahead-padding-right) rem($type-ahead-padding-bottom) rem($type-ahead-padding-left);
			}
			overflow: hidden;
			a {
				font-size: rem(14);
				float: right;
				font-weight: 600;
				@include theme-color('primary-1');
			}
		}
	}

	&__footer {
		padding: rem($type-ahead-padding-top);
		@include mq($from: screen-sm){
			padding: rem($type-ahead-padding-top * 2) rem($type-ahead-padding-right) rem($type-ahead-padding-top * 2) rem($type-ahead-padding-left);
		}
		a {
			position: static;
			font-weight: 700;
			.text {
				@include theme-color('primary-1');
			}
			.svg-icon {
				@include theme-fill('primary-1');
			}
		}
		&--centered {
			text-align: center;
		}
	}

	&--tight {

		#{$module}__group:not(:first-child) {
			border-top: 0;
		}

		#{$module}__group__header {
			padding-top: rem($space-xlarge);
		}
		#{$module}__group__header,
		#{$module}__group__list li a,
		#{$module}__group__footer {
			padding-right: rem($type-ahead-padding-left);
		}
	}


}
