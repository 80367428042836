// Promotion Box
//
// DOD: done
//
// Used on Kanalens frontpage
//
// .promotion-box--theme-1					- Theme 1
// .promotion-box--theme-1--sm			- Theme 1 small
// .promotion-box--theme-2					- Theme 2
// .promotion-box--theme-2--sm			- Theme 2 small
// .promotion-box--theme-3					- Theme 3
// .promotion-box--theme-3--sm			- Theme 3 small
// .promotion-box--theme-4					- Theme 4
// .promotion-box--theme-4--sm			- Theme 4 small
// .promotion-box--theme-5					- Theme 5
// .promotion-box--theme-5--sm			- Theme 5 small
// .promotion-box--theme-6					- Theme 6
// .promotion-box--theme-6--sm			- Theme 6 small
// .promotion-box--theme-7					- Theme 7
// .promotion-box--theme-7--sm			- Theme 7 small
//
// Markup: ../../templates/prototype/partials/blocks/promotion-box.twig
//
// hidedefault: true
//
// Style guide: blocks.promotionbox

.promotion-box {
	position: relative;
	min-height: 150px;
	padding-right: 100px;
	overflow: hidden;
	&:after {
		content: "";
		background: url("/svg/crown.svg") center center no-repeat;
		opacity: 0.5;
		background-size: contain;
		display: block;
		width: 143px;
		height: 202px;
		position: absolute;
		right: -50px;
		top: 50%;
		transform: translateY(-50%);
	}

	&--theme-1 {
		background-color: $color-promotion-1;

		&--sm {
			background-color: $color-promotion-1;
			.panel__content {
				padding: 0;
			}
			&:after {
				display: none;
			}
		}
	}
	&--theme-2 {
		background-color: $color-promotion-2;
		&--sm {
			background-color: $color-promotion-2;
			.panel__content {
				padding: 0;
			}
			&:after {
				display: none;
			}
		}
	}
	&--theme-3 {
		background-color: $color-promotion-3;

		&--sm {
			background-color: $color-promotion-3;
			.panel__content {
				padding: 0;
			}
			&:after {
				display: none;
			}
		}
	}
	&--theme-4 {
		background-color: $color-promotion-4;

		&--sm {
			background-color: $color-promotion-4;
			.panel__content {
				padding: 0;
			}
			&:after {
				display: none;
			}
		}
	}
	&--theme-5 {
		background-color: $color-promotion-5;
		
		&--sm {
			background-color: $color-promotion-5;
			.panel__content {
				padding: 0;
				h1,h2,h3,h4,h5,p{
					color: $color-white!important;
					&:after{
						background: $color-white!important;
					}
				}
				a{
					color: $color-tranquil!important;
					&:hover{
						color: $color-white!important;
					}
				}
			}
			&:after {
				display: none;
			}
		}
	}
	&--theme-6 {
		background-color: $color-promotion-6;

		&--sm {
			background-color: $color-promotion-6;
			.panel__content {
				padding: 0;
			}
			&:after {
				display: none;
			}
		}
	}
	&--theme-7 {
		background-color: $color-promotion-7;

		&--sm {
			background-color: $color-promotion-7;
			.panel__content {
				padding: 0;
			}
			&:after {
				display: none;
			}
		}
	}
}
