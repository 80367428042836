.author-box {
	$module: '.author-box';
	font-size: rem(14);


	h3,
	h4 {
		display: block;
		@extend %headline-5;
		margin-bottom: rem($space-large);
	}

	p {
		color: $color-grey-1;

		strong {
			font-weight: 700;
			color: $color-text;
		}
	}

	p a {
		@extend %general-link;
		display: inline-block;
	}

	p a + a {
		margin-top: rem($space-small);
	}

	&__image {
		width: rem(72);
		display: block;
		margin-bottom: rem($space-large);


		&--dummy {
			width: rem(72);
			height: rem(72 * $UVM-IMAGE-RATIO);
			background: $color-grey-3;
		}
	}



	&--kanalen {
		#{$module}__image {
			width: rem(72);
			height: rem(72);
			overflow: hidden;
			border-radius: 50% 50%;
			background-size: cover;
			background-position: center center;

			img {
				opacity: 0;
			}
		}
	}

	hr {
		text-align: left;
		width: rem(70);
		border: 0;
		margin: 0;
		border-bottom: rem(1) solid $color-grey-4;
		margin-bottom: rem($space-xsmall);
	}

	&__content {
		p {
			margin-bottom: rem($space-medium);
		}

		*:last-child {
			margin-bottom: 0;
		}
		h3 {
			margin-bottom: rem($space-xsmall);
		}
		.links {

			span {
				display: block;
			}
			span + span {
				margin-top: rem($space-xsmall);
			}
		}
	}


	&__content + &__image {
		margin-bottom: 0;
		margin-top: 0.5em;
	}

	&__footer {
		margin-top: 2em;
		flex: 100% 0 0;

		p {
			margin-bottom: rem($space-medium);
		}
	}



	+ .author-box {

	}
}
