// Overview navigation
//
// DOD: done
//
// This component is used for the workareas and ministry pages
//
// .theme-uvm						- Shown in uvm theme
// .theme-stuk						- Shown in stuk theme
// .theme-stil						- Shown in stil theme
//
// Markup: ../../templates/prototype/partials/blocks/overview-navigation.twig
//
// hidedefault: true
//
// Style guide: blocks.overviewnavigation

.overview-navigation {

	ul li {
		margin-bottom: rem($space-medium);
	}
	h1 {
		@extend %headline-1;
		margin-bottom: rem($space-large);
	}

	.button {

		.text {
			line-height: inherit;
			display: inline-block;
			font-weight: 600;
			color: $color-black;
			line-height: 1.4em;
			margin-right: auto;
			.theme-stuk &,
			.theme-stil &,
			.theme-uvm &
			{
				color: $color-white;
			}
		}
		.icon {
			line-height: inherit;
			transition: transform 0.3s ease-out;
			transform: translateX(10px);
			overflow: hidden;
			font-size: rem(16);
			@include mq($from: screen-sm){
				font-size: rem(20);
			}
		}
		.svg-icon {
			transition: transform 0.3s ease-out;
			width: 1em;
			height: 1em;
			vertical-align: middle;
			fill: $color-black;

			@include mq($from: screen-sm){
				transform: translateX(-10px);
			}
			.theme-trivsel &			
			{
				fill: $color-black!important;
			}
		}


		p {
			@extend %bodytext-lg;
			color: $color-grey-1;
			font-weight: 400;

		}
	}

	.button--navigate {
		padding: rem($space-large);
		@include mq($from: screen-sm){
			padding: rem($space-xlarge);
		}
		@include mq($from: screen-md){
			padding: rem($space-xxlarge) rem($space-xxlarge * 2);
		}
	}

	.button--item,
	.button--navigate {
		display: block;

		.text {
			@extend %headline-5;
		}

		@include mq($from: screen-sm){
			&:hover {
				.text {
					border-color: $color-black;
				}
				.svg-icon {
					transform: translateX(0px);
				}
				.icon {
					transform: translateX(10px);
				}
			}
		}
	}

	.button--item {
		.text {
			font-weight: 400;
			color: $color-black;
			font-size: rem(16);
			@include mq($from: screen-sm){
				font-size: rem(20);
			}
			.theme-stuk &,
			.theme-stil &,
			.theme-uvm &
			{
				color: $color-white;
			}
		}
	}

	.button--category {
		display: flex;
		align-items: center;
		justify-content: space-between;

		cursor: pointer;

		padding: rem($space-large);
		@include mq($from: screen-sm){
			padding: rem($space-xlarge);
		}
		@include mq($from: screen-md){
			padding: rem($space-xxlarge) rem($space-xxlarge * 2);
		}
		@include mq($from: screen-xl){
			padding: rem($space-xxxlarge) rem($space-xxlarge * 2);
		}


		.text {
			@extend %headline-4;
		}

		.icon {
			transition: transform 0.2s ease-out;
			transform-origin: center center;
			transform: translateX(0px);

			display: inline-block;
			font-size: rem(18);
			flex: 0 0 rem(18);
			margin-left: rem($space-small);
			.svg-icon {
				transform: rotate(90deg);
			}
		}

		.svg-icon {
			@include theme-fill('primary-1');
			.theme-stuk &,
			.theme-stil &,
			.theme-uvm &
			{
				fill: $color-white;
			}
		}

		&__icon {
			margin-right: rem($space-small);
			display: inline-block;
			font-size: rem(40);
			display: inline-flex;
			align-items: center;
			justify-content: center;

			.svg-icon {
				transform: translate(0,0);
			}
		}

	}
	&__category {
		position: relative;

		@include theme-background-color('primary-4');		
		.theme-trivsel &		
		{
			background-color: $color-dust-storm;
		}
		.theme-stuk &,
		.theme-stil &,
		.theme-uvm &
		{
			background-color: $color-tidal-pool;
		}
		margin-bottom: rem(3);

		&__inner {
			.grid {
				padding: 0 rem($space-large) rem($space-large);
				@include mq($from: screen-sm){
					padding: 0 rem($space-xlarge) rem($space-xlarge) rem($space-xlarge);
				}
				@include mq($from: screen-md){
					padding: 0 rem($space-xxlarge * 2) rem($space-xxlarge);
				}
				@include mq($from: screen-xl){
					padding: 0 rem($space-xxlarge * 2) rem($space-xxxlarge);
				}

			}
		}
		> .button--navigate {
			.text {
				font-weight: 400;
				font-size: rem(20);
				@include mq($from: screen-sm){
					font-size: rem(22);
				}
			}
		}

		&.is-active {
			.button--category .icon {
				transform: rotateZ(-135deg);
			}
		}
	}
}
