.keyword-configurator {
	color: $color-text;

	h5 {
		font-size: rem(14);
		font-weight: 700;
		margin-bottom: rem($space-medium);
	}

	.keyword-list {
		display: block;
		max-width: rem(600);
		margin: 0 auto;
	}
}
