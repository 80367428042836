
// The micro clearfix http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}
}
@mixin vertical-align($type:"margin"){
	$type: "margin" !default;
	position: absolute;
	@if $type == "margin" {
		margin-top: auto;
		margin-bottom: auto;
		display: table;
		top: 0;
		bottom: 0;
	} @else {
		top: 50%;
		transform: translateY(-50%);
	}
}
@mixin animation($type) {
    @extend %animation--#{$type};
}
@mixin pixel-ratio($ratio:2){
	$o-ratio: $ratio*2;
	@media only screen and ( -webkit-min-device-pixel-ratio: $ratio ),
	       only screen and (    min--moz-device-pixel-ratio: $ratio ),
	       only screen and (      -o-min-device-pixel-ratio: #{$o-ratio}/2 ),
	       only screen and (         min-device-pixel-ratio: $ratio ),
	       only screen and ( min-resolution: #{$ratio}dppx ) {
	       @content;
	}
}

@mixin linear-gradient($from, $to, $type:0, $opacity:0){
	$type: "transparent" !default;
	@if $type == "transparent" {
		background: -moz-linear-gradient(top, rgba($to, $opacity) 0%, rgba($from, 0) 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba($to, $opacity)), color-stop(100%,rgba($from, 0)));
		background: -webkit-linear-gradient(top, rgba($to, $opacity) 0%, rgba($from, 0) 100%);
		background: -o-linear-gradient(top, rgba($to, $opacity) 0%, rgba($from, 0) 100%);
		background: -ms-linear-gradient(top, rgba($to, $opacity) 0%, rgba($from, 0) 100%);
		background: linear-gradient(to bottom, rgba($to, $opacity) 0%, rgba($from, 0) 100%);
	}

}
@mixin aspect-ratio($width, $height, $class:"content") {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .#{$class} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@mixin border($border:"bottom", $color: $color-border) {
	$color: $color-border !default;
	border-#{$border}: rem(1) solid $color;
}
@mixin screen-readers-only(){
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}
@mixin reset-button(){
	background: none;
	border: 0;
	color: inherit;
	font: inherit;
	line-height: normal;
	overflow: visible;
	padding: 0;
	cursor: pointer;
	-webkit-appearance: button; /* for input */
	-webkit-user-select: none; /* for button */
	-moz-user-select: none;
	-ms-user-select: none;
	&:hover {

	}
	&:active, &:focus {
		outline: 0;
	}
}
@mixin flex-line($justify) {
	display: flex;
	justify-content: $justify;
	align-items: center;
	>* {
		flex: 0 0 auto;
	}
	.no-flexbox &{
		display: table;
		>* {
			display: table-cell;
		}
	}
}

@mixin hyphenation() {
	html.csshyphens & {
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
	}
	html.no-csshyphens & {
		-ms-word-break: break-all;
		word-break: break-all;
		// Non standard for webkit
		word-break: break-word;
	}

	@supports (hyphens: auto) {
		html.csshyphens body & {
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
			-ms-word-break: normal;
			word-break: normal;
		}
		html.no-csshyphens body & {
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			-moz-hyphens: auto;
			hyphens: auto;
			-ms-word-break: normal;
			word-break: normal;
		}
	}

	// For IE only - Modernizr fails testing hyphens in IE
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		html.csshyphens body &,
		html.no-csshyphens body & {
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
			-ms-word-break: normal;
			word-break: normal;
		}
	}
}

@mixin objectfit($fit: cover, $position: center) {
	object-fit: $fit;
	object-position: $position;
	font-family: 'object-fit: #{$fit}; object-position: #{$position};';
	width: 100%;
	height: 100%;
}



